import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNewspaper, faBriefcase, faSearch } from "@fortawesome/free-solid-svg-icons";
import './Footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const opacity = path => location.pathname === path ? 1 : 0.35;

  return (
    <div className="footer">

      <NavLink to="/home" className="footer-option" style={{ opacity: opacity('/home'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faHome} />
        <div className="title">{t('footer.home')}</div>
      </NavLink> 

      <NavLink to="/news/explore" className="footer-option" style={{ opacity: opacity('/news/explore'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faNewspaper} />
        <div className="title">{t('footer.news')}</div>
      </NavLink>

      <NavLink to="/jobs/overview" className="footer-option" style={{ opacity: opacity('/jobs/overview'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faBriefcase} />
        <div className="title">{t('footer.jobs')}</div>
      </NavLink>

      <NavLink to="/discover" className="footer-option" style={{ opacity: opacity('/discover'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} />
        <div className="title">{t('footer.discovery')}</div>
      </NavLink>

    </div>
  );
}

export default Footer;