import React from "react";
import Header from "../components/Header/Header";
import { Container } from "react-bootstrap";

const ContactUs = () => {
  return (
    <>
      <Header />

      <Container
        className="create-account-container"
        style={{ paddingBottom: "30px" }}
      >
        <div
          style={{
            height: "100vh",
            background: "black",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
            color: "#fff",
            fontWeight: "700",
          }}
        >
          Coming Soon
        </div>
      </Container>
    </>
  );
};

export default ContactUs;
