/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from 'react';

// Components
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar, Modal, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

// CSS

import './More.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { ApiContext } from '../../../context/ApiContext';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

export default function SubscriptionsMore() {

    const [subscriptionId, setSubscriptionId] = useState(''); 
    const { setLoading } = useContext(ApiContext);

    const navigate = useNavigate();

    let profile = localStorage.getItem('profile');
    let token = localStorage.getItem('token');

    profile = JSON.parse(profile)

    const handleCancelSubscription = async () => {

        try {
            setLoading(true);
            const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/subscriptions/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'token' : token
                },
            });

            const data = await response.json();

            if (data.success) {
                alert('Subscription was canceled');
                navigate('/subscriptions/overview');
            } else {
                console.error(`API Error: ${data.message}`);
                alert('Error while canceling your subscription');
            }
       } 
       catch (err) {
            console.error(`Fetch Error: ${err}`);
            alert(`Error while canceling your subscription: ${err}`);
       } finally {
        setLoading(false);
       }

    }

    return (
        <>
        <Header />
            <Container className="create-account-container">
            <Row>
            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left", "marginBottom" : "45px" }}>
                <h2 className="mt-4 mb-4" style={{ "color" : "#fff" }}>Cancel Subscription</h2>
                <h3 className="mt-6" style={{ "color" : "#fff", "fontWeight" : '300' }}>Click here to stop your subscription</h3>
                <div style={{ "marginTop" : "55px" }}>
                    <Button onClick={handleCancelSubscription} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Cancel</Button>
                </div>
            </Col>
            </Row>
            </Container>
        <Footer />
        </>
    );
}