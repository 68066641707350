import { BASE_URL } from "../Utils/utils";
import Profile from "./Profile";

const API_ENDPOINT = BASE_URL;

export default class Jobs {
  constructor() {
    this.profileObj = new Profile();
  }

  async fetchJobsAppliedData() {
    try {
      let profile = localStorage.getItem("profile");
      profile = JSON.parse(profile);

      //const profile = await this.profileObj.getUserProfile();
      const response = await fetch(
        `${API_ENDPOINT}/profiles/id/${profile._id}/jobs/applied`
      );
      const data = await response.json();

      return data.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }

  // async fetchJobsSavedData() {
  //   try {
  //     let profile = localStorage.getItem("profile");
  //     profile = JSON.parse(profile);

  //     const response = await fetch(
  //       `${API_ENDPOINT}/profiles/id/${profile._id}/jobs/saved`
  //     );
  //     const data = await response.json();

  //     return data.data;
  //   } catch (error) {
  //     console.log(error.message);
  //     return [];
  //   }
  // }

  async getJobsData() {
    try {
      const jobsAppliedData = this.fetchJobsAppliedData();
      // const jobsSavedData = this.fetchJobsSavedData();

      return await Promise.all([jobsAppliedData]);
    } catch (error) {
      console.log(error);
    }
  }

  static async toggleApplyJob(jobId, profileId) {
    console.log(jobId, profileId,"jobId, profileId")
    const response = await fetch(`${API_ENDPOINT}/profiles/jobs/id/${jobId}/apply`, {
      method: "POST",
      body: JSON.stringify({ profileId: profileId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // Throw an error if response was not ok
      throw new Error("Error applying for job");
    }
  }

  apply(jobId) {
    this.toggleApplyJob(jobId);
  }

  handleConfirm(jobId) {
    this.apply(jobId);
  }
}
