
// export const BASE_URL_ADMIN = "http://192.168.1.111:9002/"
// export const BASE_URL = "http://192.168.1.103:9001"
// export const STRIPE_PUBLIC_KEY = "pk_test_51Jok4OGs7CwTG1kMRdrqamQro9Bzl0DxTLXhcZ1B5D8lAP8IUe93Td8AcytbsrP95eeIKXdQzwWIUjScHzL79l2W00P5nO6UEI"
// export const MODE_DEV = true




// Live
export const STRIPE_PUBLIC_KEY ='pk_live_51Jok4OGs7CwTG1kMnfM5d2YmE2sgxMngzGV7TjkOLpd1qyuTfroSvUjioln5jSFBZEbJZTLqCKcdtmKO4lmdhGWo00xUmrV96K'
export const MODE_DEV = false
export const BASE_URL = "https://scoutzpro.com"
export const BASE_URL_ADMIN = "https://admin.scoutzpro.com/"


// dev
// export const STRIPE_PUBLIC_KEY = "pk_test_51Jok4OGs7CwTG1kMRdrqamQro9Bzl0DxTLXhcZ1B5D8lAP8IUe93Td8AcytbsrP95eeIKXdQzwWIUjScHzL79l2W00P5nO6UEI"
// export const BASE_URL_ADMIN = "https://admin-staging.scoutzpro.com/"
// export const BASE_URL = "https://staging.scoutzpro.com"
// export const MODE_DEV = true


