import React, { useState, useEffect } from "react";
import NewHeader from "../components/newHeader";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageUpload from "../../Component/UI/ImageUpload";
import { Country, City } from "country-state-city";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL_ADMIN } from "../../Utils/utils";

const EditJob = () => {
  const location = useLocation();

  // Initialize jobPostData with location state data
  const [jobPostData, setJobPostData] = useState(
    location?.state?.data ? location?.state?.data : {}
  );

  console.log(location?.state?.data);

  const [selectedCountry, setSelectedCountry] = useState(
    jobPostData?.country || ""
  );
  const [cities, setCities] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const { jobId } = useParams();
  const navigate = useNavigate();

  const countries = Country.getAllCountries();
  let profile = JSON.parse(localStorage.getItem("profile"));

  const options = {
    gender: ["Male", "Female"],
    position: [
      "Point Guard",
      "Shooting Guard",
      "Small Forward",
      "Forward",
      "Center",
    ],
    pay: ["Negotiable", "$X per month"],
    apartmentIncluded: ["Yes", "No"],
    mealsIncluded: ["Yes", "No"],
    playerNeededBy: ["ASAP", "DD/MM/YY"],
    team: ["Name", "Anonymous"],
    experience: ["Current Pros", "College grads", "Rookies", "Any"],
  };

  useEffect(() => {
    if (jobPostData?.country) {
      setCities(City.getCitiesOfCountry(jobPostData.country));
    }
  }, [jobPostData?.country]);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setCities(City.getCitiesOfCountry(selectedCountry));
    setJobPostData((prevData) => ({
      ...prevData,
      country: selectedCountry,
      city: "",
    }));
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setJobPostData((prevData) => ({
      ...prevData,
      city: selectedCity,
    }));
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `${BASE_URL_ADMIN}api/s3-upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const responseData = response.data;
      if (responseData.code === 200) {
        setJobPostData((prevData) => ({
          ...prevData,
          image: responseData.objectUrl,
        }));
      } else {
        throw new Error(responseData.message || "Upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Upload failed!");
    }
  };

  const handleSave = async () => {
    try {
      const completeJobPostData = {
        ...jobPostData,
        website: profile?._id,
        contact: { name: "John Doe", number: "1234567890" },
        jobType: "normal",
      };

      const response = await axios.put(
        `${BASE_URL_ADMIN}jobs/id/${jobPostData?._id}`,
        completeJobPostData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to update the job. Please try again.");
    }
  };


  useEffect(()=>{
    if (jobPostData.playerNeededBy !== "ASAP") {
      setSelectedDate(jobPostData?.playerNeededBy)
    }
  },[location?.state?.data])

  return (
    <Container
      style={{ position: "relative", minHeight: "100vh" }}
      className="create-account-container"
    >
      <NewHeader name={"Edit Job"} path="/home" />
      <Row>
        <Col xs={12}>
          <h3 style={{ color: "#fff" }}>Edit Job</h3>
        </Col>

        <Col xs={12}>
          {/* Image Upload */}
          <ImageUpload
            onImageUpload={handleImageUpload}
            image={jobPostData?.image}
          />

          {/* Job Title */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Title</h2>
          <input
            type="text"
            placeholder="Enter Job Title"
            value={jobPostData.title || ""}
            onChange={(e) =>
              setJobPostData((prevData) => ({
                ...prevData,
                title: e.target.value,
              }))
            }
            className="form-control mt-3"
          />

          {/* Country & City Select */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Country</h2>
          <select
            className="form-control"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>

          <h2 style={{ color: "#fff", marginTop: "10px" }}>City</h2>
          <select
            className="form-control mt-3"
            value={jobPostData.city || ""}
            onChange={handleCityChange}
            disabled={!cities.length}
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>

          {/* Gender Select */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Gender</h2>
          <select
            className="form-control"
            value={jobPostData.gender || ""}
            onChange={(e) =>
              setJobPostData({ ...jobPostData, gender: e.target.value })
            }
          >
            <option value="">Select Gender</option>
            {options.gender.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* Position Select */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Position</h2>
          <select
            className="form-control"
            value={jobPostData.designation || ""}
            onChange={(e) =>
              setJobPostData({ ...jobPostData, designation: e.target.value })
            }
          >
            <option value="">Select Position</option>
            {options.position.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* Pay Select */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Pay</h2>
          <select
            className="form-control"
            value={
              jobPostData?.salary != "Negotiable"
                ? "$X per month"
                : jobPostData.salary
            }
            onChange={(e) =>
              setJobPostData({ ...jobPostData, salary: e.target.value })
            }
          >
            <option value="">Select Pay</option>
            {options.pay.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {jobPostData?.salary != "Negotiable" && (
            <input
              type="text"
              placeholder="Enter Salary Amount"
              value={jobPostData.salary || ""}
              onChange={(e) =>
                setJobPostData({ ...jobPostData, customSalary: e.target.value })
              }
              className="form-control mt-3"
            />
          )}

          <h2 style={{ color: "#fff", marginTop: "10px" }}>
            Apartment Included
          </h2>
          <select
            className="form-control"
            value={jobPostData.apartmentIncluded || ""}
            onChange={(e) =>
              setJobPostData({
                ...jobPostData,
                apartmentIncluded: e.target.value,
              })
            }
          >
            <option value="">Select Apartment Inclusion</option>
            {options.apartmentIncluded.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <h2 style={{ color: "#fff", marginTop: "10px" }}>Meals Included</h2>
          <select
            className="form-control"
            value={jobPostData.mealsIncluded || ""}
            onChange={(e) =>
              setJobPostData({ ...jobPostData, mealsIncluded: e.target.value })
            }
          >
            <option value="">Select Meals Inclusion</option>
            {options.mealsIncluded.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <h2 style={{ color: "#fff", marginTop: "10px" }}>Player Needed By</h2>
          <select
            className="form-control"
            value={jobPostData.playerNeededBy !== "ASAP" ? "DD/MM/YY" : jobPostData.playerNeededBy }
            onChange={(e) =>
              setJobPostData({ ...jobPostData, playerNeededBy: e.target.value })
            }
          >
            <option value="">Select Player Needed By</option>
            {options.playerNeededBy.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {jobPostData.playerNeededBy != "ASAP" && (
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setJobPostData({ ...jobPostData, customDate: date });
              }}
              className="form-control mt-3"
            />
          )}

          {/* Team Select */}
          <h2 style={{ color: "#fff", marginTop: "10px" }}>Team</h2>
          <select
            className="form-control"
            value={jobPostData.team || ""}
            onChange={(e) =>
              setJobPostData({ ...jobPostData, team: e.target.value })
            }
          >
            <option value="">Select Team</option>
            {options.team.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* Save Button */}
          <button
            className="btn btn-primary mt-3"
            onClick={handleSave}
            style={{ display: "block", margin: "auto" }}
          >
            Save Job
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default EditJob;
