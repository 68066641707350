/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from 'react'

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card, Tab, Tabs } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Home.css'
import './HamburguerMenu.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import Logo from '../../../assets/logo.png';
import { ApiContext } from '../../../context/ApiContext';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

const ProDayById = ({_id, src, title, city, country, date}) => {

  const navigate = useNavigate();

  console.log(_id)
  
  const handleOnOpen = () => {
    navigate('/proday/id/' + _id)
  }

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>

      <Row onClick={handleOnOpen} style={{ cursor : 'pointer' }}>
          <Col xs={3}>
              <Image src={src} style={{ objectFit: 'cover', width: '100%', aspectRatio: '1/1', borderRadius : 10 }} />
          </Col>
          <Col xs={8} style={{ color: '#ffffff' }}>
              <h1 style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{title}</h1>
              <p style={{ fontSize: '0.8em', opacity: '0.6' }}>
                  {city}, {country} - {formatDate(date)}
              </p>
          </Col>
      </Row>

    </>
  );

};

function ScrollableJobsRow({images}) {
    return (
        <>
        <div className="d-flex justify-content-between mt-4 mb-2">
          <h3 style= {{fontWeight: 'bold', color:'white'}}>Scoutz Pro Day</h3>
        </div>
        <Row>
          {
            images.map((img, index) => <ProDayById key={index} {...img} />)
          }
        </Row>
        </>
    );
}
 
const EntityList = ({ data }) => {
    const [follow, setFollow] = useState(false);
  
    const handleFollow = () => {
        setFollow(!follow);
    };
  
    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="entity-title">Teams</div>
          </Col>
        </Row>
        {data.map(item => (
          <Row key={item.id} style={{ 'marginBottom' : 15 }}>
            <Col xs={3}>
              <div 
                className="circle-img"
                style={{
                  backgroundImage: `url(${item.image})`,
                }}
              />
            </Col>
            <Col xs={5}>
              <div className="title-team" style={{ 'fontSize' : '1.5em', 'color' : '#fff' }}>{item.title}</div>
              <div className="description">{item.description}</div>
            </Col>
            <Col xs={4} style={{ 'textAlign' : 'right' }}>
              {/*
              <Button onClick={handleFollow}>{follow ? 'Following' : 'Follow'}</Button>*/}
            </Col>
          </Row>
        ))}
      </div>
    );
};

/*



  START PAGE



*/

export default function ProDayOverview() {
  
  const navigate = useNavigate();
  const [key, setKey] = useState('applied');

  const [events, setEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const { setLoading } = useContext(ApiContext);

  // Load Teams
  useEffect(() => {

    const fetchProDaySessions = async () => {

      try {

        setLoading(true);
        const response = await fetch(`${API_ENDPOINT}/events/type/scoutzproday`);
        const data = await response.json();
        

        console.log("Fetch ProDay Data")
        console.log(data.data)

        setEvents(data.data);

        setEventsLoaded(true)

        
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }

    };

    fetchProDaySessions();
    
    
  }, []);

  const data = [
    {
        id: 1,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBxnO690tKIXuCClHqYt5Sgmg8mULSTpLnddttwz0k7g&s',
        title: 'Orangers',
        description: 'High School'
    },
    {
        id: 2,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rcd-xbL9wuKXAgIE6IKsfeWZ0wuh0miummH2nRHW_g&s',
        title: 'Golden Eagles',
        description: 'College'
    },
    // more items here
  ];

  // Verify if user subscription is active

  useEffect(() => {

    let profile = JSON.parse(localStorage.getItem('profile'));

    const fetchData = async () => {

      setLoading(true);

      try {

        const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/subscriptions/active`);
        const data = await response.json();
  
        console.log("Profile Subscription Active")
        console.log(data)
  
        if (!data.data) {
  
          // Redirect
          navigate('/subscriptions/unlock');
  
        } else {
  
        }
  
        //setJobData(data.data);
      } catch(e) {
        console.error(e);
      } finally {
        setLoading(false);
      }

    };
  
    fetchData();
  
  },[]);

  //

  return (
    <>

    <Header />
   
    <Container className="create-account-container">

        <Row>

            <Col xs={12}>

                <Tabs 
                    id="controlled-tab-example" 
                    activeKey={key} 
                    onSelect={(k) => setKey(k)} 
                    className="mb-3"
                >

                    <Tab eventKey="applied" title="Sessions" 
                        className="tabStyle">


                        {/* Recommended Events */}

                        {eventsLoaded && events.length > 0 && (

                          <ScrollableJobsRow 
                            images={events.map((team) => ({
                              src: team.imageUrl,
                              title: team.title,
                              _id: team._id,
                              city : team.city,
                              country : team.country,
                              state : team.state,
                              date : team.time
                            }))}

                          />

                        )}


                    </Tab>

                </Tabs>

            </Col>

        </Row>

    </Container>

    <Footer />
    </>
  );

}