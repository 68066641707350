/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useEffect, useState } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

// API

import Jobs from "../../../api/Jobs";
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

/*



  START PAGE



*/

const JobPage = () => {
  const [jobData, setJobData] = useState(null);
  const [jobId, setJobId] = useState("");
  const [jobsApplied, setJobsApplied] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  let profile = JSON.parse(localStorage.getItem("profile"));

  const jobsObj = new Jobs();

  useEffect(() => {
    fetch(`${API_ENDPOINT}/jobs/id/${params?.id}/applied`, {
      method: "PUT",
      body: JSON.stringify({ profileId: profile?._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data);

        setIsFollowing(data.data); // the data from our api call
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const toggleFollowing = async () => {
    if (profile?._id) {
      try {
        setIsLoading(true)
        await Jobs.toggleApplyJob(params?.id, profile?._id);
        setIsLoading(false)
        navigate("/jobs/my");
        setIsFollowing(!isFollowing);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Please Login First");
      navigate("/accounts/login");
    }
  };

  const fetchJobsData = async () => {
    try {
      const [jobsAppliedData, jobsSavedData] = await jobsObj.getJobsData();

      console.log("Fetch Jobs Applied", jobsAppliedData);
      console.log("Fetch Jobs Saved", jobsSavedData);

      setJobsApplied(jobsAppliedData);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchJobsData();
  }, []);

  const fetchData = async () => {
    const response = await fetch(
      `${API_ENDPOINT}/profiles/jobs/id/${params.id}?.`
    );
    const data = await response.json();

    setJobData(data?.data);
  };

  useEffect(() => {
    fetchData();
  }, [params.id]);


  function ApplyButton({ jobsId }) {
    const newJobsIds = jobsApplied.map((job) => job._id);

    console.log(jobData, "jobData");

    return (
      <>
        <Row>
          <Col xs={12} style={{ textAlign: "center", marginBottom: "40px" }}>
            <button
              disabled={newJobsIds.includes(params.id)}
              style={{
                fontWeight: "bold",
                color: "#FFF",
                height: "45px",
                border: "none",
                borderRadius: "15px",
                width: "100%",
                background: newJobsIds.includes(params.id) ? "gray" : "linear-gradient(to right, #9F3F86, #1BA9CE)",
              }}
              onClick={toggleFollowing}
            >
              {isFollowing
                ? "Remove application"
                : newJobsIds.includes(params.id)
                ? "Already applied"
                : isLoading ? 'Loading...' : "Apply to Job"}
            </button>
          </Col>
        </Row>
      </>
    );
  }

  //

  // Verify if user subscription is active

  // useEffect(() => {
  //   let profile = JSON.parse(localStorage.getItem("profile"));

  //   const fetchData = async () => {
  //     const response = await fetch(
  //       `${API_ENDPOINT}/profiles/id/${profile?._id}/subscriptions/active`
  //     );
  //     const data = await response.json();

  //     console.log("Profile Subscription Active");
  //     console.log(data);

  //     if (data.data == false) {
  //       console.log("Data ");

  //       // Redirect
  //       navigate("/subscriptions/unlock/jobs");
  //     } else {
  //     }
  //   };

  //   fetchData();
  // }, []);

  //

  return (
    <>
      <Header />

      <Container style={{ marginBottom: "55px" }}>
        <Row className="justify-content-md-center">
          <Col xs={12}>
            <img
              src={
                jobData?.image ? jobData?.image : jobData?.profile?.profileImage
              }
              alt="Logo"
              style={{ width: "150px", height: "150px", borderRadius: "50%" }}
            />
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12}>
            <p style={{ background: "blue", color: "white" }}>
              {jobData?.status}
            </p>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {jobData?.title}
            </h2>
            <div style={{ textAlign: "center" }}>
              <p
                style={{ color: "white" }}
              >{`${jobData?.city} - ${jobData?.country}`}</p>
            </div>
          </Col>
        </Row>

        <Row className="mb-4" style={{ color: "white", textAlign: "center" }}>
          <Col xs={4} md={4}>
            <p style={{ fontWeight: "lighter" }}>Salary</p>
            <p style={{ color: "white", fontWeight: "bold" }}>
              {jobData?.salary}
            </p>
          </Col>

          <Col xs={4} md={4}>
            <p style={{ fontWeight: "lighter" }}>Contract</p>
            <p style={{ color: "white", fontWeight: "bold" }}>
              {jobData?.contract}
            </p>
          </Col>

          <Col xs={4} md={4}>
            <p style={{ fontWeight: "lighter" }}>Experience</p>
            <p style={{ color: "white", fontWeight: "bold" }}>
              {jobData?.experience}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h2
              className="mb-2"
              style={{ textAlign: "left", fontWeight: "bold", color: "white" }}
            >
              Job Description
            </h2>
            <p style={{ color: "white", fontWeight: "light" }}>
              {jobData?.description}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h2
              className="mb-2"
              style={{ textAlign: "left", fontWeight: "bold", color: "white" }}
            >
              What you'll do
            </h2>
            <p style={{ color: "white", fontWeight: "light" }}>
              {jobData?.whatYouWillDo}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h2
              className="mb-2"
              style={{ textAlign: "left", fontWeight: "bold", color: "white" }}
            >
              Skills
            </h2>
            <p style={{ color: "white", fontWeight: "light" }}>
              {jobData?.skills}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h2
              className="mb-2"
              style={{ textAlign: "left", fontWeight: "bold", color: "white" }}
            >
              Designation
            </h2>
            <p style={{ color: "white", fontWeight: "light" }}>
              {jobData?.designation}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col xs={12}>
            <h2
              className="mb-2"
              style={{ textAlign: "left", fontWeight: "bold", color: "white" }}
            >
              Contact Number
            </h2>
            <p style={{ color: "white", fontWeight: "light" }}>
              {jobData?.contact?.name} - {jobData?.contact?.number}
            </p>
          </Col>
        </Row>

        {params.id && (
          <div className="mb-10 mt-4">
            <ApplyButton jobsId={params.id} />
          </div>
        )}

        <Row className="justify-content-center">
          <Col xs={12}>
            <div style={{ hegiht: "550px" }}></div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default JobPage;
