/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
  Modal,
} from "react-bootstrap";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useTranslation } from "react-i18next";

// CSS

import "react-horizontal-scrolling-menu/dist/styles.css";
import "./Discover.css";
import "./HamburguerMenu.css";

// Media
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL, BASE_URL_ADMIN } from "../../../Utils/utils";
import {
  Menplayer,
  ProfileLock,
  Womenplayer,
  saveJobIcon,
} from "../../../assets";
import moment from "moment/moment";

// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

/*




  START COMPONENTS



*/

// Athletes

function AthletesTitle({ itemId, src, title, gender, index, ...restProps }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/athletes/id/${itemId}`);
  };

  const placeholderImage =
    gender?.toLowerCase() == "male" ? Menplayer : Womenplayer;
  // "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";

  return (
    <div
      onClick={() =>
        restProps.onClick({ getItemById: undefined, scrollToItem: undefined })
      }
      style={{ width: "80px", margin: index === 0 ? "0 16px 0 8px" : "0 16px" }}
    >
      <Card
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          // width: "8rem",
          backgroundColor: "unset",
          border: "unset",
        }}
      >
        <img
          src={src || placeholderImage}
          alt="$$$"
          style={{
            borderRadius: "50%",
            margin: "5px auto",
            width: "60px",
            height: "60px",
          }}
        />
        <Card.Body style={{ textAlign: "center", padding: "0.25rem" }}>
          <Card.Title
            style={{ color: "#fff", fontSize: "1em", lineHeight: "1.2" }}
          >
            {title.split(" ").map((word, index, array) =>
              index === array.length - 1 ? (
                word
              ) : (
                <React.Fragment key={index}>
                  {word}
                  <br />
                </React.Fragment>
              )
            )}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

export function ScrollablePlayersRow({ images }) {
  let token = localStorage.getItem("token");
  const [selected, setSelected] = React.useState([]);
  const isItemSelected = (id) => !!selected.find((el) => el === id);
  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);
      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  const { t, i18n } = useTranslation();

  console.log(token, "token");

  return (
    <>
      <div className="d-flex justify-content-between mt-2 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>{t("players")}</h3>
        {token ? (
          <Link
            to="/athletes/overview"
            style={{ color: "#fff", fontWeight: "lighter" }}
          >
            See All
          </Link>
        ) : (
          <Link
            to="/accounts/login"
            style={{ color: "#fff", fontWeight: "lighter" }}
          >
            See All
          </Link>
        )}
      </div>

      <div className="col-12 d-flex flex-column mt-2">
        <div style={{ position: "relative", height: "170px", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              overflowX: "scroll",
              // left: "-25px",
            }}
            className="d-flex flex-row overflow-auto pb-4"
          >
            {images.map(({ id, src, title, gender }, index) => (
              <AthletesTitle
                itemId={id}
                src={src}
                title={title}
                gender={gender}
                onClick={handleClick(id)}
                selected={isItemSelected(id)}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

// END Athletes

////////////////

// Teams

const ImageComponent = ({ src, fallbackSrc }) => {
  // Check if 'src' is provided, else use 'fallbackSrc'
  const imageUrl = src ? src : fallbackSrc;

  return (
    <img
      className="circle-img"
      src={imageUrl}
      style={{
        borderRadius: "50%",
        margin: "1px auto",
        width: "60px",
        height: "60px",
      }}
    />
  );
};

export function ScrollableTeamsRow({ images }) {
  let token = localStorage.getItem("token");
  const [selected, setSelected] = React.useState([]);
  const isItemSelected = (id) => !!selected.find((el) => el === id);
  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);
      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className="d-flex justify-content-between mb-2"
        style={{ marginTop: token ? "0px" : "100px" }}
      >
        <h3 style={{ fontWeight: "bold", color: "white" }}>Leagues</h3>
        {token ? (
          <Link
            to="/leagues/overview"
            style={{ color: "#fff", fontWeight: "lighter" }}
          >
            See All
          </Link>
        ) : (
          <Link
            to="/accounts/login"
            style={{ color: "#fff", fontWeight: "lighter" }}
          >
            See All
          </Link>
        )}
      </div>

      <div className="col-12 d-flex flex-column mt-4 mb-2">
        <div style={{ position: "relative", height: "170px", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              overflowX: "scroll",
              // left: "-25px",
            }}
            className="d-flex flex-row overflow-auto pb-4"
          >
            {images?.map(({ id, src, title }, index) => (
              <TeamsTitle
                itemId={id}
                src={src}
                title={title}
                onClick={handleClick(id)}
                selected={isItemSelected(id)}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function TeamsTitle({ itemId, src, title, index, ...restProps }) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/league/${itemId}`);
  };

  return (
    <div
      style={{ width: "80px", margin: index === 0 ? "0 16px 0 8px" : "0 16px" }}
    >
      <Card
        onClick={handleNavigate}
        style={{
          cursor: "pointer",
          // width: "8rem",
          backgroundColor: "unset",
          border: "unset",
        }}
      >
        <ImageComponent
          src={src}
          fallbackSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCifOFpkQ9QbQ_V-Tm1o1FPNpTyrTsHjVqoNCrUmqFxKkDe39qHGOwvbkOIO7ELAykm2Q&usqp=CAU"
        />

        <Card.Body style={{ textAlign: "center", padding: "0.25rem" }}>
          <Card.Title
            style={{ color: "#fff", fontSize: "0.7em", lineHeight: "1.2" }}
          >
            {title.split(" ").map((word, index, array) =>
              index === array.length - 1 ? (
                word
              ) : (
                <React.Fragment key={index}>
                  {word}
                  <br />
                </React.Fragment>
              )
            )}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

// END Teams

const JobsTitle = ({
  src,
  title,
  city,
  id,
  country,
  date,
  gender,
  image,
  handleNavigate,
  jobType,
  payment,
}) => (
  <Row
    style={{ position: "relative" }}
    onClick={() => handleNavigate(id, jobType)}
  >
    <Col
      xs={3}
      style={
        jobType === "exclusive"
          ? {
              filter: payment ? "blur(0px)" : "blur(10px)",
              cursor: "pointer",
              color: "#ffffff",
            }
          : { cursor: "pointer", color: "#ffffff" }
      }
    >
      <Image
        src={src}
        style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1" }}
      />
    </Col>
    <Col
      xs={8}
      style={
        jobType === "exclusive"
          ? {
              filter: payment ? "blur(0px)" : "blur(10px)",
              cursor: "pointer",
              color: "#ffffff",
            }
          : { cursor: "pointer", color: "#ffffff" }
      }
    >
      <div
        style={{
          borderRadius: "100px",
          padding: 3,
          color: "#fff",
          backgroundColor: "#1BA9CE",
          display: "inline-block",
          fontSize: "0.7em",
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 4,
        }}
      >
        Active
      </div>
      {gender && (
        <p
          style={{
            borderRadius: "100px",
            padding: 3,
            color: "#fff",
            backgroundColor: "rgba(159, 63, 134, 1)",
            display: "inline-block",
            fontSize: "0.7em",
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 4,
            marginLeft: "10px",
            cursor: "pointer",
          }}
        >
          {gender == "male" ? "Men" : "Women"}
        </p>
      )}
      {jobType === "exclusive" && (
        <p
          style={{
            borderRadius: "100px",
            padding: 3,
            color: "#fff",
            backgroundColor: "rgba(159, 63, 134, 1)",
            display: "inline-block",
            fontSize: "0.7em",
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 4,
            marginLeft: "10px",
            cursor: "pointer",
            textTransform: "capitalize",
          }}
        >
          {jobType}
        </p>
      )}
      <h1 style={{ fontSize: "1.3em", fontWeight: "bold" }}>{title}</h1>
      <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
        {city}, {country} - {moment(date).format("MM-DD-YYYY")}
      </p>
    </Col>
    <Col
      xs={1}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   saveJob();
      // }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
      }}
    >
      <img src={saveJobIcon} alt="$$$" />
      {/* <FontAwesomeIcon icon={faBookBookmark} style={{ color: iconColor }} /> */}
    </Col>

    {jobType === "exclusive" ? (
      payment ? null : (
        <div>
          <div className="exclusiveBlurLeft">
            <img src={ProfileLock} alt="$$$" />
          </div>
          <div className="exclusiveBlurRight">
            <h2 style={{ color: "#ffff" }}>Exclusive opening</h2>
          </div>
        </div>
      )
    ) : null}
  </Row>
);

function NewsTitle({ id, src, title, city, country, date }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/news/id/${id}`);
  };

  return (
    <>
      <Row onClick={handleNavigate}>
        <Col xs={3}>
          <Image
            src={src}
            style={{
              objectFit: "cover",
              width: "100%",
              aspectRatio: "1/1",
              borderRadius: 10,
            }}
          />
        </Col>
        <Col xs={8} style={{ color: "#ffffff" }}>
          <h1 style={{ fontSize: "1.3em", fontWeight: "bold" }}>{title}</h1>
          <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
            {city}, {country} - {date}
          </p>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          {/*<FontAwesomeIcon icon={faBookBookmark} />*/}
        </Col>
      </Row>
    </>
  );
}

export function ScrollableJobsRow({ images }) {
  const navigate = useNavigate();
  const [jobModalIsOpen, setJobModalIsOpen] = useState(false);

  const profile = JSON.parse(localStorage.getItem("profile"));

  const subscriptionData =
    profile?.paymentMethods?.[profile?.paymentMethods?.length - 1]
      ?.subscription;

  const payment =
    (subscriptionData?.status === "incomplete_expired" ||
      subscriptionData?.status === "canceled") &&
    new Date().toISOString() >
      new Date(subscriptionData?.current_period_end * 1000).toISOString()
      ? false
      : subscriptionData?.id && true;

  function handleNavigate(id, jobType) {
    if (payment && jobType === "exclusive") {
      navigate(`/jobs/id/${id}`);
    } else if (!payment && jobType === "normal") {
      navigate(`/jobs/id/${id}`);
    } else {
      setJobModalIsOpen(true);
    }
  }

  const handleClose = () => {
    setJobModalIsOpen(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Jobs</h3>
        <Link
          to="/jobs/overview"
          style={{ color: "#fff", fontWeight: "lighter" }}
        ></Link>
      </div>
      <Row>
        {images.map((img, index) => (
          <JobsTitle
            key={index}
            {...img}
            handleNavigate={handleNavigate}
            payment={payment}
          />
        ))}
      </Row>

      <Modal
        show={jobModalIsOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            Attention!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="attention">
            <p>
              sorry you must be on a subscription to view this exclusive opening
            </p>
            <div className="footerBtn">
              <button
                onClick={() => setJobModalIsOpen(false)}
                type="button"
                class="linkBtn"
              >
                Cancel
              </button>
              <button
                style={{
                  fontWeight: "bold",
                  color: "#FFF",
                  height: "45px",
                  border: "none",
                  borderRadius: "15px",
                  width: "100%",
                  background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                  padding: "10px 40px",
                }}
                onClick={() => navigate("/settings/paymentmethods")}
              >
                Subscribe
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function ScrollableNewsRow({ images }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>
          {t("modules.news")}
        </h3>
      </div>
      <Row style={{ marginBottom: "70px" }}>
        {images.map((img, index) => (
          <NewsTitle key={index} {...img} />
        ))}
      </Row>
    </>
  );
}

export function UserComponent({ userData }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    img: {
      borderRadius: "50%",
      marginTop: "15px",
      height: "100px",
      width: "100px",
      margin: 0,
    },
    name: {
      color: "#fff",
      fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "0px",
    },
    nickname: {
      fontWeight: "lighter",
      opacity: 0.6,
      marginBottom: "25px",
    },
  };

  return (
    <div style={styles.container}>
      <img style={styles.img} src={userData.image} alt="User Profile" />
      <div style={styles.name}>{userData.name}</div>
      <div style={styles.nickname}>@{userData.nickname}</div>
    </div>
  );
}

function Discover() {
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  const [athletes, setAthletes] = useState([]);
  const [athletesLoaded, setAthletesLoaded] = useState(false);

  const [newsArticles, setNewsArticles] = useState([]);
  const [newsLoaded, setNewsLoaded] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const { setLoading } = useContext(ApiContext);

  const completeUser = JSON.parse(localStorage.getItem("profile"));

  // Load Teams
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${BASE_URL_ADMIN}/league/leagues`);
        const data = await response.json();
        setTeams(data?.data);

        setTeamsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  // Load Recommended Athletes
  useEffect(() => {
    const fetchAthleteData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/profiles/type/athletes`);
        const data = await response.json();

        console.log("Fetch Team Data");
        console.log(data.data);

        setAthletes(data.data.docs);

        setAthletesLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAthleteData();
  }, []);

  // Load News
  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/news`);
        const data = await response.json();

        console.log("Fetch News Data");
        console.log(data.data);

        setNewsArticles(data.data);

        setNewsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, []);

  // Load Recommended Jobs
  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        setLoading(true);
        const profile = JSON.parse(localStorage.getItem("profile"));
        const response = await fetch(`${API_ENDPOINT}/profiles/jobs?gender}`);
        const data = await response.json();

        if (completeUser?.people?.[0]?.gender) {
          console.log(
            completeUser?.people?.[0]?.gender,
            "completeUser?.people?.[0]?.gender"
          );
          const filterData = data.data.docs?.filter((item) =>
            item?.gender
              ? item?.gender.toLowerCase() ==
                completeUser?.people?.[0]?.gender.toLowerCase()
              : null
          );
          console.log(filterData, "filterData");
          setJobs(filterData);
        } else {
          setJobs(data.data.docs);
        }

        setJobsLoaded(true);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobsData();
  }, []);

  return (
    <>
      <Header />

      <Container className="create-account-container">
        {/* Recommended Teams */}

        {teamsLoaded && teams?.length > 0 && (
          <ScrollableTeamsRow
            images={teams?.map((team) => ({
              src: team?.image,
              title: team?.name,
              id: team?._id,
            }))}
          />
        )}

        {athletesLoaded && athletes.length > 0 && (
          <ScrollablePlayersRow
            images={athletes.map((team) => ({
              src: team?.profileImage,
              title: team?.title,
              id: team?._id,
              gender: team?.people[0]?.gender || "",
            }))}
          />
        )}

        {/* Recommended Teams */}

        {jobsLoaded && jobs.length > 0 && (
          <ScrollableJobsRow
            images={jobs.map((job) => ({
              src: job?.image ? job?.image : job.profile.profileImage,
              id: job._id,
              title: job.title,
              city: job.city,
              country: job.country,
              date: job.createdAt,
              saved: job.saved,
              gender: job.gender,
              jobType: job.jobType,
            }))}
          />
        )}

        <div style={{ height: "100px" }}></div>
      </Container>

      <Footer />
    </>
  );
}

export default Discover;
