/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar, Modal, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Unlock.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START PAGE



*/

export default function SubscriptionUnlock() {

    const [step, setStep] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);

    const handleShow = () => {

        navigate('/subscriptions/start');

    }

    // First Step
    const [username, setUsername] = useState('');

    const handleStartForm = () => {
        navigate('/subscriptions/start');
    }

    return (
        <>
        <Header />
            <Container className="create-account-container">

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center", "marginBottom" : "45px" }}>
                
                <img className="mt-4 mb-4" src="https://cdn3d.iconscout.com/3d/premium/thumb/key-6338353-5220261.png" height="255px" />
                <h1 className="mt-4 mb-4" style={{ "color" : "#fff" }}>Boost your Career</h1>
                <h3 className="mt-6" style={{ "color" : "#fff", "fontWeight" : '300' }}>Sign up for an active subscription and enhance your career by accessing all Scoutz network</h3>

                <div style={{ "marginTop" : "55px" }}>

                    <Button onClick={handleShow} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Get Started</Button>
                
                </div>

            </Col>

            </Row>

            <Row>

                <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                    <div  style={{ "height" : "150px" }}></div>
                </Col>

            </Row>

            </Container>
        <Footer />
        </>
    );
}