/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from "react";
import { Country, State, City } from "country-state-city";

// Components

import UserComponent from "./UserComponent";

// Packages

import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// API

import Profile from "../../../../api/Profile";

// JSON

// import countries from './countries.json';

function AthleteForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  phoneInternationalCode,
  setPhoneInternationalCode,
  phoneNumber,
  setPhoneNumber,
}) {
  const profile = new Profile();

  const navigate = useNavigate();

  const [username, setUsername] = useState(email.split("@")[0]);
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [sport, setSport] = useState("");
  const [feets, setFeets] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [position, setPosition] = useState("");
  const [nationality, setNationality] = useState("");
  const [profileImage, setUserImage] = useState("");
  const [errors, setErrors] = useState({});

  const phone = phoneNumber;

  const type = "athlete";

  async function handleSubmit(userData) {
    try {
      setErrors({});
      const user = await profile.create(userData, navigate);
      console.log("User created:", user);
      window.location.pathname = "/"
    } catch (error) {
      console.error("Failed to create user:", error);
    }
  }

  const handleCreateAccount = () => {
    let isValid = true;

    if (!username) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        username: "Username is required",
      }));
      isValid = false;
    }
    if (!gender) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        gender: "gender is required",
      }));
      isValid = false;
    }
    if (!birthday) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        birthday: "Birthday is required",
      }));
      isValid = false;
    }
    if (!state) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        state: "state is required",
      }));
      isValid = false;
    }
    if (!city) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        city: "city is required",
      }));
      isValid = false;
    }
    if (!country) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        country: "Country is required",
      }));
      isValid = false;
    }
    if (!sport) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        sport: "Confirm Password is required",
      }));
      isValid = false;
    }
    if (!feets) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        feets: "feets is required",
      }));
      isValid = false;
    }
    if (!inches) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        inches: "inches is required",
      }));
      isValid = false;
    }
    if (!weight) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        weight: "weight is required",
      }));
      isValid = false;
    }
    if (!currentTeam) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        currentTeam: "currentTeam is required",
      }));
      isValid = false;
    }

    if (!position) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        position: "position is required",
      }));
      isValid = false;
    }
    if (!nationality) {
      setErrors((previousErrors) => ({
        ...previousErrors,
        nationality: "position is required",
      }));
      isValid = false;
    }
    console.log(isValid, "isValid before");

    if (isValid) {
      console.log(isValid, "isValid after");
      const userData = {
        type,
        profileImage,
        firstName,
        phoneInternationalCode,
        lastName,
        email,
        password,
        phone,
        username,
        gender,
        birthday,
        country,
        state,
        city,
        sport,
        feets,
        inches,
        weight,
        currentTeam,
        position,
        nationality,
      };
      console.log("User Data is: ");
      console.log(userData);
      handleSubmit(userData);
    }
  };

  const handleCreateAccountSkip = () => {
    const userData = {
      type,
      profileImage,
      firstName,
      phoneInternationalCode,
      lastName,
      email,
      password,
      phone,
      username,
      gender,
      birthday,
      country,
      state,
      city,
      sport,
      feets,
      inches,
      weight,
      currentTeam,
      position,
      nationality,
    };
    console.log("User Data is: ");
    console.log(userData);
    handleSubmit(userData);
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    if (
      (value.length === 2 || value.length === 5) &&
      value[value.length - 1] !== "/"
    ) {
      value += "/";
    }

    let month = value.split("/")[0];
    let day = value.split("/")[1];

    if (month > 12 || day > 31) {
      return;
    }

    setBirthday(value);
  };

  const handleInputBlur = (e) => {
    e.preventDefault();
    const pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

    if (!pattern.test(e.target.value)) {
      alert("Please follow the date pattern MM/DD/YYYY");
      setBirthday("");
    }
  };
  console.log(Country,"Country");

  return (
    <>
      <div className="row">
        <div className="col-12">
          <UserComponent
            userData={{ image: "", name: "", nickname: "" }}
            setUserImage={setUserImage}
          />
        </div>

        <div className="col-12">
          <Form.Group controlId="formFirstName">
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              style={{ width: "100%" }}
            />
            {errors && errors.username && (
              <Form.Label className="error">{errors.username}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Form.Group controlId="formGender">
            <Form.Control
              as="select"
              className="form-dropdown"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Control>
            {errors && errors.gender && (
              <Form.Label className="error">{errors.gender}</Form.Label>
            )}
          </Form.Group>
        </div>

        <div className="col-6">
          <Form.Group controlId="formBirthday">
            <Form.Control
              type="text"
              value={birthday}
              onChange={handleInputChange}
              placeholder="Birthdate - MM/DD/YYYY"
              maxLength="10"
              style={{ width: "100%" }}
            />
            {errors && errors.birthday && (
              <Form.Label className="error">{errors.birthday}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Form.Group controlId="formTeamLocation">
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              className="form-dropdown"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <option value="" disabled>
                Please select country
              </option>
              {Country.getAllCountries().map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Form.Control>
            {errors && errors.country && (
              <Form.Label className="error">{errors.country}</Form.Label>
            )}
          </Form.Group>
        </div>

        <div className="col-6">
          <Form.Group className="mb-4" controlId="formState">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              className="form-dropdown"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
                <option value="" disabled>
                Please select state
              </option>
              {State.getStatesOfCountry(country).map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </Form.Control>
            {errors && errors.state && (
              <Form.Label className="error">{errors.state}</Form.Label>
            )}
          </Form.Group>

          {
            <Form.Group controlId="formTeamLocation">
              <Form.Label>City</Form.Label>
              <Form.Control
                as="select"
                className="form-dropdown"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                <option value="" disabled>
                Please select city
              </option>
                {City.getCitiesOfState(country, state).map((city) => (
                  <option key={city.isoCode} value={city.isoCode}>
                    {city.name}
                  </option>
                ))}
              </Form.Control>
              {errors && errors.city && (
                <Form.Label className="error">{errors.city}</Form.Label>
              )}
            </Form.Group>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div style={{ color: "#fff" }}>Select your sport</div>
        </div>
        <div className="col-6">
          <Form.Group controlId="formSport">
            <Form.Control
              as="select"
              className="form-dropdown"
              value={sport}
              onChange={(e) => setSport(e.target.value)}
            >
              <option value="">Select your Sport</option>
              <option value="Basketball">Basketball</option>
            </Form.Control>
            {errors && errors.sport && (
              <Form.Label className="error">{errors.sport}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Form.Group controlId="formFeets">
            <Form.Control
              type="tel"
              value={feets}
              onChange={(e) => setFeets(e.target.value)}
              placeholder="Feet"
              style={{ width: "100%" }}
            />
            {errors && errors.feets && (
              <Form.Label className="error">{errors.feets}</Form.Label>
            )}
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group controlId="formBirthday">
            <Form.Control
              type="tel"
              value={inches}
              onChange={(e) => setInches(e.target.value)}
              placeholder="Inches"
              style={{ width: "100%" }}
            />
            {errors && errors.inches && (
              <Form.Label className="error">{errors.inches}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Form.Group controlId="formWeight">
            <Form.Control
              type="tel"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Weight"
              style={{ width: "100%" }}
            />
            {errors && errors.weight && (
              <Form.Label className="error">{errors.weight}</Form.Label>
            )}
          </Form.Group>
        </div>

        <div className="col-6">
          <div style={{ color: "#fff", lineHeight: "36px", height: "36px" }}>
            lbs
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Form.Group controlId="formBirthday">
            <Form.Control
              type="text"
              value={currentTeam}
              onChange={(e) => setCurrentTeam(e.target.value)}
              placeholder="Current team"
              style={{ width: "100%" }}
            />
            {errors && errors.currentTeam && (
              <Form.Label className="error">{errors.currentTeam}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Form.Group controlId="formPosition">
            <Form.Control
              as="select"
              className="form-dropdown"
              value={position} // set the value to the state variable
              onChange={(e) => setPosition(e.target.value)} // update the state as the user makes a selection
            >
              <option value="">Select your Position</option>
              <option value="center">Center</option>
              <option value="pointguard">Point Guard</option>
              <option value="shootinguard">Shooting Guard</option>
              <option value="smallforwad">Small Forward</option>
              <option value="powerforward">Power Forward</option>
              {/* Add additional options as necessary */}
            </Form.Control>
            {errors && errors.position && (
              <Form.Label className="error">{errors.position}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Form.Group controlId="formPosition">
            <Form.Control
              as="select"
              className="form-dropdown"
              value={nationality} // set the value to the state variable
              onChange={(e) => setNationality(e.target.value)} // update the state as the user makes a selection
            >
              <option value="">Select your Nationality</option>
              <option value="Asia">Asia</option>
              <option value="Africa">Africa</option>
              <option value="Europe">Europe</option>
              <option value="Australia">Australia</option>
              <option value="Canada">Canada</option>
              <option value="South America">South America</option>
              <option value="USA">USA</option>
              {/* Add additional options as necessary */}
            </Form.Control>
            {errors && errors.nationality && (
              <Form.Label className="error">{errors.nationality}</Form.Label>
            )}
          </Form.Group>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <Button
            variant="primary"
            style={{
              border: "1px solid",
              width: "100%",
              background: "transparent",
            }}
            onClick={handleCreateAccountSkip}
          >
            Skip Now
          </Button>
        </div>
        <div className="col-6">
          <Button
            variant="primary"
            style={{
              border: "none",
              width: "100%",
              background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
            }}
            onClick={handleCreateAccount}
          >
            Create Account
          </Button>
        </div>
      </div>
    </>
  );
}

export default AthleteForm;
