import React, { useState } from "react";
import { Vector } from "../../../assets";

const ImageUpload = ({ onImageUpload, image }) => {
  console.log(image,"image")
  const [imagePreview, setImagePreview] = useState(image);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        onImageUpload(file); // Pass the selected file to parent component
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        border: "2px dashed #555",
        borderRadius: "10px",
        position: "relative",
        backgroundColor: "#222",
      }}
    >
      <label
        htmlFor="image-upload"
        style={{ display: "block", cursor: "pointer", color: "#fff" }}
      >
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="Job image"
            style={{ width: "100%", borderRadius: "10px", objectFit: "cover" }}
          />
        ) : (
          <div style={{ padding: "40px 0" }}>
            <img
              src={Vector}
              alt="Placeholder"
              style={{ width: "50px", marginBottom: "10px" }}
            />
            <p>Job image</p>
          </div>
        )}
      </label>

      <input
        id="image-upload"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageUpload;
