/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar, Modal, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

// CSS

import './More.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START PAGE



*/

export default function ScoutzLinkEdit() {

    const [subscriptionId, setSubscriptionId] = useState(''); //Replace with actual subscription id

    const navigate = useNavigate();

    const handleNavigateToVideoCreateAttack = () => {

        navigate('/videos/create?t=attack')

    }

    const handleNavigateToVideoCreateDefense = () => {

        navigate('/videos/create?t=defense')

    }

    const handleNavigateToVideoCreateFullTape = () => {

        navigate('/videos/create?t=fulltape')

    }

    const handleNavigateToVideoCreateHighlights = () => {

        navigate('/videos/create?t=highlights')

    }

    return (
        <>

        <Header />

            <Container>

                <Row>
                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left", "marginBottom" : "45px" }}>
                        <h1 className="mt-4 mb-4" style={{ "color" : "#fff" }}>Scoutz Link</h1>
                        <h3 className="mt-6" style={{ "color" : "#fff", "fontWeight" : '300' }}>Configure your profile</h3>
                        <div style={{ "marginTop" : "55px" }}>
                            <Button onClick={handleNavigateToVideoCreateAttack} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Update Offensive highlights</Button>
                        </div>
                        <div style={{ "marginTop" : "35px" }}>
                            <Button onClick={handleNavigateToVideoCreateDefense} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Update Defensive highlights </Button>
                        </div>
                        <div style={{ "marginTop" : "35px" }}>
                            <Button onClick={handleNavigateToVideoCreateFullTape} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Update Full Game film</Button>
                        </div>
                        <div style={{ "marginTop" : "35px" }}>
                            <Button onClick={handleNavigateToVideoCreateHighlights} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Update Highlights</Button>
                        </div>
                    </Col>
                </Row>

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left", "marginBottom" : "45px" }}>

                    </Col>

                </Row>    


            </Container>

        <Footer />
        </>
    );
}