import { BASE_URL } from "../Utils/utils";

const API_ENDPOINT = BASE_URL

export default class Profile {

    constructor() {
       this.apiUrl = BASE_URL;
      //  this.apiUrl = 'BASE_URL'; 
       this.profile = JSON.parse(localStorage.getItem('profile'));
    }
 
    async create(userData, navigate) {

       try {

        // Define the email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Perform validation checks
        if (!emailRegex.test(userData.email)) {
            alert('Invalid email address.');
            return;
        } else if (userData.firstName.length <= 1 || userData.lastName.length <= 1 || userData.password.length <= 1) {
            alert('First name, Last name, and Password must each be more than 1 characters.');
            return;
        }

        const formData = new URLSearchParams();

        for (const key in userData) {
            formData.append(key, userData[key]);
        }

        const response = await fetch(`${API_ENDPOINT}/profiles`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        });

        const data = await response.json();

        if (data.message && data.message.includes('successfully')) {

            alert('Account created! You can now login.');

            localStorage.clear();
            localStorage.setItem('token', data.token);
            localStorage.setItem('profileType', data.type);
            localStorage.setItem('profile', JSON.stringify(data.profile));

            console.log("Profile inside: ")
            console.log(localStorage.getItem('profile'))

            navigate('/home');

        } else {
            // Handle failed account creation
            alert(data.error || 'Failed to create account.');
        }

       } catch (error) {
            console.error("Error creating account:", error);
            alert('An error occurred during account creation.'); 
       }

    }
 
    async authenticate(credentials) {
       try {
          const response = await fetch(`${this.apiUrl}/users/authenticate`, {
             method: 'POST',
             headers: {
                'Content-Type': 'application/json'
             },
             body: JSON.stringify(credentials)
          });
 
          if (response.ok) {
             const data = await response.json();
             localStorage.setItem('token', data.token); 
             return data;
          } else {
             throw new Error('Authentication failed');
          }
       } catch (error) {
          console.error('Error during authentication:', error);
          throw error;
       }
    }

    async verifyDocuments(fileAddress, documentType) {

      const storedProfile = localStorage.getItem('profile');
      const token = localStorage.getItem('token');

      const profile = JSON.parse(storedProfile);
      const profileId = profile._id

      const response = await fetch(`${API_ENDPOINT}/profiles/id/${profileId}/documents/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          },
          body: JSON.stringify({ fileAddress, documentType })
      });
      
      return await response.json();
    }

    async rating(userData) {
      
      const token = localStorage.getItem('token');

      const formData = new URLSearchParams();
      for (const key in userData) {
         formData.append(key, userData[key]);
      }

      let profileId = this.profile._id

      formData.append('profile', profileId)

      let url = `${API_ENDPOINT}/profiles/id/${profileId}/rating`

      const response = await fetch(url, {
         method: 'PUT',
         headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               'token': token
         },
         body: formData.toString()
      });

      if (!response.ok) {
         // Throw an error if response was not ok
         throw new Error('Error sending Scoutz Rating');
      }

   }

   handleConfirm = (userData) => {
      this.rating(userData);
   }

}