import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Terms.css'

function Terms() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    return (
        <>
            <Header />
            <Container className="create-account-container text-white">
                
                <h1>Terms of Service for Scoutz Sports Inc</h1>
                <p>Effective Date: January 26, 2024</p>

                <h2>1. Acceptance of Terms</h2>
                <p>1.1 By accessing or using the Scoutz platform, you agree to comply with and be bound by these Terms of Service.</p>

                <h2>2. User Eligibility</h2>
                <p>2.1 To use Scoutz, you must be at least 18 years old and possess the legal authority to enter into these terms.</p>

                <h2>3. User Accounts</h2>
                <p>3.1 You are responsible for maintaining the confidentiality of your account and password. Any activities that occur under your account are your responsibility.</p>

                <h2>4. Use of Scoutz</h2>
                <p>4.1 Scoutz grants you a non-exclusive, non-transferable, revocable license to use the platform for its intended purposes.</p>
                <p>4.2 You agree not to use Scoutz for any unlawful purpose or in a way that violates these Terms of Service.</p>

                <h2>5. Privacy</h2>
                <p>5.1 Your use of Scoutz is also governed by our Privacy Policy. By using the platform, you consent to the terms outlined in the Privacy Policy.</p>

                <h2>6. User Content</h2>
                <p>6.1 By submitting content to Scoutz, you grant us a worldwide, non-exclusive license to use, modify, and display that content.</p>

                <h2>7. Intellectual Property</h2>
                <p>7.1 All content on Scoutz, including but not limited to text, graphics, logos, and images, is the property of Scoutz Sports Inc and is protected by copyright laws.</p>

                <h2>8. Termination</h2>
                <p>8.1 Scoutz reserves the right to terminate or suspend your account for any reason at its sole discretion.</p>

                <h2>9. Changes to Terms</h2>
                <p>9.1 Scoutz may modify these Terms of Service at any time. Notification of changes will be provided, and your continued use of the platform after such modifications will constitute your acknowledgment and acceptance of the updated terms.</p>

                <h2>10. Limitation of Liability</h2>
                <p>10.1 Scoutz is provided "as is" without any warranties. We are not liable for any damages resulting from your use of the platform.</p>

                <h2>11. Governing Law</h2>
                <p>11.1 These Terms of Service are governed by and construed in accordance with the laws of the state of Florida.</p>

                <h2>12. Contact Information</h2>
                <p>12.1 For questions or concerns about these Terms of Service, contact us at Info@scoutzapp.com.</p>

                <p>By using Scoutz, you agree to abide by these Terms of Service.</p>

            </Container>
            <Footer />
        </>
    );
}

export default Terms;