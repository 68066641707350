import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAirFreshener,
  faBookBookmark,
  faGlobe,
  faEye,
  faSearch,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
} from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Chart from "react-apexcharts";

import Twitter from "../../../assets/icons/socialmedia/twitter.png";
import Instagram from "../../../assets/icons/socialmedia/instagram.png";
import TikTok from "../../../assets/icons/socialmedia/tiktok.png";
import Web from "../../../assets/icons/socialmedia/web.png";
import YouTube from "../../../assets/icons/socialmedia/youtube.png";
import { BASE_URL } from "../../../Utils/utils";
import { CoachIcon, Menplayer } from "../../../assets";

//const API_ENDPOINT = "BASE_URL"
const API_ENDPOINT = BASE_URL;

function ProfileStatistics() {
  const { id: profileId } = useParams();

  const [athleteStats, setAthleteStats] = useState("");

  useEffect(() => {
    const fetchAthleteData = async () => {
      const athResponse = await fetch(
        `${API_ENDPOINT}/profiles/id/${profileId}/athlete`
      );
      const athData = await athResponse.json();

      setAthleteStats((prevState) => ({
        ...prevState,
        height: `${athData.data.feets}'${athData.data.inches}"`, // Assuming height is provided in feet and inches
        weight: `${athData.data.weight}kg`,
        level: athData.data.level,
        position: athData.data.position,
        number: athData.data.number,
        currentTeam: athData.data.currentTeam,
        sport: athData.data.sport,
        country: "", // Replace with actual key for country if different
      }));
    };

    fetchAthleteData();
  }, [profileId]);

  const {
    height,
    weight,
    level,
    position,
    number,
    currentTeam,
    sport,
    country,
  } = athleteStats;

  return (
    <>
      <Row
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          color: "#fff",
          textAlign: "center",
          marginBottom: "5px",
        }}
      >
        <Col xs={4}>
          <div>Height</div>
          <b>{height}</b>
        </Col>
        <Col xs={4}>
          <div>Weight</div>
          <b>{weight}</b>
        </Col>
        <Col xs={4}>
          <div>Country</div>
          <b>{country}</b>
        </Col>
      </Row>

      <Row
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Col xs={4}>
          <div>Level</div>
          <b>{level || "2"}</b>
        </Col>
        <Col xs={4}>
          <div>Current Team</div>
          <b>{currentTeam || "Osceola Magic"}</b>
        </Col>
        <Col xs={4}>
          <div>Position</div>
          <b>{position || "Shooting Guard"}</b>
        </Col>
      </Row>
    </>
  );
}

export const FollowersFollowing = ({ followers, following }) => {
  console.log("Render followers FollowersFollowing");
  console.log(followers);
  console.log("Render following");
  console.log(following);

  return (
    <Row style={{ textAlign: "center", color: "#fff" }}>
      <Col xs={6} className="border-right">
        <h2 className="font-weight-bold">{followers}</h2>
        <p className="text-white-50">Followers</p>
      </Col>
      <Col xs={6}>
        <h2 className="font-weight-bold">{following}</h2>
        <p className="text-white-50">Following</p>
      </Col>
    </Row>
  );
};

export const SocialMedia = ({ links }) => {
  // Loop profileData.links here

  console.log("Profile Links are ");
  console.log(links);

  return (
    <Col xs={12} className="text-center mb-1">
      {links.map(({ type, link, _id }) => {
        let imgSrc;
        switch (type) {
          case "twitter":
            imgSrc = Twitter;
            break;
          case "instagram":
            imgSrc = Instagram;
            break;
          case "tiktok":
            imgSrc = TikTok;
            break;
          case "website":
            imgSrc = Web;
            break;
          case "youtube":
            imgSrc = YouTube;
            break;
          default:
            imgSrc = Web; // default image in case the type doesn't match
        }
        return (
          <div
            key={_id}
            className="mr-3"
            style={{ display: "inline-block", paddingRight: "15px" }}
          >
            <Link to={`https://${link}`} target="_blank" rel="noreferrer">
              <img src={imgSrc} width="30" />
            </Link>
          </div>
        );
      })}
    </Col>
  );
};

function FollowUnfollowButton({ profileId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let profile = localStorage.getItem("profile");
    console.log(JSON.parse(profile));
    profile = JSON.parse(profile);

    console.log(profileId);
    console.log(profile._id);

    // replace with your api url
    fetch(`${API_ENDPOINT}/profiles/id/${profileId}/following`, {
      method: "PUT",
      body: JSON.stringify({ profileId: profile._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsFollowing(data.data); // the data from our api call
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [profileId]);

  const toggleFollowing = () => {
    let profile = localStorage.getItem("profile");
    console.log(JSON.parse(profile));
    profile = JSON.parse(profile);

    console.log(profileId);
    console.log(profile._id);

    // replace with your api url
    fetch(`${API_ENDPOINT}/profiles/id/${profileId}/following`, {
      method: "POST",
      body: JSON.stringify({ profileId: profile._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setIsFollowing(!isFollowing);
        navigate(0);
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) {
    return <button disabled>...</button>; // replace with your chimmery loading
  }

  return (
    <>
      <Row>
        <Col xs={12} style={{ textAlign: "center" }}>
          <button
            style={{
              padding: "10px",
              paddingLeft: "30px",
              paddingRight: "30px",
              backgroundColor: "aquamarine",
              border: "0",
              borderRadius: "15px",
              color: "#fff",
              "font-weight": "bold",
            }}
            onClick={toggleFollowing}
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </button>
        </Col>
      </Row>
    </>
  );
}

function UserComponent({ userData }) {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    img: {
      borderRadius: "50%",
      marginTop: "15px",
      height: "100px",
      width: "100px",
      margin: 0,
    },
    name: {
      color: "#fff",
      fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "0px",
      fontSize: "1.5em",
    },
    nickname: {
      fontWeight: "lighter",
      opacity: 0.8,
      marginBottom: "25px",
      color: "#fff",
    },
  };

  const placeholderImage = userData.image
    ? userData.image
    : userData?.userType === "coach"
    ? CoachIcon
    : Menplayer;

  return (
    <div style={styles.container}>
      <img style={styles.img} src={placeholderImage} alt="User Profile" />
      <div></div>
      <div style={styles.name}>{userData.name}</div>
      <div style={styles.nickname}>@{userData.nickname}</div>
    </div>
  );
}

function NewsHighlight({ src, title }) {
  return (
    <Card
      style={{
        width: "12rem",
        marginRight: "16px",
        backgroundColor: "unset",
        border: "unset",
      }}
    >
      <Card.Img
        src={src}
        style={{ borderRadius: "15px", margin: "5px auto", height: "110px" }}
      />
      <Card.Body style={{ textAlign: "left", padding: 0 }}>
        <Card.Title style={{ color: "#fff", fontSize: "0.8em" }}>
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

const JobsTitle = ({ src, title, city, country, date }) => (
  <>
    <Col xs={3} style={{ textAlign: "center" }}>
      <Image
        src={src}
        style={{
          objectFit: "cover",
          width: "100%",
          aspectRatio: "1/1",
          borderRadius: "15px",
        }}
      />
    </Col>
    <Col xs={8} style={{ color: "#ffffff" }}>
      <h1 style={{ fontSize: "1em", fontWeight: "bold" }}>{title}</h1>
      <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
        {city}, {country} - {date}
      </p>
    </Col>
    <Col
      xs={1}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
      }}
    >
      <FontAwesomeIcon icon={faBookBookmark} />
    </Col>
  </>
);

export function ScrollableInstagramPhotosRow({ images }) {
  return (
    <>
      <div className="d-flex mt-4 mb-4">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Instagram Photos</h3>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {images.map((img, index) => (
          <NewsHighlight key={index} {...img} />
        ))}
      </div>
    </>
  );
}

export function ScrollableHighlightsRow({ images }) {
  return (
    <>
      <div className="d-flex mt-4 mb-4">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Highlights</h3>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {images.map((img, index) => (
          <NewsHighlight key={index} {...img} />
        ))}
      </div>
    </>
  );
}

export function ScrollableNewsRow({ images }) {
  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Trending</h3>
      </div>
      <Row>
        {images.map((img, index) => (
          <JobsTitle key={index} {...img} />
        ))}
      </Row>
    </>
  );
}

function StaffItem({ src, title }) {
  return (
    <Card
      style={{
        width: "8rem",
        marginRight: "16px",
        backgroundColor: "unset",
        border: "unset",
      }}
    >
      <Card.Img
        variant="top"
        src={src}
        style={{
          borderRadius: "50%",
          margin: "5px auto",
          width: "80px",
          height: "80px",
        }}
      />
      <Card.Body style={{ textAlign: "center" }}>
        <Card.Title style={{ color: "#fff" }}>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export function CoachingStaffRow({ images }) {
  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Coaching Staff</h3>
        <Link to="#" style={{ color: "#fff", fontWeight: "lighter" }}>
          See All
        </Link>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {images.map((img, index) => (
          <StaffItem key={index} {...img} />
        ))}
      </div>
    </>
  );
}

function PlayersItem({ id, src, title }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/profile/id/${id}/athlete`);
  };

  return (
    <Card
      onClick={handleNavigate}
      style={{
        cursor: "pointer",
        width: "8rem",
        marginRight: "16px",
        backgroundColor: "unset",
        border: "unset",
      }}
    >
      <Card.Img
        variant="top"
        src={src}
        style={{
          borderRadius: "50%",
          margin: "5px auto",
          width: "80px",
          height: "80px",
        }}
      />
      <Card.Body style={{ textAlign: "center" }}>
        <Card.Title style={{ color: "#fff" }}>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

export function ScrollablePlayersRow({ images }) {
  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-2">
        <h3 style={{ fontWeight: "bold", color: "white" }}>Players</h3>
        <Link to="#" style={{ color: "#fff", fontWeight: "lighter" }}>
          See All
        </Link>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        {images.map((img, index) => (
          <PlayersItem key={index} {...img} />
        ))}
      </div>
    </>
  );
}

function AthleteByIdAthlete() {
  const navigate = useNavigate();
  const [menuDisplay, setMenuDisplay] = useState(false);

  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [profileImage, setProfileImage] = useState("");
  const [userType, setUserType] = useState("");
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [coachingStaff, setCoachingStaff] = useState([]);
  const [players, setPlayers] = useState([]);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [athleteData, setAthleteData] = useState("");

  const [currentTeam, setCurrentTeam] = useState("");
  const [feets, setFeets] = useState("");
  const [inches, setInches] = useState("");
  const [number, setNumber] = useState("");
  const [sport, setSport] = useState("");
  const [weight, setWeight] = useState("");
  const [country, setCountry] = useState("");

  const [links, setLinks] = useState([]);

  const handleOnOpen = () => {
    setMenuDisplay(true);
  };

  const { id: profileId } = useParams();

  const [athleteStats, setAthleteStats] = useState({
    height: "",
    weight: "",
    level: "",
    position: "",
    number: "",
    currentTeam: "",
    sport: "",
    country: "",
  });

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profileId}/athlete`
        );
        const data = await response.json();

        console.log("Fetch Athlete Data");
        console.log(data.data);

        setAthleteData(data.data);

        setCurrentTeam(data.data.currentTeam);
        setFeets(data.data.feets);
        setInches(data.data.inches);
        setNumber(data.data.number);
        setSport(data.data.sport);
        setWeight(data.data.weight);

        setAthleteStats((prevState) => ({
          ...prevState,
          height: `${data.data.feets}'${data.data.inches}"`, // Assuming height is provided in feet and inches
          weight: `${data.data.weight} pounds`,
          level: data.data.level,
          position: data.data.position,
          number: data.data.number,
          currentTeam: data.data.currentTeam,
          sport: data.data.sport,
          country: "", // Replace with actual key for country if different
        }));
      } catch (error) {
        console.log(error.message);
      }
    };

    if (profileId) {
      fetchTeamData();
    }
  }, [API_ENDPOINT, profileId]);

  /* */

  /*

      Preciso ajustar 

    */

  const [activeUserProfile, setActiveUserProfile] = useState(null);

  useEffect(() => {
    const storedProfile = localStorage.getItem("profile");
    console.log("Stored Profile is");
    console.log(storedProfile);
    if (storedProfile) {
      setActiveUserProfile(JSON.parse(storedProfile));
    }
  }, []);

  useEffect(() => {
    const fetchViewProfile = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profileId}/view`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ profileId: activeUserProfile._id }),
          }
        );

        const profileViewed = await response.json();

        if (profileViewed.success) {
          //
          //alert("You viewed his profile")
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (activeUserProfile) {
      fetchViewProfile();
    }
  }, [activeUserProfile]);

  /* */

  //

  function AthleteChart({ athleteData }) {
    console.log("Athlete Data is ");
    console.log(athleteData);

    // Check if athleteData object is empty or not
    if (!athleteData || Object.keys(athleteData).length === 0) {
      return <div>Data is loading...</div>;
    }

    const options = {
      chart: {
        id: "athlete-chart",
        background: "transparent",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#FFFFFF"],
        },
      },
      xaxis: {
        categories: ["PTS", "AST", "RPG", "STL", "BPG"],
        labels: {
          style: {
            colors: ["#FFFFFF"],
          },
        },
      },
      fill: {
        colors: ["#8B3976"],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    function getNumber(value) {
      const parsed = Number(value);
      return isNaN(parsed) ? 0 : parsed;
    }

    // Here we sanitize the series data by calling getNumber on everything
    const series = [
      {
        data: [
          getNumber(athleteData.pts),
          getNumber(athleteData.ast),
          getNumber(athleteData.rpg),
          getNumber(athleteData.stl),
          getNumber(athleteData.bpg),
        ],
      },
    ];

    return (
      <div className="mt-4">
        <div className="flex justify-between items-center mb-2">
          <h1
            style={{ fontWeight: "bold", color: "white" }}
            className="text-xl font-bold"
          >
            Statistics
          </h1>
        </div>
        <Row>
          <Col xs={12}>
            <Chart options={options} series={series} type="bar" width="100%" />
          </Col>
        </Row>
      </div>
    );
  }

  function ProfileChats({ profileData, profile }) {
    console.log(profileData);

    function getNumber(value) {
      const parsed = String(value);
      return isNaN(parsed) ? 0 : parsed;
    }

    function getReadablePosition(position) {
      switch (position) {
        case "center":
          return "Center";
        case "pointguard":
          return "Point Guard";
        case "shootinguard":
          return "Shooting Guard";
        case "smallforwad":
          return "Small Forward";
        case "powerforward":
          return "Power Forward";
        default:
          return "N/A";
      }
    }

    return (
      <>
        <div className="flex justify-between items-center mb-4">
          <h1
            style={{ fontWeight: "bold", color: "white" }}
            className="text-xl font-bold"
          >
            Statistics
          </h1>
        </div>

        <Row
          className="mb-4"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "5px",
          }}
        >
          <Col xs={4}>
            <div
              className="mb-2"
              style={{ fontSize: "0.9em", fontWeight: "300" }}
            >
              PTS
            </div>
            <b>{getNumber(profileData?.pts)}</b>
          </Col>
          <Col xs={4}>
            <div
              className="mb-2"
              style={{ fontSize: "0.9em", fontWeight: "300" }}
            >
              AST
            </div>
            <b>{getNumber(profileData?.ast)}</b>
          </Col>
          <Col xs={4}>
            <div
              className="mb-2"
              style={{ fontSize: "0.9em", fontWeight: "300" }}
            >
              RPG
            </div>
            <b>{getNumber(profileData?.rpg)}</b>
          </Col>
        </Row>

        <Row
          className="mb-4"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <Col xs={4}>
            <div
              className="mb-2"
              style={{ fontSize: "0.9em", fontWeight: "300" }}
            >
              STL
            </div>
            <b>{getNumber(profileData?.stl)}</b>
          </Col>
          <Col xs={4}>
            <div
              className="mb-2"
              style={{ fontSize: "0.9em", fontWeight: "300" }}
            >
              BPG
            </div>
            <b>{getNumber(profileData?.bpg)}</b>
          </Col>
        </Row>
      </>
    );
  }
  //

  // Fetch Profile
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profileId}`
        );
        const data = await response.json();

        setFollowers(data.data.followers.length);
        setFollowing(data.data.following.length);
        setProfileImage(data.data.profileImage);
        setUserType(data.data.type);
        setUsername(data.data.username);
        setTitle(data.data.title);
        setProfileLoaded(true);
        setLinks(data.data.links);

        const currentTime = new Date().getTime();
        const lastFetchTime = localStorage.getItem(
          `lastFetchTime_${profileId}`
        );

        // Check if the last fetch time is longer than 24 hours ago
        if (
          !lastFetchTime ||
          currentTime - lastFetchTime > 24 * 60 * 60 * 1000
        ) {
          const viewResponse = await fetch(
            `${API_ENDPOINT}/profiles/id/${profileId}/view`
          );

          // Check if the API call is successful
          if (viewResponse.ok) {
            localStorage.setItem(
              `lastFetchTime_${profileId}`,
              currentTime.toString()
            );
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (profileId) {
      fetchProfileData();
    }
  }, [API_ENDPOINT, profileId]);

  const handleChatWithProfile = () => {
    navigate(`/chat/id/${profileId}`);
  };

  return (
    <>
      <Header />

      <Container
        className="create-account-container"
        style={{ paddingBottom: "150px" }}
      >
        <div>
          <UserComponent
            userData={{
              image: profileImage,
              name: title,
              nickname: username,
              userType: userType,
            }}
          />
        </div>
        {profileId && (
          <Row className="mb-4">
            <Col xs={6} style={{ textAlign: "right" }}>
              <FollowUnfollowButton profileId={profileId} />
            </Col>
            <Col xs={6} style={{ textAlign: "center" }}>
              <Button onClick={handleChatWithProfile}>Chat</Button>
            </Col>
          </Row>
        )}
        {/* {profileLoaded && (

        <Row>
          <Col xs={12} style={{ 'textAlign' : 'right' }}>
            <FollowersFollowing followers={followers} following={following}/>
          </Col>
        </Row>

        )}      */}
        {profileLoaded && <ProfileStatistics />}
        {links && (
          <Row style={{ marginTop: "15px" }}>
            <Col xs={12} style={{ textAlign: "right" }}>
              <SocialMedia links={links} />
            </Col>
          </Row>
        )}
        {athleteData && <ProfileChats profileData={athleteData} />}
        {/* {athleteData && (
          <AthleteChart athleteData={athleteData} />
        )} */}
        {/* Players */}
        {coachingStaff && coachingStaff.length > 0 && (
          <CoachingStaffRow
            images={coachingStaff.map((staff) => ({
              src: staff.profile.profileImage,
              title: staff.profile.title,
            }))}
          />
        )}
        {/* Players */}
        {players && players.length > 0 && (
          <ScrollablePlayersRow
            images={players.map((staff) => ({
              src: staff.profile.profileImage,
              title: staff.profile.title,
              id: staff.profile._id,
            }))}
          />
        )}
        <div style={{ height: "150px" }}></div>
      </Container>

      <Footer />
    </>
  );
}

export default AthleteByIdAthlete;
