/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useContext, useEffect, useState } from 'react';

// Components

import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ApiContext } from '../../../../context/ApiContext';
import { BASE_URL } from '../../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

const fetchConversations = async (profileId) => {
  const response = await fetch(`${API_ENDPOINT}/profiles/id/${profileId}/notifications`);

  return await response.json();
};

/*



  START COMPONENTS



*/

const EntityList = ({ data }) => {

  console.log("Entity data inside is ")
  console.log(data)

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="entity-title">Who viewed your profile</div>
        </Col>
      </Row>
      {data.length === 0 ? (
        <div className="text-white mt-4" style={{ textAlign: 'center' }}>No one viewed your profile</div>
      ) : (
        data.map(item => (
          <Row key={item._id} style={{ 'marginBottom' : 15 }}>
            <Col xs={3}>
                <div 
                  className="circle-img"
                  style={{
                    backgroundImage: `url(${item.profile && item.profile.profileImage ? item.profile.profileImage : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'})`,
                    backgroundSize : 'cover'
                  }}
                />
            </Col>
            <Col xs={5}>
                {item.profile && item.profile.title ? 
                    <div className="text-white">{item.profile.title}</div> 
                    : <div className="text-white">Default title</div>
                }
                {item.profile && item.profile.type ? 
                    <div className="description">{item.profile.type}</div> 
                    : <div className="description">Default type</div>
                }
            </Col>
          </Row>
        ))
     )}
    </div>
  );
  
};

/*



  START PAGE



*/

export default function WhoViewedMyProfile() {

  const [conversations, setConversations] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setLoading } = useContext(ApiContext);

  /*
  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    console.log("Stored Profile is")
    console.log(storedProfile)
    if (storedProfile) {
      setProfile(JSON.parse(storedProfile));
    }
  }, []);
  */

  //

  useEffect(() => {
    const fetchProfileData = async () => {
      
      try {
        setLoading(true);

        let storedProfile = localStorage.getItem('profile');

        if (storedProfile) {
          storedProfile = JSON.parse(storedProfile);
        }
        
        const response = await fetch(`${API_ENDPOINT}/profiles/id/${storedProfile._id}`);
        const data = await response.json();

        console.log(data)

        setProfile(data.data);
        setIsLoading(false)
        

      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
    fetchProfileData();
  }, []);

  //

  return (
    <>
      <Header />
      <Container>

        { !isLoading && <EntityList data={profile.views} /> }

      </Container>
      <Footer />
    </>
  );

}