import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header/Header";
import { Container, Card, Row, Col, ListGroup, Modal } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import "./PaymentMethods.css";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL, MODE_DEV, STRIPE_PUBLIC_KEY } from "../../../Utils/utils";

const API_ENDPOINT = BASE_URL;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

stripePromise
  .then((stripe) => {
    console.log(stripe, "stripeInstance");
  })
  .catch((error) => {
    console.error("Failed to load Stripe:", error);
  });

const AddCardForm = ({
  onSuccessfulAdd,
  subscriptionPlan,
  loading,
  price,
  cardHolderName,
  setCardHolderName,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
    });

    if (error) {
      console.error("Error creating payment method:", error.message);
      alert(`Error: ${error.message}`);
    } else {
      console.log("Payment method created successfully:", paymentMethod);
      onSuccessfulAdd(paymentMethod.id, subscriptionPlan);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="cardHolderName">Card Holder Name</label>
        <br></br>
        <input
          style={{
            width: "100%",
            padding: "10px 20px",
            border: "1px solid gray",
            borderRadius: "10px",
            marginTop: "10px",
          }}
          id="cardHolderName"
          type="text"
          placeholder="Please enter Card Holder Name"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
          required
        />
      </div>

      <label style={{ marginTop: "10px" }} htmlFor="cardHolderName">
        Card Details
      </label>
      <div
        style={{
          border: "1px solid gray",
          padding: "15px 20px",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        <CardElement />
      </div>
      <div style={{ width: "100%" }}>
        <button
          style={{
            width: "100%",
            marginTop: "30px",
            background: loading ? "gray" : "",
          }}
          variant="success"
          size="lg"
          className="px-4 btn-signin"
          type="submit"
          disabled={loading || !stripe}
          sty
        >
          {loading
            ? "Loading...."
            : `Add Payment Method $${
                price == 99
                  ? "99.99"
                  : price == 59
                  ? "59.99"
                  : price == 29
                  ? "29.99"
                  : null
              } `}
        </button>
      </div>
    </form>
  );
};
const SubscriptionStartPage = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [planPriceIdes, setPlanPriceIds] = useState([]);
  const [profile, setProfile] = useState({});
  const [profileLoaded, setProfileLoaded] = useState(false);
  const { setLoading } = useContext(ApiContext);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [cardHolderName, setCardHolderName] = useState("");

  const getProductList = async () => {
    const response = await fetch(`${API_ENDPOINT}/profiles/get-product-list`);

    const jsonResponse = await response.json();
    console.log(jsonResponse?.data?.data, "jsonResponse?.data?.data");
    setPlanPriceIds(jsonResponse?.data?.data);
  };

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    async function fetchProfile() {
      const storedProfile = localStorage.getItem("profile");
      if (storedProfile) {
        const profile = JSON.parse(storedProfile);

        try {
          setLoading(true);

          const response = await fetch(
            `${API_ENDPOINT}/profiles/id/${profile._id}`
          );
          const data = await response.json();

          setProfile(data.data);
          setProfileLoaded(true);

          if (data.success && data.data.paymentMethods.length > 0) {
            setHasPaymentMethod(true);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    }

    fetchProfile();
  }, []);

  const handleAddPaymentMethod = async (paymentMethodId) => {
    console.log(
      subscriptionPlan,
      "subscriptionPlansubscriptionPlansubscriptionPlansubscriptionPlansubscriptionPlansubscriptionPlan"
    );
    setIsLoading(true);
    console.log(profile, "profile");
    if (profile) {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}/profiles/add-payment-method`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              profileId: profile._id,
              stripePaymentMethodId: paymentMethodId,
              subscriptionPlan: subscriptionPlan,
              price: price,
              cardHolderName: cardHolderName,
            }),
          }
        );

        const jsonResponse = await response.json();
        console.log(jsonResponse, "jsonResponse");
        setIsLoading(false);

        if (jsonResponse && jsonResponse.success) {
          setHasPaymentMethod(true);
          navigate("/subscriptions/overview");
        } else {
          alert(jsonResponse?.error);
        }
      } catch (error) {
        console.error("Error adding payment method:", error);
        alert("An unexpected error occurred");
        setIsLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    navigate("/subscriptions/overview");
    setShow(false);
  };

  const selectPlan = (value) => {
    console.log(value, "valuevaluevaluevaluevaluevaluevalue");
    setSubscriptionPlan(value);
    setShow(false);
  };

  useEffect(() => {
    if (profile?.paymentMethods?.length > 0) {
    } else {
      setShow(true);
    }
  }, [profile?.paymentMethods]);

  return (
    <>
      <Header />
      <Elements stripe={stripePromise}>
        <Container className="my-5">
          <Row>
            <Col>
              <Card
                className="mx-auto shadow-lg"
                style={{
                  backgroundColor: "white",
                  maxWidth: "600px",
                }}
              >
                <Card.Header>
                  <h3 className="text-center">Add Your Card</h3>
                </Card.Header>

                <Card.Body style={{ minHeight: "150px" }}>
                  <AddCardForm
                    onSuccessfulAdd={handleAddPaymentMethod}
                    subscriptionPlan={subscriptionPlan}
                    loading={loading}
                    setCardHolderName={setCardHolderName}
                    cardHolderName={cardHolderName}
                    price={price}
                  />
                </Card.Body>
                <Card.Footer></Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col>
              <h3 style={{ color: "white" }}>Your payment methods</h3>
              {profile &&
              profile.paymentMethods &&
              profile.paymentMethods.length > 0 ? (
                profile.paymentMethods.map((method, index) => (
                  <Card key={index} className="mb-3">
                    <Card.Body>
                      <Card.Title>
                        {method.brand} ending in {method.last4}
                      </Card.Title>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          Card Number: **** **** **** {method.last4}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Expiry Date: {method.expMonth}/{method.expYear}
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <p style={{ color: "white" }}>No payment methods added.</p>
              )}
            </Col>
          </Row>
        </Container>
      </Elements>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size='xl'
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pro Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="subscriptionCards">
            <div className="subscriptionCard">
              <h1>Premium Annual Plan</h1>
              <ul>
                <li>
                  Full access to the Scoutz job marketplace: Find and apply to
                  professional sports opportunities across multiple leagues and
                  teams.
                </li>
                <li>
                  Guaranteed access to Pro Day: Participate in Scoutz’s
                  exclusive pro day events to showcase your skills to scouts and
                  recruiters.
                </li>
                <li>See who has viewed your profile</li>
                <li>
                  Exclusive resources: Access to recruiting tools, tips, and
                  updates to increase visibility.
                </li>
              </ul>
              <a
                href="https://buy.stripe.com/aEU3e21uY6KE0TeeUZ"
                target="_blank"
              >
                <button> $119.99/year</button>
              </a>
            </div>

            <div className="subscriptionCard">
              <h1>Monthly Personal Support Plan</h1>
              <ul>
                <li>
                Dedicated 1-on-1 Scout & Agent: Receive personalized guidance from a Scoutz professional dedicated to helping you get recruited, with support in identifying the best opportunities, preparing your profile, and improving your chances of being noticed.

                </li>
                <li>
                Tailored advice: Get customized feedback and career advice to help you navigate the recruitment process.

                </li>
                <li> Direct communication: Work closely with your Scout/Agent for one month with regular check-ins.
                </li>

              </ul>
              <a
                href="https://buy.stripe.com/dR601Q2z23ys9pK7sw"
                target="_blank"
              >
                <button>$69.99/month </button>
              </a>
            </div>

            <div className="subscriptionCard">
              <h1>Basic Access Plan</h1>
              <ul>
                <li>
                Access to the Scoutz job marketplace: Search and apply for professional sports opportunities directly through the Scoutz platform.

                </li>
                <li>
                Affordable option: Perfect for emerging athletes looking to get their foot in the door without the additional personalized support.

                </li>
                <li>See who has viewed your profile</li>
                <li>
                  Exclusive resources: Access to recruiting tools, tips, and
                  updates to increase visibility.
                </li>
              </ul>
              <a
                href="https://buy.stripe.com/bIY3e28Xqb0UfO8fZ1"
                target="_blank"
              >
                <button> $19.99/month</button>
              </a>
            </div>

            {/* {profile?.paymentMethods?.[0]?.price == "99" ? (
              <div className="subscriptionCard">
                <h1>Pro Elite Membership</h1>
                <ul>
                  <li>Attend exclusive Pro Day events</li>
                  <li>Access premium job listings</li>
                  <li>See who has viewed your profile</li>
                  <li>Connect directly with top coaches</li>
                </ul>
                <button
                  onClick={() => {
                    setPrice(59);
                    selectPlan(
                      MODE_DEV
                        ? planPriceIdes[1]?.default_price
                        : planPriceIdes[11]?.default_price
                    );
                  }}
                >
                  {" "}
                  $59.99/month
                </button>
              </div>
            ) : (
              <div className="subscriptionCard">
                <h1>Pro Elite Membership</h1>
                <ul>
                  <li>Attend exclusive Pro Day events</li>
                  <li>Access premium job listings</li>
                  <li>See who has viewed your profile</li>
                  <li>Connect directly with top coaches</li>
                </ul>
                <button
                  onClick={() => {
                    setPrice(99);
                    selectPlan(
                      MODE_DEV
                        ? planPriceIdes[2]?.default_price
                        : planPriceIdes[12]?.default_price
                    );
                  }}
                >
                  {" "}
                  $99.99/month
                </button>
              </div>
            )}
            <div className="subscriptionCard">
              <h1>Premium Membership</h1>
              <ul>
                <li>Access premium job listings</li>
                <li>See who has viewed your profile</li>
                <li>Connect directly with top coaches</li>
              </ul>
              <button
                onClick={() => {
                  setPrice(29);
                  selectPlan(
                    MODE_DEV
                      ? planPriceIdes[0]?.default_price
                      : planPriceIdes?.[10]?.default_price
                  );
                }}
              >
                {" "}
                $29.99/month
              </button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionStartPage;
