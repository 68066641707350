import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./Header.css";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTv,
  faNewspaper,
  faSearch,
  faBars,
  faMagnifyingGlass,
  faBookBookmark,
  faArrowRight,
  faChevronRight,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu";
import CompanyLogo from "../../../assets/logo.png";
import SearchDiv from "./SearchDiv";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";
import {
  CoachIcon,
  FilterIcon,
  Menplayer,
  Womenplayer,
  messageIcon,
  searchIcon,
} from "../../../assets";

//import ModalComponent from './ModalComponent';

//const API_ENDPOINT = "BASE_URL"
const API_ENDPOINT = BASE_URL;
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [searchDisplay, setSearchDisplay] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [isRecruiting, setIsRecruiting] = useState(false);

  const { t, i18n } = useTranslation();
  const tokenItems = [];

  const handleRecruitingToggle = () => {
    const profileData = localStorage.getItem("profile");

    if (profileData) {
      const parsedProfile = JSON.parse(profileData);
      if (
        parsedProfile.people &&
        parsedProfile.people[0].firstname &&
        parsedProfile.username
      ) {
        if (["agent", "coach", "team"].includes(parsedProfile.type)) {
          const url = `${API_ENDPOINT}/profiles/id/${parsedProfile._id}/${parsedProfile.type}/recruiting`;
          const options = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ recruiting: !parsedProfile.recruiting }),
          };
          fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                setIsRecruiting(data.data.recruiting);
              } else {
                throw new Error(
                  data.message || "Error toggling recruiting status"
                );
              }
            })
            .catch((error) => {
              console.error("Error toggling recruiting status:", error);
            });
        }
      }
    }
  };

  const handleOnOpen = () => {
    setMenuDisplay(true);
  };

  const handleSearchOnOpen = () => {
    console.log("Display search");
    setSearchDisplay(true);
  };

  const handleBack = () => {};

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));

    if (profile && (profile.type === "athlete" || profile.type === "team")) {
      const items = [
        { route: `/profile/${profile.type}`, display: t("menu.myprofile") },
        {
          route: `/profile/${profile.type}/edit`,
          display: t("Edit profile"),
        },
        // {
        //   route: "/settings/paymentmethods",
        //   display: t("menu.paymentmethods"),
        // },
        { route: "/subscriptions/overview", display: t("menu.subscription") },
        //{ route: "/profile/verify", display: t('menu.verifyme')},
        { route: "/chat/overview", display: t("menu.conversations") },
        { route: "/proday", display: "My Scoutz Pro Day" },
        { route: "/rating/overview", display: "Scoutz Rating" },
        { route: "/jobs/my", display: t("menu.myjobs") },
        { route: "/contactus", display: t("menu.contactus") },
        { route: "/settings", display: t("menu.settings") },
      ];

      setMenuItems(items);
    }

    if (profile && profile.type === "team") {
      const items = [
        { route: `/profile/${profile.type}`, display: t("menu.myprofile") },
        {
          route: "/settings/paymentmethods",
          display: t("menu.paymentmethods"),
        },
        //{ route: "/subscriptions/overview", display: t('menu.subscription') },
        //{ route: "/profile/verify", display: t('menu.verifyme')},
        { route: "/chat/overview", display: t("menu.conversations") },
        { route: "/proday", display: "My Scoutz Pro Day" },
        //{ route: "/rating/overview", display: "Scoutz Rating" },
        { route: "/contactus", display: t("menu.contactus") },
        { route: "/settings", display: t("menu.settings") },
      ];

      setMenuItems(items);
    }

    if (profile && profile.type === "coach") {
      const items = [
        { route: `/profile/${profile.type}`, display: t("menu.myprofile") },
        // {
        //   route: "/settings/paymentmethods",
        //   display: t("menu.paymentmethods"),
        // },
        //{ route: "/subscriptions/overview", display: t('menu.subscription') },
        //{ route: "/profile/verify", display: t('menu.verifyme')},
        { route: "/coach-jobs", display: t('menu.coachJOb')},
        { route: "/chat/overview", display: t("menu.conversations") },
        { route: "/proday", display: "My Scoutz Pro Day" },
        //{ route: "/rating/overview", display: "Scoutz Rating" },
        { route: "/contactus", display: t("menu.contactus") },
        { route: "/settings", display: t("menu.settings") },
      ];

      setMenuItems(items);
    }

    if (profile && profile.type === "agent") {
      const items = [
        { route: `/profile/${profile.type}`, display: t("menu.myprofile") },
        {
          route: "/settings/paymentmethods",
          display: t("menu.paymentmethods"),
        },
        //{ route: "/subscriptions/overview", display: t('menu.subscription') },
        //{ route: "/profile/verify", display: t('menu.verifyme')},
        { route: "/chat/overview", display: t("menu.conversations") },
        { route: "/proday", display: "My Scoutz Pro Day" },
        //{ route: "/rating/overview", display: "Scoutz Rating" },
        { route: "/contactus", display: t("menu.contactus") },
        { route: "/settings", display: t("menu.settings") },
      ];

      setMenuItems(items);
    }

    // re-run effect when t changes
  }, [t]);

  function UserComponent({ userData }) {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      img: {
        borderRadius: "50%",
        marginTop: "15px",
        height: "100px",
        width: "100px",
        margin: 0,
      },
      name: {
        color: "#fff",
        fontWeight: "bold",
        marginTop: "5px",
        marginBottom: "0px",
      },
      nickname: {
        fontWeight: "lighter",
        opacity: 0.6,
        marginBottom: "25px",
      },
    };
    const placeholderImage = userProfile?.profileImage
      ? userProfile?.profileImage
      : userProfile?.type === "coach"
      ? CoachIcon
      : userProfile?.people[0]?.gender?.toLowerCase() === "male"
      ? Menplayer
      : Womenplayer;

    return (
      <div style={styles.container}>
        <img
          style={styles.img}
          src={userData.image || placeholderImage}
          alt="User Profile"
        />
        <div style={styles.name}>{userData.name}</div>
        <div style={styles.nickname}>@{userData.nickname}</div>
      </div>
    );
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    let profileType = localStorage.getItem("profileType");
    let profile = localStorage.getItem("profile");

    console.log(token);
    console.log(profileType);
    console.log(JSON.parse(profile));
  }, []);

  const [userProfile, setUserProfile] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  const ToggleSwitch = ({ isOn, handleToggle }) => {
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={isOn} onChange={handleToggle} />
        <span className="switch" />
      </label>
    );
  };

  // Precisa ajustar aqui. Fazer API call versus buscar do storage profile

  // 1 - Se o profile.type for 'agent' or 'coach' or 'team', then it should load each model on api, to check if isRecruiting is on or off
  // 2 -

  useEffect(() => {
    const profileData = localStorage.getItem("profile");

    console.log("Profile Data second ");
    console.log(profileData);

    if (profileData) {
      const parsedProfile = JSON.parse(profileData);
      console.log("Parsed data is ");
      console.log(parsedProfile, "profile info check");
      setUserProfile(parsedProfile);

      if (
        parsedProfile.people &&
        parsedProfile.people[0].firstname &&
        parsedProfile.username
      ) {
        if (["agent", "coach", "team"].includes(parsedProfile.type)) {
          const url = `${API_ENDPOINT}/profiles/id/${parsedProfile._id}/${parsedProfile.type}`;
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                setIsRecruiting(data.data.recruiting === true);
              }
            })
            .catch((error) => {
              console.error("Error fetching recruiting status:", error);
            });
        }
        setIsLoaded(true);
      }
    }
  }, []);
  const { setLoading } = useContext(ApiContext);

  const token = localStorage.getItem("token");
  const handleLogOut = () => {
    setMenuDisplay(false);
    localStorage.removeItem("profile");
    localStorage.removeItem("profileType");
    localStorage.removeItem("token");
    window.location.pathname = "/"
  };

  return (
    <>
      {/* Menu Left */}

      <Menu onOpen={menuDisplay}>
        <div>
          {token ? (
            <UserComponent
              userData={{
                image: userProfile?.profileImage,
                name: userProfile?.people[0]?.firstname,
                nickname: userProfile?.username,
              }}
              userProfile={userProfile}
            />
          ) : (
            <div className="d-flex justify-content-center w-full py-4 ">
              <button
                className="header-btn btn"
                onClick={() => navigate("/accounts/login")}
              >
                Log in
              </button>
            </div>
          )}
        </div>

        {isLoaded &&
          (userProfile.type === "coach" || userProfile.type === "agent") && (
            <div className="menu-item">
              <div className="menu-link">
                Is recruiting
                <ToggleSwitch
                  isOn={isRecruiting}
                  handleToggle={handleRecruitingToggle}
                  style={{ position: "absolute", right: 15 }}
                />
              </div>
            </div>
          )}

        {token
          ? menuItems.map((item) => (
              <Link
                key={item.route}
                to={item.route}
                style={{ color: "white", textDecoration: "none" }}
                className="menu-link"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="menu-item"
                >
                  {item.display}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="arrowIcon"
                  />
                </div>
              </Link>
            ))
          : tokenItems.map((item) => (
              <Link
                key={item.route}
                to={item.route}
                style={{ color: "white" }}
                className="menu-link"
              >
                <div className="menu-item">
                  {item.display}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ position: "absolute", right: 15 }}
                    className="arrowIcon"
                  />
                </div>
              </Link>
            ))}
        {token !== null && (
          <div className="d-flex justify-content-center w-full py-4 ">
            <button className="header-btn btn" onClick={handleLogOut}>
              Log out
            </button>
          </div>
        )}
      </Menu>

      <SearchDiv
        searchDisplay={searchDisplay}
        setSearchDisplay={setSearchDisplay}
      />

      <Container style={{ height: "unset", marginBottom: "0px" }}>
        <Row>
          <Col xs={12} md={6} lg={4} className="mx-auto">
            <div
              style={{
                position: "absolute",
                left: "15px",
                top: "30px",
                color: "white",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={handleOnOpen}
            ></div>
            <div className="header-auth">
              {!token && (
                <div className="header-auth-btn">
                  {/* <button
                  className="btn btn-dark fs-5"
                  onClick={() => navigate("/contactus")}
                >
                  Contact us
                </button> */}
                  <button
                    className="header-btn btn"
                    onClick={() => navigate("/accounts/create")}
                  >
                    Sign up
                  </button>
                  <button
                    className="header-btn btn"
                    onClick={() => navigate("/accounts/login")}
                  >
                    Log in
                  </button>
                </div>
              )}
            </div>

            <div
              style={{
                position: "absolute",
                right: "70px",
                top: "25px",
                color: "white",
                cursor: "pointer",
                fontSize: "1.2em",
                backgroundColor: "#1F1F1F",
                width: "36px",
                height: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100px",
              }}
              onClick={() => handleSearchOnOpen()}
            >
              <img
                width="16px"
                height="16px"
                style={{ margin: "0px" }}
                src={searchIcon}
                alt="$$$"
              />
              {/* <FontAwesomeIcon icon={messageIcon} /> */}
            </div>
            <div
              style={{
                position: "absolute",
                right: "20px",
                top: "25px",
                color: "white",
                cursor: "pointer",
                fontSize: "1.2em",
                backgroundColor: "#1F1F1F",
                width: "36px",
                height: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100px",
              }}
              onClick={() => navigate("/notifications/overview")}
            >
              <img
                width="16px"
                height="16px"
                style={{ margin: "0px" }}
                src={
                  location.pathname === "/home" || location.pathname === "/"
                    ? messageIcon
                    : FilterIcon
                }
                alt="$$$"
              />
              {/* <FontAwesomeIcon icon={messageIcon} /> */}
            </div>
            
            <img
              src={CompanyLogo}
              width={50}
              alt="Logo"
              className="mt-4 my-4 d-block mx-auto"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Header;
