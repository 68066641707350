import React, { useState, useEffect, useRef, useContext } from 'react';

// Components

// Packages

import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// API

import Profile from '../../../api/Profile';

// CSS

import './Overview.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import Placeholder from '../../../assets/userplaceholder.jpg';
import { ApiContext } from '../../../context/ApiContext';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
const API_ENDPOINT_FILES = BASE_URL
//const API_ENDPOINT = "BASE_URL"
//const API_ENDPOINT_FILES = "http://localhost:8998"

export default function VerifyDocuments() {

    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const { t, i18n } = useTranslation();
    const [profileImage, setUserImage] = useState('');
    const [documentType, setType] = useState(''); // include this in your state
    const [fileAddress, setImageFile] = useState('');
    const { setLoading } = useContext(ApiContext);

    const handleNext = () => {
        // Here, you'd typically handle form validation and other checks 
        // before proceeding to the next step.
        if (step < 5) setStep(step + 1);
    };

    const handleBack = () => {
        if (step == 1) {
            navigate('/home')
        }
        if (step > 1) setStep(step - 1);
    };

    //

    const DocumentUploadComponent = ({ userData, setImageFile }) => {

        const [isLoading, setIsLoading] = useState(false);
        const [selectedImage, setSelectedImage] = useState(Placeholder);
        const [uploadedImageUrl, setUploadedImageUrl] = useState(userData.image);
        const fileInputRef = useRef();
    
        const handleImageSelect = (event) => {
            const file = event.target.files[0];
            if (file) {
                setSelectedImage(URL.createObjectURL(file));
            }
        }
    
        const handleUpload = async (event) => {

            event.preventDefault(); // Prevent the default form submit action.
            const file = fileInputRef.current.files[0];
            if (!file) {
                console.log('No image selected!');
                return;
            }
    
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', file);
    
            try {
                setLoading(true);
                const response = await fetch(API_ENDPOINT_FILES + '/files/uploadFile/common', {
                    method: 'POST',
                    body: formData
                });
    
                const responseData = await response.json();
    
                if (responseData.success) {
                    setUploadedImageUrl(responseData.url);
                    setImageFile(responseData.url)
                    alert("Upload with success");
                } else {
                    throw new Error(responseData.message || 'Upload failed');
                }

            } catch (error) {
                console.error('Error:', error);
                alert("Upload failed!");
            } finally {
                setIsLoading(false);
                setLoading(false);
                setSelectedImage(null); // Clear the preview
            }

        };
    
        return (
            <div className="profile-image-wrapper">
            {isLoading ? (
                <div className="loader" style={{'color' : '#fff'}}>Loading...</div>
            ) : (
                <>
                
                <div style={{ display: 'inline-block', position: 'relative' }}>

                    <label className="profile-image-wrapper-pen" htmlFor="fileUpload" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faPen} /></label>
                    
                    <div className="profile-image-wrapper-pic mb-12" style={{ backgroundImage: `url(${uploadedImageUrl || selectedImage})` }}>
                
                        <input
                        type="file"
                        id="fileUpload"
                        style={{ display: 'none' }}
                        onChange={handleImageSelect}
                        ref={fileInputRef}
                        /> 
                    </div>
                
                </div>
                
                <div className="mt-4">
                    <button className="btn-signin mt-3 w-100 btn btn-primary" onClick={handleUpload}>Upload Image</button>
                </div>

                </>
            )}

            </div>
        );
    };

    const handleConfirm = async () => {
        const profile = new Profile();
        const data = await profile.verifyDocuments(fileAddress, documentType);
        
        if (data.success) {
            alert('Your document was sent with success');
            navigate('/home/');
        } else {
            alert('Error submitting the document');
        }
    };

    /*

    const handleConfirm = async () => {

        const storedProfile = localStorage.getItem('profile');

        const profile = JSON.parse(storedProfile);

        const profileId = profile._id

        const response = await fetch(`${API_ENDPOINT}/profiles/id/${profileId}/documents/verify`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ fileAddress, documentType })
        });
        const data = await response.json();

        if (data.success) {
          alert('Your document was sent with success');
          navigate('/home/');
        } else {
          alert('Error submitting the document');
        }

    };

    */

    //

    const IDSelectionModule = () => {
        const [selected, setSelected] = useState("");
        const [agreeFaceRecord, setAgreeFaceRecord] = useState(false);
        const [agreePrivacy, setAgreePrivacy] = useState(false);
    
        const idOptions = [t('verifyDocs.nationalidcard'), t('verifyDocs.passport'), t('verifyDocs.residentpermit'), t('verifyDocs.drivinglicense')];
    
        const handleNext = () => {
            // Proceed to next operation
            console.log("Continue button clicked");
            if (step < 5) setStep(step + 1);
        };
    
        return (
            <Form>
                {idOptions.map((id) => (
                
                    <Row className="mb-4" key={id}>

                            <Col className="py-4" >
                                <Form.Check 
                                    custom 
                                    type="radio" 
                                    id={id} 
                                    label={id}
                                    checked={documentType === id}
                                    onChange={() => setType(id)}
                                />
                            </Col>
                        
                    </Row>
                    
                ))}
    
                <Row className="mb-4">
                    <Col>
                        <Form.Check 
                            custom 
                            type="checkbox" 
                            id="acceptCamera"
                            label="I agree allowing face to be recorded to verify identity remotely" 
                            checked={agreeFaceRecord}
                            onChange={() => setAgreeFaceRecord(!agreeFaceRecord)}
                        />
                    </Col>
                </Row>
    
                <Row  className="mb-4">
                    <Col>
                        <Form.Check 
                            custom 
                            type="checkbox" 
                            id="acceptPolicy"
                            label="I have agreed with privacy policy and general conditions" 
                            checked={agreePrivacy}
                            onChange={() => setAgreePrivacy(!agreePrivacy)}
                        />
                    </Col>
                </Row>
                    
                <Row className="mt-4">
                    <Col>
                        <Button className="btn-signin mt-3 w-100 btn btn-primary" onClick={handleNext} disabled={!documentType||!agreeFaceRecord||!agreePrivacy}>Continue</Button>
                    </Col>
                </Row>

            </Form>
        );
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>

                        <div className="mb-8">
                            <h1 className="mb-8">{t('verifyDocs.title1')}</h1>
                            <p>{t('verifyDocs.title2')}</p>
                        </div>

                        <div>

                            <IDSelectionModule />

                        </div>

                    </>                    
                );
                case 2:
                    return (
                        <>
                            <div className="mb-8">

                                <h1 className="mb-8">Upload your document</h1>
                                <p>Select your document</p>
                                
                            </div>

                            <div className="mb-4 mt-4">

                                <div className="col-12">
                                    <DocumentUploadComponent userData={{ image: fileAddress, name: "", nickname: "" }} setImageFile={setImageFile} />
                                </div>

                            </div>

                            <div className="mt-4">

                                {fileAddress.length > 0 && (

                                    <Button variant="primary" onClick={handleNext}>
                                    Confirm
                                    </Button>

                                )}

                                

                            </div>
                            
                        </>
                      );
                case 3:
                    return (
                        <>
                            <h1>Confirm your verification information</h1>
                            <p>Type of document: {documentType}</p>
                            <img src={fileAddress} alt="Uploaded Document" />
                            <button onClick={handleConfirm}>
                                Send for verification
                            </button>
                        </>
                    );
            case 4:
                // Implement your API integration logic here
                return null;
            case 5:
                return null;
            default:
                return null;
        }
    };

    return (
        <Container className="create-account-container" style={{ "color" : "#fff" }}>

            <Row style={{ 'height' : '50px', 'marginBottom' : '40px' }}>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'left', 'lineHeight' : '50px'}}>
                    <div style={{
                        color: "white",
                        display: "inline-block",
                        cursor: "pointer",
                        height : '50px',
                        lineHeight : '50px'
                    }}
                        onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                </Col>

                <Col xs={6} md={6} lg={6} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>
                    <div style={{ 'color' : '#fff', 'textAlign' : 'center', 'height' : '50px', 'lineHeight' : '50px' }}><b>Verify Me</b></div>
                </Col>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>                   
                    
                    <div style={{ lineHeight : '50px' }}>

                        <img src={CompanyLogo} style={{ paddingTop : '13px'}} width={30} alt="Logo" />

                    </div>
                                    
                </Col>

                <Col xs={12} md={12} lg={12}>
                    <ProgressBar className="mb-4 custom-progress-bar" style={{ height : '3px' }} now={(step / 2) * 100} />
                </Col>

            </Row>

            <Row style={{ "marginTop" : "35px" }}>

                <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center" }}>

                    {renderStepContent()}

                </Col>

            </Row>            

        </Container>
    );

}