import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, ListGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";
import { CoachIcon, Menplayer } from "../../../assets";

const API_ENDPOINT = BASE_URL;

const SearchDiv = ({ searchDisplay, setSearchDisplay }) => {
  const [input, setInput] = useState("");
  const [results, setResults] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const { setLoading } = useContext(ApiContext);

  const navigate = useNavigate();

  const handleInputChange = async (e) => {
    setInput(e.target.value);
    if (e.target.value.length > 0) {
      setIsSearched(true);
      try {
        setLoading(true);
        let response = await fetch(
          `${API_ENDPOINT}/search/profile?q=${e.target.value}`
        );
        let data = await response.json();
        console.log("Data is");
        console.log(data.data);
        if (data.data.length > 0) {
          console.log("inside data.data.length");
          setResults(data.data);
          setIsSearched(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log(results);
  }, [results]);

  const EntityList = ({ data, profileId }) => {
    const [follow, setFollow] = useState(false);

    const handleProfileAccess = () => {
      // Precisa adaptar por tipo de perfil

      if (data.type == "athlete") {
        
        navigate(`/athletes/id/${profileId}`);
        // navigate(`/coming-soon`);
      }

      if (data.type == "team") {
        navigate(`/teams/id/${profileId}`);
        // navigate(`/coming-soon`);

      }
    };

    console.log("EntityList");
    console.log(data);

    const placeholderImage = data?.type === "coach" ? CoachIcon : Menplayer
      // "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";

    return (
      <div>
        <Row
          onClick={handleProfileAccess}
          key={data.id}
          style={{ marginBottom: 15 }}
        >
          <Col xs={3}>
            <img
            src={data.profileImage || placeholderImage}
              className="circle-img"
              // style={{
              //   backgroundImage: `url(${
              //     data.profileImage || placeholderImage
              //   })`,
              // }}
              alt="$$$"
            />
          </Col>
          <Col xs={5}>
            <div className="">
              <b>{data.title}</b>
            </div>
            <div className="" style={{ fontSize: "0.9em" }}>
              @{data.username}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (!searchDisplay) {
    return null;
  }


  console.log(results,"results")

  return (
    <div
      style={{
        background: "#131313",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        zIndex: 1000,
      }}
    >
      <Container>
        <Row>
          <Col xs={10}>
            <div className="input-group" style={{ marginTop: "2em" }}>
              <Form.Control
                type="text"
                value={input}
                onChange={handleInputChange}
                placeholder="Search..."
                className="mr-sm-2"
                style={{ border: "1px solid lightgrey", height: "40px" }}
              />
              <div style={{ height: "40px" }} className="input-group-append">
                <span
                  style={{
                    height: "40px",
                    height: "40px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className="input-group-text"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <Button
                variant="outline-light"
                onClick={() => setSearchDisplay(false)}
                style={{
                  // position: "absolute",
                  // top: "2.0em",
                  // right: "1em",
                  height: "40px",
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          {!results.length && isSearched ? (
            <p>No Results Found</p>
          ) : (
            <ListGroup
              style={{
                backgroundColor: "#1a1a1a",
                border: "none",
                overscrollBehavior: "smooth",
                overflowY: "scroll",
                height: "67vh",
              }}
              // onScroll={true}
            >
              {results.map((result, index) => (
                <ListGroup.Item
                  className="text-white"
                  style={{ backgroundColor: "#1a1a1a", border: "none" }}
                  key={index}
                >
                  {result._source && (
                    <>
                      <EntityList
                        profileId={result._id}
                        data={result._source}
                      />
                    </>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default SearchDiv;
