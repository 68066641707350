/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

/*

  To-Do

  - Defensive coding

*/

import React from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages
import { Container, Row, Col, Button, Tab, Tabs  } from "react-bootstrap";
import QRCode from 'qrcode.react';
import styled from 'styled-components';

/*



  START PAGE



*/

export default function QRCodePage() {

  const profile = JSON.parse(localStorage.getItem('profile'));

  const QRWrapper = styled.div`
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 35px;
  `;

  const QRCodeGenerator = ({ id }) => {
    return <QRCode value={id} size={300} />;
  };
  
  return (
    <>
      <Header />

        <Container>

            <Row className="mb-4 text-center">

                <Col xs={12}>

                    <h1 className="text-white mt-4">QR Code</h1>

                </Col>

            </Row>

            <Row className="mt-4 text-center">

                <Col xs={12} className="pt-4">

                  <QRCodeGenerator id={profile._id} size={250} imageSettings={{ 'width' : 300 }} />

                </Col>

            </Row>

            

            {/*
            <QRWrapper>
              
                <QRCode
                value={profile._id}
                size={Math.min(350, window.innerWidth)}
                />
            </QRWrapper>
  */}

        </Container>

      
      <Footer />
    </>
  );
}
