import React from 'react';
import { Navigate } from 'react-router-dom';

const withAuth = (Component) => {
    // Returning a functional component
    return (props) => {
        const token = localStorage.getItem('token');

        if (!token) {
            // Directly returning JSX for redirection
            return <Navigate to="/accounts/login" replace />;
        }
        
        // Directly returning JSX of the passed Component
        return <Component {...props} />;
    }
}

export default withAuth;