/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import { useEffect, useState, useRef, useReducer } from "react";

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Package

import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL
const API_ENDPOINT_FILES = BASE_URL
//const API_ENDPOINT = 'BASE_URL';
//const API_ENDPOINT_FILES = 'BASE_URL';

const VideoUploadComponent = ({ setVideoLink }) => {

  const [fileLoaded, setFileLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const fileInputRef = useRef();

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedVideo(URL.createObjectURL(file));
    }
  }

  const handleUpload = async (event) => {

    event.preventDefault();

    const file = fileInputRef.current.files[0];
    if (!file) {
      console.log('No video selected!');
      return;
    }
    
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(API_ENDPOINT_FILES + '/files/upload/video', {
        method: 'POST',
        body: formData
      });

      const responseData = await response.json();

      console.log(responseData)

      if (responseData.success) {
        alert("Video uploaded with success")
        setFileLoaded(true);

        setUploadedImageUrl(responseData.url);
        setVideoLink(responseData.url);
        setIsLoading(false);

      } else {
        throw new Error(responseData.message || 'Upload failed');
      }

    } catch (error) {
      console.error('Error:', error);
      alert('Upload failed!');
    } 

  };

  return (
    <>
      <div className="profile-image-wrapper">

        {isLoading && (
          <div className="loader" style={{'color' : '#fff'}}>Loading...</div>
        )}
        
        {uploadedImageUrl.length > 0 && (
          <div style={{ display: 'inline-block', position: 'relative' }}>
            
            
            {fileLoaded && (
              <>
                <p>Video Uploaded with success</p>
                <video width="320" height="240" controls>
                  <source src={uploadedImageUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </>
            )}

          </div>
        )}

        {!fileLoaded && (
          <>
            <input
            type="file"
            id="fileUpload"
            style={{ display: 'none' }}
            onChange={handleImageSelect}
            ref={fileInputRef}
            />

            <label className="text-white" htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
            Click Here to Select Video
            </label>
          </>
        )}

      </div>
      <div className="mt-4 mb-4">
        <button className="btn-signin mt-3 w-100 btn btn-primary" onClick={handleUpload}>Upload Video</button>
      </div>
    </>
  );

};

// END VideoUploadComponent

function VideosCreate() {

    const navigate = useNavigate(); 

    const [loading, setLoading] = useState(false);
    const [videoLink, setVideoLink] = useState("");
    const [title, setTitle] = useState("");
    const [videoFile, setVideoFile] = useState('');
    const [showOptions, setShowOptions] = useState(true);
    const [type, setType] = useState("");
    const [highlight, setHighlight] = useState('');

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const t = query.get('t');

        if (['attack', 'defense', 'fulltape'].includes(t)) {
            setHighlight(t);
        }
    }, [location]);

    const initialState = {
      isLoading: false,
      fileLoaded: false,
      uploadedImageUrl: '',
    };
    
    function reducer(state, action) {
      switch (action.type) {
        case 'UPLOAD_START': return { isLoading: true, fileLoaded: false, uploadedImageUrl: '' };
        case 'UPLOAD_SUCCESS': return { isLoading: false, fileLoaded: true, uploadedImageUrl: action.payload };
        default: throw new Error();
      }
    }

    const onFormSubmit = async (event) => {

        event.preventDefault();
        let formData = new URLSearchParams();
        formData.append('videoType', highlight);
        formData.append('videoLink', videoLink);

        let profile = JSON.parse(localStorage.getItem('profile'))

        const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/scoutzlink`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        });

        const data = await response.json();

        if(data.success) {
            navigate(`/user/${profile.username}`);
            
        } else {
            alert('Error updating');
        }
        
    }

    const handleOptionChange = (event) => {
        setType(event.target.value);
        setShowOptions(false);
    } 

    const handleBack = () => {
        setShowOptions(true);
        setType('');
    }

    // render method
    return (
        <div>
          <Header />
          <Container>
            {showOptions ? (
              <>
                <Form>
                  <Row className="mt-2">
                    <Col className="mb-4" xs={12}>
                      <h2 className="text-white">Select an option</h2>
                    </Col>
    
                    <Col xs={6}>
                      <Image src="https://cdn3d.iconscout.com/3d/free/thumb/free-youtube-5562364-4642766.png?f=webp" width="80" height="80" />
                      <div className="mt-4 text-center">
                        <h3 className="text-white">Upload video from YouTube</h3>
                      </div>
                      <div className="mt-4 text-center">
                        <Form.Check type={"radio"} id={`youtube`} value="youtube" onChange={handleOptionChange} />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <Image src="https://cdn3d.iconscout.com/3d/premium/thumb/upload-5621286-4673830.png?f=webp" width="80" height="80" />
                      <div className="mt-4 text-center">
                        <h3 className="text-white">Upload video</h3>
                      </div>
                      <div className="mt-4 text-center">
                        <Form.Check type={"radio"} id={`upload`} value="upload" onChange={handleOptionChange} />
                      </div>
                    </Col>

                  </Row>
                </Form>
              </>
            ) : (
              <>
                <Form onSubmit={onFormSubmit}>
                  {type === "youtube" && (
                    <>
                      <div>
                        <Button className="mb-4" variant="secondary" onClick={handleBack}>
                          Back
                        </Button>
                        <h1 className="text-white">Upload video from YouTube</h1>
                        <Form.Group controlId="title">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <Form.Group controlId="title">
                            <Form.Label>YouTube Link</Form.Label>
                            <Form.Control
                              type="text"
                              value={videoLink}
                              onChange={(e) => setVideoLink(e.target.value)}
                              placeholder="Enter youtube video link"
                            />
                          </Form.Group>
                          <Form.Group controlId="select-highlight">
                            <Form.Label>Select highlight</Form.Label>
                            <Form.Control
                              as="select"
                              value={highlight}
                              onChange={(e) => setHighlight(e.target.value)}
                            >
                              <option value="">Select your option</option>
                              <option value="attack">Attack highlights</option>
                              <option value="defense">Defense highlights</option>
                              <option value="fulltape">Full Game video tape</option>
                              <option value="highlights">Highlights</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Group>
                      </div>
                      <Row>
                        <Col xs={6}></Col>
                        <Col xs={6}>
                          <button className="btn-signin" disabled={loading}>
                            {loading ? "Uploading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </>
                  )}

                  {type === "upload" && (
                    <>
                      <div>

                        <Button className="mb-4" variant="secondary" onClick={handleBack}>
                          Back
                        </Button>
                        <h1 className="text-white">Upload video</h1>

                        <Form.Group controlId="title">

                          <Form.Label>Title</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />

                          </Form.Group>

                          <VideoUploadComponent setVideoLink={setVideoLink}/>

                          <Form.Group controlId="select-highlight">
                            <Form.Label>Select highlight</Form.Label>
                            <Form.Control
                              as="select"
                              value={highlight}
                              onChange={(e) => setHighlight(e.target.value)}
                            >
                              <option value="">Select your option</option>
                              <option value="attack">Attack highlights</option>
                              <option value="defense">Defense highlights</option>
                              <option value="fulltape">Full Game video tape</option>
                              <option value="fulltape">Highlights</option>
                            </Form.Control>
                          </Form.Group>

                        

                      </div>

                      <Row>
                        <Col xs={6}></Col>
                        <Col xs={6}>
                          <button className="btn-signin" disabled={loading}>
                            {loading ? "Uploading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>

                    </>
                  )}
                </Form>
              </>
            )}
          </Container>
        </div>
    );

  }

export default VideosCreate;
