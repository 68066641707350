import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import translationEN from './en/translation.json';
import translationES from './es/translation.json';
import translationFR from './fr/translation.json';
import translationPT from './pt/translation.json';

// The translations.
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector) // Detects user language
  .init({
    resources,
    fallbackLng: 'en', // Use 'en' if detected lng is unavailable
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'path', 'htmlTag', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;