/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { json, Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";

// CSS

import "./Overview.css";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL, MODE_DEV } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

function SubscriptionOverview() {
  const navigate = useNavigate();
  const [menuDisplay, setMenuDisplay] = useState(false);
  const { setLoading: setGlobalLoading } = useContext(ApiContext);

  const [subscriptions, setSubscriptions] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("notactive");
  // const [storedProfile, setAllSubscriptions] = useState(null);
  const [storedProfile, setStoredProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const fetchData = (url) => {
    return fetch(url)
      .then((response) => response.json())
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleOnOpen = () => {
    setMenuDisplay(true);
  };

  const handleAddPaymentMethod = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}/profiles/cancel-subscription/${storedProfile._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionId:
              storedProfile?.paymentMethods?.[
                storedProfile?.paymentMethods?.length - 1
              ]?.id,
          }),
        }
      );

      const jsonResponse = await response.json();
      console.log(jsonResponse, "jsonResponse");
      if (jsonResponse?.data?._id) {
        localStorage.setItem("profile", JSON.stringify(jsonResponse?.data));
        setStoredProfile(jsonResponse?.data);
      }
      alert("An unexpected error occurred");

    } catch (error) {
      console.error("Error adding payment method:", error);
      alert("An unexpected error occurred");
      localStorage.setItem("profile", JSON.stringify(storedProfile));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchProfile() {
      if (storedProfile) {
        const profile = storedProfile;

        try {
          setGlobalLoading(true);

          const response = await fetch(
            `${API_ENDPOINT}/profiles/id/${profile._id}`
          );
          const data = await response.json();
          // if (data.success && data.data.paymentMethods.length == 0) {
          //   navigate('/settings/paymentmethods')
          // }
        } catch (e) {
          console.error(e);
        } finally {
          setGlobalLoading(false);
        }
      }
    }

    fetchProfile();
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  // const statusText = isActive ? "Active" : "Level Up Your Game";

  const profile = JSON?.parse(localStorage?.getItem("profile"));
  console.log(profile, "profile");
  const profileId = profile?._id;
  const getAllSubscription = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}/profiles/id/${profileId}/subscriptions`
      );
      const data = await response.json();
      setStoredProfile(data.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getAllSubscription();
    }
  }, [profileId]);

  const handleStartSubscription = () => {
    setShow(true);
    // navigate("/settings/paymentmethods");
    // // Implement start subscription logic here
    // console.log("Starting subscription...");
  };

  const latestPaymentMethod =
    storedProfile?.paymentMethods?.[storedProfile?.paymentMethods?.length - 1];

  const isSubscriptionExpired =
    latestPaymentMethod?.subscription?.status === "incomplete_expired" ||
    latestPaymentMethod?.subscription?.status === "canceled";

  const isSubscriptionDue =
    isSubscriptionExpired &&
    new Date().toISOString() >
      new Date(
        latestPaymentMethod?.subscription?.current_period_end * 1000
      ).toISOString();

  const showStartSubscriptionButton =
    storedProfile?.paymentMethods?.length <= 0 || isSubscriptionDue;
  //  &&
  // statusText === "Level Up Your Game";

  useEffect(() => {
    let profileId = profile._id;

    const fetchSubscriptions = fetchData(
      `${API_ENDPOINT}/profiles/id/${profileId}/subscriptions`
    );
    const fetchInvoices = fetchData(
      `${API_ENDPOINT}/profiles/id/${profileId}/invoices`
    );

    /*

        JWT Token

      */

    Promise.all([fetchSubscriptions, fetchInvoices]).then(
      ([subscriptionResp, invoiceResp]) => {
        if (subscriptionResp.success) {
          setSubscriptions(subscriptionResp.data);
          if (subscriptionResp.data?.paymentMethods?.length > 0) {
            localStorage.setItem(
              "profile",
              JSON.stringify(subscriptionResp.data)
            );
          }
        }
        if (invoiceResp.success) {
          setInvoices(Array.isArray(invoiceResp.data) ? invoiceResp.data : []); // Check if data is array
        }
        setLoading(false); // Indicate that loading has finished
      }
    );
  }, []);

  console.log(storedProfile, "storedProfile");

  function HeaderBox({ subscriptions, isActive }) {
    const nextPaymentDate = new Date();

    console.log(subscriptions);

    const formattedDate = `${
      nextPaymentDate.getMonth() + 1
    }/${nextPaymentDate.getDate()}/${nextPaymentDate.getFullYear()}`;

    const startSubscription = () => {
      navigate("/settings/paymentmethods");
    };

    return (
      <>
        <div className="headerBox">
          <div className="statusBox">
            <div
              className="text-align text-white"
              style={{ position: "absolute", right: 15, top: 15 }}
            >
              <Link
                className="text-align text-white"
                href="/subscriptions/more"
              >
                More
              </Link>
            </div>
            <p>Level Up Your Game</p>
            <h1>Pro Subscription</h1>

            {showStartSubscriptionButton && (
              <div style={{ textAlign: "right" }}>
                <button
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "none",
                    padding: "5px 10px",
                  }}
                  onClick={() => handleStartSubscription()}
                >
                  Start Subscription
                </button>
              </div>
            )}
          </div>

          {isActive && <p>Next Payment: {subscriptions[0]?.endDate}</p>}
        </div>
      </>
    );
  }

  function cleanAmount(amount) {
    // Remove any characters that are not digits or a decimal point
    const cleanedAmount = amount.replace(/[^\d.]/g, "");
    return cleanedAmount ? (Number(cleanedAmount) / 100).toFixed(2) : "0.00";
  }

  function HistoryRow({
    title,
    startDate,
    endDate,
    amount,
    cardHolderName,
    subscription,
  }) {
    // const displayAmount = cleanAmount(amount);

    return (
      <div
        style={{
          border: "1px solid white",
          borderRadius: "10px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <div className="historyRow">
          <div>
            <div className="title">{title}</div>
            <div className="title">{cardHolderName}</div>

            {/* Assuming startDate and endDate should be displayed as well */}
            <div className="dates">
              From: {startDate} To: {endDate}
            </div>
          </div>
          <p>${amount}</p>
        </div>
        {subscription?.status === "incomplete_expired" ||
        subscription?.status === "canceled" ? (
          <button
            disabled
            style={{
              marginTop: "10px",
              background: "gray",
              height: "auto",
              minHeight: "50px",
            }}
            className="btn-signin"
          >
            your subscription plan end successfully but you can use the
            subscribed features until your subscription time period end
          </button>
        ) : (
          <button
            style={{ marginTop: "10px" }}
            className="px-4 btn-signin"
            onClick={() => handleAddPaymentMethod()}
          >
            Cancel Subscription
          </button>
        )}
      </div>
    );
  }

  const formatUnixTimestamps = (currentPeriodStart, periodType = 'month') => {
    // Convert Unix timestamp to JavaScript Date object for the start date
    const startDate = new Date(currentPeriodStart * 1000);
  
    // Create the end date by adding a month or year to the start date
    const endDate = new Date(startDate);
    if (periodType === 'year') {
      endDate.setFullYear(startDate.getFullYear() + 1);
    } else {
      endDate.setMonth(startDate.getMonth() + 1);
    }
  
    // Format the dates to a readable string
    const formattedStartDate = startDate.toLocaleDateString();
    const formattedEndDate = endDate.toLocaleDateString();
  
    // Return the formatted dates
    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };
  

  // Function rendering HistoryRow or a message when there are no invoices.
  const renderHistoryRows = () => {
    if (loading) {
      return <p>Loading...</p>; // Show loading state
    }
    if (storedProfile?.paymentMethods?.length > 0) {
      console.log(storedProfile, "storedProfile");
      return storedProfile?.paymentMethods.map((invoice) => (
        <HistoryRow
          title={`${
            invoice.plan.product == "prod_QTVdUcUebS2qw9" ||
            invoice?.plan?.product === "prod_R4cDiSmZL66LEi"
              ? "Pro Day + Job Marketplace Annual plan"
              : invoice.plan.product == "prod_R4cDB9ZIL1huzy"
              ? "Make it Pro & Sign with a Agent"
              : invoice.plan.product == "prod_R4cE6739t8KEb8"
              ? "Basic Access Plan"
              : null
          }`}
          cardHolderName={invoice?.cardHolderName}
          startDate={formatUnixTimestamps(invoice?.start_date)?.startDate}
          endDate={formatUnixTimestamps(invoice.start_date,invoice?.plan?.product === "prod_R4cDiSmZL66LEi" ? "year" : null )?.endDate}
          subscription={invoice}
          amount={`${
            invoice.plan.product == "prod_QTVdUcUebS2qw9" ||
            invoice?.plan?.product === "prod_R4cDiSmZL66LEi"
              ? "119.00/year"
              : invoice.plan.product == "prod_R4cDB9ZIL1huzy"
              ? "69.99/month"
              : invoice.plan.product == "prod_R4cE6739t8KEb8"
              ? "19.99/month"
              : null
          }`}
          key={invoice._id}
        />
      ));
    }
    return <p style={{ color: "#fff" }}>No payments made</p>;
  };

  return (
    <>
      <Header />
      <Container className="create-account-container">
        <HeaderBox
          subscriptions={subscriptions}
          isActive={subscriptionStatus === "active"}
        />
        <h2 style={{ color: "#fff" }}>Payment History</h2>
        <div style={{ marginBottom: "100px" }}>
          {!loading && renderHistoryRows()}
        </div>
      </Container>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pro Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="subscriptionCards">
            <div className="subscriptionCard">
              <h1>Pro Day + Job Marketplace Annual plan</h1>
              <ul>
                <li>
                  Full access to the Scoutz job marketplace: Find and apply to
                  professional sports opportunities across multiple leagues and
                  teams.
                </li>
                <li>
                  Guaranteed access to Pro Day: Participate in Scoutz’s
                  exclusive pro day events to showcase your skills to scouts and
                  recruiters.
                </li>
                <li>See who has viewed your profile</li>
                <li>
                  Exclusive resources: Access to recruiting tools, tips, and
                  updates to increase visibility.
                </li>
              </ul>
              <a
                href={
                  MODE_DEV
                    ? "https://buy.stripe.com/test_9AQ1652eU8LJbJK6oo"
                    : "https://buy.stripe.com/aEU3e21uY6KE0TeeUZ"
                }
                target="_blank"
              >
                <button> $119.99/year</button>
              </a>
            </div>

            <div className="subscriptionCard">
              <h1>Make it Pro & Sign with a Agent</h1>
              <ul>
                <li>
                  Dedicated 1-on-1 Scout & Agent: Receive personalized guidance
                  from a Scoutz professional dedicated to helping you get
                  recruited, with support in identifying the best opportunities,
                  preparing your profile, and improving your chances of being
                  noticed.
                </li>
                <li>
                  Tailored advice: Get customized feedback and career advice to
                  help you navigate the recruitment process.
                </li>
                <li>
                  {" "}
                  Direct communication: Work closely with your Scout/Agent for
                  one month with regular check-ins.
                </li>
              </ul>
              <a
                href="https://buy.stripe.com/dR601Q2z23ys9pK7sw"
                target="_blank"
              >
                <button>$69.99/month </button>
              </a>
            </div>

            <div className="subscriptionCard">
              <h1>Basic Access Plan</h1>
              <ul>
                <li>
                  Access to the Scoutz job marketplace: Search and apply for
                  professional sports opportunities directly through the Scoutz
                  platform.
                </li>
                <li>
                  Affordable option: Perfect for emerging athletes looking to
                  get their foot in the door without the additional personalized
                  support.
                </li>
                <li>See who has viewed your profile</li>
                <li>
                  Exclusive resources: Access to recruiting tools, tips, and
                  updates to increase visibility.
                </li>
              </ul>
              <a
                href="https://buy.stripe.com/bIY3e28Xqb0UfO8fZ1"
                target="_blank"
              >
                <button> $19.99/month</button>
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SubscriptionOverview;
