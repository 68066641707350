/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar, Modal, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Overview.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { ApiContext } from '../../../context/ApiContext';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START PAGE



*/

export default function CreateAccount() {

    const [step, setStep] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const { setLoading: setGlobalLoading } = useContext(ApiContext);

    // Modal handler functions
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // First Step
    const [username, setUsername] = useState('');

    const handleStartForm = () => {
        navigate('/rating/form');
    }

    function PurchaseModal({ showModal, handleClose }) {
        const [loading, setLoading] = useState(false);
        const [purchaseStatus, setPurchaseStatus] = useState(null);
      
        const handlePurchase = async () => {
          setPurchaseStatus(null);
          setLoading(true);
      
          let profile = localStorage.getItem('profile');
          profile = JSON.parse(profile);
      
          try {
            setGlobalLoading(true);
            const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/tape`, {
              method: 'POST',
              // Add any needed headers here
            });
      
            if (response.ok) {
              setPurchaseStatus('success');
            } else {
              setPurchaseStatus('error');
            }
          } catch (error) {
            setPurchaseStatus('error');
          } finally {
            setGlobalLoading(true);
          }
      
          setLoading(false);
        };
      
        return (
            <Modal show={showModal} onHide={handleClose} centered>
              <Modal.Body style={{ backgroundColor: '#000', color: 'white', borderRadius : '5px', minHeight : '200px' }}>
                <Row className="justify-content-center my-3">
                  <Col xs={12} className="text-center">
                    <div><img src="https://cdn3d.iconscout.com/3d/premium/thumb/video-production-6907883-5640258.png?f=webp" width="150px" /></div>
                    <h3>Highlight Tape - $40</h3>
                    <p>The tape will be accessible on your profile in 3-5 days.</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Highlight Tape</td>
                          <td>$40.00</td>
                        </tr>
                        {/* Optional: Add more rows if there are more items or fees */}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12} className="text-center">
                    {!purchaseStatus && !loading && (
                      <Button
                        onClick={handlePurchase}
                        variant="primary"
                        style={{ border: 'none', width: '100%', background: 'linear-gradient(to right, #9F3F86, #1BA9CE)' }}
                      >
                        Confirm Purchase
                      </Button>
                    )}
                    {loading && <p>Loading...</p>}
                    {purchaseStatus === 'success' && <p>Purchase was successful!</p>}
                    {purchaseStatus === 'error' && <p>Something went wrong. Please contact Antonio.</p>}
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
        <Header />
            <Container className="create-account-container">

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center", "marginBottom" : "45px" }}>
                
                <img src="https://cdn3d.iconscout.com/3d/premium/thumb/video-camera-7970699-6323928.png" height="255px" />
                <h1 style={{ "color" : "#fff" }}>Unleash Your Best on 'Highlight Tape'</h1>
                <h3 style={{ "color" : "#fff", "fontWeight" : '300' }}>Sign up for an active subscription and enhance your profile with a $40 add-on for exclusive highlights from Scoutz Pro!</h3>

                <div style={{ "marginTop" : "15px" }}>

                    <Button onClick={handleShow} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Get Started</Button>
                
                </div>

            </Col>

            </Row>

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                
                <h1 style={{ "color" : "#fff" }}>Your 'Highlight Tape' Journey</h1>
                <h2 style={{ "color" : "#fff" }}>What we do?</h2>
                <p style={{ "color" : "#fff" }}>You play, we do the rest. We’ll edit and upload your best moments in this section, and automatically add them to your digital profile- Scoutz Link, the hotspot for your games!</p>

            </Col>

            </Row>

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                
                <h2 style={{ "color" : "#fff" }}>Boost Your Chances</h2>
                <p style={{ "color" : "#fff" }}>By having your best moments catalogued in Scoutz Link, your digital profile and resume takes a gigantic leap. Tightly edited, highly accessible select highlights increase visibility and placement in your Instagram bio, giving you the edge you deserve.</p>
                <p style={{ "color" : "#fff" }}>This way, you make a vital impression on coaches, agents, and teams scouting for talent. With the structure they crave, you increase your chances of being recruited. Go ahead, spotlight your brilliance.</p>

            </Col>

            </Row>

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                        <div  style={{ "height" : "150px" }}></div>
                    </Col>

                </Row>

                {/* Modal */}
                <PurchaseModal
                showModal={showModal}
                handleClose={() => setShowModal(false)}
              />

            </Container>
        <Footer />
        </>
    );
}