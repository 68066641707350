import React from "react";
import "./blueButton.css";

const BlueButton = ({ children, onClick, type, disabled, active, style }) => {
  return (
    <button
      style={style}
      className={`${active ? "blue-button blue-button-active" : "blue-button"}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default BlueButton;
