/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React from 'react';

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';

/*



  START COMPONENT



*/

const BottomBar = ({ setFilterDisplay }) => {

  const navigate = useNavigate();


  const handleLogout = () => {
    navigate('/')
    localStorage.clear();
    // Add any additional logout logic here, if needed
  };

  const handleDeleteAccount = () => {
    // Add the logic to delete the account here. Usually, it involves making an API call to the backend
    console.log('Account delete functionality not implemented.');
  };

  return (
    <div style={{
      background: '#131313',
      width: '100%',
      height: '350px', // Assuming a fixed height for the bar
      position: 'fixed',
      bottom: 0,
      zIndex: 1000,
      justifyContent: 'space-around', // This will space out the buttons evenly
      alignItems: 'center',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '5px',
      left : 0
    }}>

      <div>

        <Row>

            <Col xs={12} className="pt-2" style={{ 'textAlign' : 'center' }}>
        
                <h3 className="text-white"><b>Filter</b></h3>

            </Col>

        </Row>

        <Row>

            <Col xs={6} className="pt-2" style={{ 'textAlign' : 'center' }}>
        
                <div class="gradient-border"><div class="inner-div">Football</div></div>

            </Col>

            <Col xs={6} className="pt-2" style={{ 'textAlign' : 'center' }}>
        
                <div class="gradient-border"><div class="inner-div">Basketball</div></div>

            </Col>

        </Row>

      </div>

      <div style={{ position: 'absolute', bottom : 15, left : 15, right : 15 }}>
        <Row>
            <Col xs={6}>
                <button onClick={() => { setFilterDisplay(false) } } style={{ 'background' : 'none' }} className="btn-signin">Close</button>
            </Col>
            <Col xs={6}>
                <button className="btn-signin">Filter</button>
            </Col>
        </Row>
      </div>

    </div>
  );
  
};

export default BottomBar;