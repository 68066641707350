/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef, useContext } from 'react';

// Packages

import imageCompression from 'browser-image-compression';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { ApiContext } from '../../../../context/ApiContext';
import { BASE_URL, BASE_URL_ADMIN } from '../../../../Utils/utils';
import axios from 'axios';

// Global Variables

const API_ENDPOINT_FILES = BASE_URL

const MAX_SIZE_MB = 1;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

const UserComponent = ({ userData, setUserImage }) => {

    const { setLoading } = useContext(ApiContext);

    const placeholderImage = "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";
  
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");
    const fileInputRef = useRef();

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    }

    const handleUpload = async (event) => {
        event.preventDefault(); // Prevent the default form submit action.
        const file = fileInputRef.current.files[0];
        if (!file) {
            console.log('No image selected!');
            return;
        }
    
        setIsLoading(true);
        const formData = new FormData();
        formData.append('image', file);
    
        try {
            setLoading(true);
            // Using axios for the file upload
            const response = await axios.post(BASE_URL_ADMIN + 'api/s3-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            const responseData = response.data;
    
            if (responseData.code === 200) {
                console.log(responseData, "responseData");
                setUploadedImageUrl(responseData.objectUrl);
                setUserImage(responseData.objectUrl);
                alert("Upload successful");
            } else {
                throw new Error(responseData.message || 'Upload failed');
            }
    
        } catch (error) {
            console.error('Error:', error);
            alert("Upload failed!");
        } finally {
            setIsLoading(false);
            setLoading(false);
            setSelectedImage(null); // Clear the preview
        }
    };


    console.log(uploadedImageUrl,"uploadedImageUrl")
    return (
        <div className="profile-image-wrapper">
        {isLoading ? (
            <div className="loader" style={{'color' : '#fff'}}>Loading...</div>
        ) : (
            <>
            
            <div style={{ display: 'inline-block', position: 'relative' }}>

                <label className="profile-image-wrapper-pen" htmlFor="fileUpload" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faPen} /></label>
                
                <div className="profile-image-wrapper-pic" >
                    <img src={selectedImage ? selectedImage : uploadedImageUrl || placeholderImage} alt='$$$' />
                    <input
                    type="file"
                    id="fileUpload"
                    style={{ display: 'none' }}
                    onChange={handleImageSelect}
                    ref={fileInputRef}
                    /> 
                </div>
                <br></br>

                {selectedImage != placeholderImage && (
                    <button className="mb-4 px-4 py-2 text-white" style={{ 'background' : 'linear-gradient(to right, #9F3F86, #1BA9CE)', 'borderRadius' : '5px' }}  onClick={handleUpload}>Upload Image</button>
                )}
                
            </div>
            
            </>
        )}
        </div>
    );
};

export default UserComponent