/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________ 
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useContext } from "react";

// Components

import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";

// Packages

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAirFreshener,
  faBookBookmark,
  faGlobe,
  faEye,
  faSearch,
  faArrowRight,
  faArrowLeft,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
} from "react-bootstrap";
import Chart from "react-apexcharts";

// Media

import Twitter from "../../../../assets/icons/socialmedia/twitter.png";
import Instagram from "../../../../assets/icons/socialmedia/instagram.png";
import TikTok from "../../../../assets/icons/socialmedia/tiktok.png";
import Web from "../../../../assets/icons/socialmedia/web.png";
import YouTube from "../../../../assets/icons/socialmedia/youtube.png";
import { ApiContext } from "../../../../context/ApiContext";
import { BASE_URL, BASE_URL_ADMIN } from "../../../../Utils/utils";
import "./coach.css";
import {
  BackArrow,
  CoachIcon,
  DefaultJob,
  FilterIcon,
  saveJobIcon,
  searchIcon,
} from "../../../../assets";
import NewHeader from "../../../components/newHeader";
import CoachFooter from "../../../components/CoachFooter/CoachFooter";
import moment from "moment/moment";
import axios from "axios";
// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

/*

  Display followers and who is following component

*/

const FollowersFollowing = ({ followers, following }) => {
  return (
    <Row style={{ textAlign: "center", color: "#fff", marginTop: "10px" }}>
      <Col xs={6} className="border-right">
        <h2 className="font-weight-bold">{followers}</h2>
        <p className="text-white-50">Followers</p>
      </Col>
      <Col xs={6}>
        <h2 className="font-weight-bold">{following}</h2>
        <p className="text-white-50">Following</p>
      </Col>
    </Row>
  );
};

/*

  Social Media links component

*/

const SocialMedia = ({ profileData }) => {
  return (
    <Col xs={12} className="text-center mb-1">
      {profileData.links.map(({ type, link, _id }) => {
        let imgSrc;
        switch (type) {
          case "twitter":
            imgSrc = Twitter;
            break;
          case "instagram":
            imgSrc = Instagram;
            break;
          case "tiktok":
            imgSrc = TikTok;
            break;
          case "website":
            imgSrc = Web;
            break;
          case "youtube":
            imgSrc = YouTube;
            break;
          default:
            imgSrc = Web; // default image in case the type doesn't match
        }
        return (
          <div
            key={_id}
            className="mr-3"
            style={{ display: "inline-block", paddingRight: "15px" }}
          >
            <Link to={`https://${link}`} target="_blank" rel="noreferrer">
              <img src={imgSrc} width="30" />
            </Link>
          </div>
        );
      })}
    </Col>
  );
};

/*

  Who Viewed My Profile component

*/

const ProfileViews = () => {
  const [viewsCount, setViewsCount] = useState(0);
  const [searchCount, setSearchCount] = useState(0);

  const navigate = useNavigate();

  const handleProfileRedirect = () => {
    // navigate to another page
    navigate("/profile/whoviewed");
  };

  return (
    <div className="mb-4 px-2">
      <div
        style={{
          backgroundColor: "#3A3A3A",
          borderRadius: "15px",
          marginBottom: "15px",
        }}
      >
        <Row
          className="px-4"
          style={{ paddingLeft: "15px", paddingRight: "15px", color: "#fff" }}
        >
          <Col
            xs={12}
            onClick={handleProfileRedirect}
            style={{
              position: "relative",
              height: "50px",
              lineHeight: "50px",
              borderBottom: "1px solid #fff",
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faEye} /> {viewsCount}{" "}
            Profile Views
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ position: "absolute", right: 15, top: 15 }}
            />
          </Col>
        </Row>

        <Row
          className="px-4"
          style={{ paddingLeft: "15px", paddingRight: "15px", color: "#fff" }}
        >
          <Col
            xs={12}
            onClick={handleProfileRedirect}
            style={{ position: "relative", height: "50px", lineHeight: "50px" }}
          >
            <FontAwesomeIcon className="mr-2" icon={faSearch} /> {searchCount}{" "}
            Search Views
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ position: "absolute", right: 15, top: 15 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

/*

  Show Profile Header component

*/

const UserComponent = ({ userData }) => {
  const placeholderImage = CoachIcon;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    userData.image || placeholderImage
  );

  console.log("User image is ");
  console.log(userData.image);

  return (
    <div className="profile-image-wrapper coachContainer">
      <NewHeader name={"Coach profile"} path="/home" />
      {isLoading ? (
        <div className="loader" style={{ color: "#fff" }}>
          Loading...
        </div>
      ) : (
        <div
          style={{
            display: "inline-block",
            position: "relative",
          }}
        >
          <Link to="/profile/coach/edit">
            <label
              className="profile-image-wrapper-pen"
              style={{ cursor: "pointer", bottom: 0 }}
            >
              <FontAwesomeIcon icon={faPen} />
            </label>
          </Link>
          <img
            alt="$$$"
            src={selectedImage}
            className="profile-image-wrapper-pic"
          />
        </div>
      )}
      <div
        style={{ fontSize: "1.2em", fontWeight: "bold" }}
        className="name text-white mt-3"
      >
        {userData.name}
      </div>
      <div className="nickname text-white mt-1">@{userData.nickname}</div>
    </div>
  );
};

//

/*



  START PAGE



*/

export default function MyProfileCoach() {
  const navigate = useNavigate();
  const [menuDisplay, setMenuDisplay] = useState(false);

  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [profileImage, setProfileImage] = useState("");

  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [profileData, setProfileData] = useState("");
  const [athleteData, setAthleteData] = useState("");

  const handleOnOpen = () => {
    setMenuDisplay(true);
  };

  const [sport, setSport] = useState("");
  const [feets, setFeets] = useState("");
  const [inches, setInches] = useState("");
  const [weight, setWeight] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [position, setPosition] = useState("");
  const { setLoading } = useContext(ApiContext);
  const [allJObs, setAllJObs] = useState([]);

  //

  let profile = localStorage.getItem("profile");
  profile = JSON.parse(profile);

  useEffect(() => {
    setProfileData(profile);
  }, []);

  const fetchJobsData = async () => {
    try {
      const response = await axios.get(`${BASE_URL_ADMIN}jobs`);
      const data = response.data?.data?.docs?.filter(
        (item) => item?.website == profile?._id
      );
      setAllJObs(data);
      console.log(data, "setAllJobs");
    } catch (error) {
      console.error("Error fetching jobs data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchJobsData();
  }, []);



  useEffect(() => {
    setProfileData(profile);
  }, []);



  return (
    <>
      <Container
        className="create-account-container profileCoach"
        style={{
          paddingBottom: "150px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {profileData && (
          <div>
            <UserComponent
              userData={{
                image: profileData?.profileImage,
                name: profileData?.title,
                nickname: profileData?.username,
              }}
            />
          </div>
        )}

        <Row style={{ marginTop: "20px" }}>
          <hr />
          <Col xs={12} className="heading">
            Previous experiences
          </Col>
          {profileData &&
            profileData?.experience?.map((item, index) => {
              return (
                <Col style={{ marginTop: "20px" }} key={index} xs={12}>
                  <div className="experiences">
                    <img src={CoachIcon} alt="$$$" />
                    <div>
                      <h2>{item?.team}</h2>

                      <p>
                        {moment(item?.startDate).format("MMM YYYY")} -{" "}
                        {item?.currentlyWorking
                          ? "Present"
                          : moment(item?.endDate).format("MMM YYYY")}
                      </p>
                    </div>
                  </div>
                  <hr />
                </Col>
              );
            })}
        </Row>

        <Row >
          <Col xs={12} className="heading">
          Recently posted jobs
          </Col>
          <Col xs={12} style={{ display: "flex", gap: "20px", overflowX: "auto" }}>
            {allJObs.map((item, index) => {
              return (
                <div className="coachJobSingleContainer" key={index}>
                  <div className="coachJobsLeft">
                    <div className="coachImage">
                      <img
                        alt="$$$"
                        src={item?.image || DefaultJob}
                        style={{ margin: 0 }}
                      />
                    </div>
                    <div>
                      <div className="nickname text-white">{item?.title}</div>
                      <div
                        className="coachJobNameTitle"
                        style={{ color: "rgba(181, 181, 181, 1)" }}
                      >
                        title . {moment(new Date()).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="coachJobBellIcon">
                    <img src={saveJobIcon} style={{ margin: 0 }} />
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
  {/*  */}
        <div style={{ height: "100px" }}></div>
      </Container>

      <CoachFooter />
    </>
  );
}
