import React from "react";
import { BackArrow, FilterIcon, LogoutIcon, searchIcon } from "../../assets";
import { useNavigate } from "react-router-dom";

const NewHeader = ({ name, path }) => {
  const naviagte = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        width: "100%",
        position: "relative",
      }}
    >
      <div>
        <div
          style={{
            color: "white",
            cursor: "pointer",
            fontSize: "1.2em",
            backgroundColor: "rgba(31, 31, 31, 0.5)",
            width: "36px",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100px",
          }}
          onClick={() => naviagte(-1)}
        >
          <img
            width="16px"
            height="16px"
            style={{ margin: "0px" }}
            src={BackArrow}
            alt="$$$"
          />
          {/* <FontAwesomeIcon icon={messageIcon} /> */}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h2 style={{ color: "rgba(255, 255, 255, 1)" }}>{name}</h2>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "1.2em",
              backgroundColor: "rgba(31, 31, 31, 0.5)",
              width: "36px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100px",
            }}
          >
            <img
              width="16px"
              height="16px"
              style={{ margin: "0px" }}
              src={searchIcon}
              alt="$$$"
            />
            {/* <FontAwesomeIcon icon={messageIcon} /> */}
          </div>
          <div
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "1.2em",
              backgroundColor: "rgba(31, 31, 31, 0.5)",
              width: "36px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100px",
            }}
          >
            <img
              width="16px"
              height="16px"
              style={{ margin: "0px" }}
              src={FilterIcon}
              alt="$$$"
            />
            {/* <FontAwesomeIcon icon={messageIcon} /> */}
          </div>
          {window.location?.pathname?.includes("/profile/coach") && (
            <div
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.2em",
                backgroundColor: "rgba(31, 31, 31, 0.5)",
                width: "36px",
                height: "36px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100px",
              }}
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("profileType");
                localStorage.removeItem("profile");
                naviagte("/")
              }}
            >
              <img
                width="16px"
                height="16px"
                style={{ margin: "0px" }}
                src={LogoutIcon}
                alt="$$$"
              />
              {/* <FontAwesomeIcon icon={messageIcon} /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
