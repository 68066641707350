import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import withAuth from "../withAuth";
import ProfileCoachEdit from "../pages/Profiles/Edit/Coach/Coach";
import CoacHJobs from "../pages/Coach";
import PostJob from "../pages/Coach/postJob";
import MyProfileCoach from "../pages/Profiles/MyProfile/Coach/Coach";
import EditJob from "../pages/Coach/editJob";
import Appliedplayers from "../pages/Coach/appliedplayers";
import AthleteById from "../pages/Athlete/PlayerById/PlayerById";


const CoachRoutes = () => {
  const AuthedProfileCoachEdit = withAuth(ProfileCoachEdit);
  const AuthedProfileCoach = withAuth(MyProfileCoach);

  const AuthedProfileCoachJobs = withAuth(CoacHJobs);
  const AuthedProfileCoachPostJob = withAuth(PostJob);
  const AuthedProfileEditCoachJob = withAuth(EditJob);
  const AuthedProfileAppliedCoachJob = withAuth(Appliedplayers);
const AuthedProfileAthleteById = withAuth(AthleteById);


  return (
    <Routes>
      <Route path={"*"} element={<Navigate to="/" replace />} />
      <Route path="/" element={<AuthedProfileCoachJobs />} />
      <Route path="/profile/coach" element={<AuthedProfileCoach />} />
      <Route path="/coach-post-job" element={<AuthedProfileCoachPostJob />} />
      <Route path="/edit-coach-job" element={<AuthedProfileEditCoachJob />} />
      <Route path="/profile/coach/edit" element={<AuthedProfileCoachEdit />} />
      <Route path="/job-applied-playes" element={<AuthedProfileAppliedCoachJob />} />

      <Route
            path="/athletes/id/:id"
            element={<AuthedProfileAthleteById />}
          />
    </Routes>
  );
};

export default CoachRoutes;
