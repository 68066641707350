import React, { useState } from 'react';
import './Forgot.css'
import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';

const API_ENDPOINT = BASE_URL

//const API_ENDPOINT = "BASE_URL"

export default function ForgotYourPassword() {
    const [step, setStep] = useState(1);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [feets, setFeets] = useState('');

    const [email, setEmail] = useState('');

    const [userType, setUserType] = useState('');

    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const navigate = useNavigate();
    
    const resetPassword = async () => {
        const response = await fetch(`${API_ENDPOINT}/profiles/password/reset`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({email})
        });
        const data = await response.json();
        if (data.success) {
          setStep(2);
        } else {
          alert('Error resetting password');
        }
      };
    
      const submitNewPassword = async () => {
        const response = await fetch(`${API_ENDPOINT}/profiles/password/reset/`, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({code, newPassword, email })
        });
        const data = await response.json();
        if (data.success) {
          navigate('/');
        } else {
          alert('Error updating password');
        }
      };

    const handleNext = () => {
        // Here, you'd typically handle form validation and other checks 
        // before proceeding to the next step.
        if (step < 5) setStep(step + 1);
    };

    const handleBack = () => {
        if (step == 1) {
            navigate('/');
        }
        if (step > 1) setStep(step - 1);
    };

    const handleSignup = async () => {
        try {
            const profileData = {
                email,
                password, 
                phone: phoneNumber
                //... add any other necessary fields
            };

            const response = await fetch(`${API_ENDPOINT}/profiles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(profileData)
            });

            const data = await response.json();

            if (data.message && data.message.includes('successfully')) {
                alert('Account created! You can now login.');
                //navigate('/accounts/onboarding');
            } else {
                // Handle failed account creation
                alert(data.error || 'Failed to create account.');
            }
        } catch (error) {
            console.error("Error creating account:", error);
            alert('An error occurred during account creation.');
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                    <div style={{ "paddingBottom" : "15px" }}>
                        <div>Enter your email account to reset password</div>
                    </div>
                    <Form.Group controlId="formEmail">
                        <Form.Control 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                    </Form.Group>
                    <button className="btn-signin mt-3 w-100" onClick={resetPassword}>Send Reset Link</button>
                    </>                    
                );
                case 2:
                    return (
                        <>
                            <div>
                            <Form.Control 
                                    type="text"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    placeholder="Enter your code"
                                />
                                <Form.Control
                                    type="password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    placeholder="Enter your new password"
                                />
                                <button className="btn-signin mt-3 w-100" onClick={submitNewPassword}>Reset Password</button>
                            </div>
                        </>
                      );
                case 3:
                    return (
                        <> 
                            
                            <Button variant="primary" onClick={handleSignup}>
                                Create Account
                            </Button>
                        </>
                    );
            case 4:
                // Implement your API integration logic here
                return null;
            case 5:
                return null;
            default:
                return null;
        }
    };

    return (
        <Container className="create-account-container" style={{ "color" : "#fff" }}>

            <Row style={{ 'height' : '50px', 'marginBottom' : '40px' }}>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'left', 'lineHeight' : '50px'}}>
                    <div style={{
                        color: "white",
                        display: "inline-block",
                        cursor: "pointer",
                        height : '50px',
                        lineHeight : '50px'
                    }}
                        onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                </Col>

                <Col xs={6} md={6} lg={6} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>
                    <div style={{ 'color' : '#fff', 'textAlign' : 'center', 'height' : '50px', 'lineHeight' : '50px' }}>Forgot your Password</div>
                </Col>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>                   
                    
                    <div style={{ lineHeight : '50px' }}>

                        <img src={CompanyLogo} style={{ paddingTop : '13px'}} width={30} alt="Logo" />

                    </div>
                                    
                </Col>

                <Col xs={12} md={12} lg={12}>
                    <ProgressBar className="mb-4 custom-progress-bar" style={{ height : '3px' }} now={(step / 2) * 100} />
                </Col>

            </Row>

            <Row style={{ "marginTop" : "35px" }}>

                <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center" }}>

                    {renderStepContent()}

                </Col>

            </Row>            

        </Container>
    );
}