import React, { useState, useEffect } from "react";


// Packages

import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

// CSS

import "./Overview.css";
import "./HamburguerMenu.css";
import "./EntityList.css";

// Media

import { BASE_URL_ADMIN } from "../../../Utils/utils";
import BackgroundCard from "../../../Component/UI/BackgroundCard";
import NewHeader from "../../components/newHeader";
import { Menplayer } from "../../../assets";

function RostersOverview() {
  const navigate = useNavigate();

  const [Rosters, setRosters] = useState(null);
  const [teamsLoaded, setTeamsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTeamsData = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL_ADMIN}roster/rosters`);
        const data = await response.json();
        setRosters(data?.data?.slice(0, 6));
        setLoading(false);

        setTeamsLoaded(true);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    fetchTeamsData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className="RosterOverview">
            <BackgroundCard>
              <NewHeader name={"Roster"} />
              <div className="NewTeamByIdRosterArea">
                {Rosters?.length > 0 &&
                  Rosters?.map((item, index) => {
                    return (
                      <div style={{ marginTop: "20px" }} key={index}>
                        <div className="experiences">
                          <img src={item?.image || Menplayer} alt="$$$" />
                          <div>
                            <h2>{item?.name}</h2>
                            <p>6’8 • PG • </p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
              </div>
            </BackgroundCard>
          </div>
        </>
      )}
    </>
  );
}

export default RostersOverview;
