/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from 'react';

// Components

import UserComponent from './UserComponent'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// API

import Profile from '../../../../api/Profile';

// JSON

import countries from './countries.json';

const AgentForm = ({ firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, phoneInternationalCode, setPhoneInternationalCode, phoneNumber, setPhoneNumber }) => {
        
    const profile = new Profile();

    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [agency, setAgency] = useState('');

    const [username, setUsername] = useState("");
    const [gender, setGender] = useState("");
    const [birthday, setBirthday] = useState("");
    const [sport, setSport] = useState("");
    
    const [currentTeam, setCurrentTeam] = useState("");
    
    const [profileImage, setUserImage] = useState('');
    const type = "agent"
    const phone = phoneInternationalCode + phoneNumber;

    async function handleSubmit(userData) {
        try {
           const user = await profile.create(userData);
           console.log('User created:', user);
        } catch (error) {
           console.error('Failed to create user:', error);
        }
    }

    const handleCreateAccount = () => {
        const userData = {type, profileImage, firstName, lastName, username, gender, email, password, birthday, country, state, city, sport, currentTeam, agency};
        handleSubmit(userData);
    }

    return (
        <>
            <div className="col-12">
                
                <UserComponent userData={{ image: "", name: "", nickname: "" }} setUserImage={setUserImage} />

            </div>

            <Form>
               
                <Form.Group controlId="formCountry">
                        <Form.Control 
                            as="select" 
                            className="form-dropdown"
                            value={country} 
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            {countries.map(country => <option key={country.code} value={country.name}>{country.name}</option>)}
                        </Form.Control>
                </Form.Group>
    
                <Form.Group controlId="formCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </Form.Group>
    
                <Form.Group controlId="formState">
                    <Form.Label>State (if USA)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </Form.Group>
    
                <Form.Group controlId="formAgency">
                    <Form.Label>Agency</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Agency"
                        value={agency}
                        onChange={(e) => setAgency(e.target.value)}
                    />
                </Form.Group>
            </Form>

            <div className="row mt-4">
                <div className="col-12">
                    <Button variant="primary" style={{ "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={handleCreateAccount}>Create Account</Button>
                </div>
            </div>

        </>
    );
}

export default AgentForm;