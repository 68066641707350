/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from 'react';

// Components

import Header from '../../../components/Header/Header'

// Package

import { useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link , useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import Chart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';

// CSS

import './ProfileEdit.css'

// Media

import CompanyLogo from '../../../../assets/logo.png';
import { BASE_URL } from '../../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

export default function ProfileTeamEdit() {

    const { id } = useParams();
    const [step, setStep] = useState(1);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [username, setUsername] = useState('');

    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('team');

    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [profileImage, setUserImage] = useState('');

    const [phoneInternationalCode, setPhoneInternationalCode] = useState('');

    const [profileInfo, setProfileInfo] = useState({});
    const [teamInfo, setTeamInfo] = useState({});

    const [birthday, setBirthday] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');

    const [teamName, setTeamName] = useState('');
    const [teamLocation, setTeamLocation] = useState('');
    const [teamGender, setTeamGender] = useState('');

    // Social Media

    const [youtube, setYoutube] = useState('');
    const [twitter, setTwitter] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [website, setWebsite] = useState('');
    const [instagram, setInstagram] = useState('');

    useEffect(() => {
        
        const fetchTeamInfo = async () => {

            let profile = localStorage.getItem('profile');

            profile = JSON.parse(profile)


            try {
                const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/team`);

                const data = await response.json();

                if (data.success && data.data) {

                    setTeamName(data.data.teamName);
                    setTeamLocation(data.data.teamLocation)
                    setTeamGender(data.data.teamGender)
                    setTeamInfo(data.data)

                }

            } catch (error) {
                console.error('Error fetching team info:', error);
            }
        };
        

        async function fetchProfile() {

            let profile = localStorage.getItem('profile');

            profile = JSON.parse(profile)

            try {

                const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const result = await response.json();

                if (result.success) {

                    const profile = result.data;
                    const personItem = profile.people[0];

                    setProfileInfo(profile)
                    setFirstName(personItem.firstname);
                    setLastName(personItem.lastname);
                    setEmail(profile.email);
                    setUsername(profile.username);
                    setPhoneNumber(personItem.phone);
                   
                    setBirthday(profile.birthday || "");
                   
                    setUserImage(profile.profileImage)


                    setPhoneInternationalCode(personItem.phoneInternationalCode || ''); 
    
                    
                    if (profile.links.length > 0) {

                        profile.links.forEach(link => {

                            if (link.type === 'youtube') {
                                setYoutube(link.link)
                            }
                            
                            if (link.type === 'tiktok') {
                                setTiktok(link.link);
                            }
                    
                            if (link.type === 'twitter') {
                                setTwitter(link.link)
                            }

                            if (link.type === 'website') {
                                setWebsite(link.link)
                            }

                            if (link.type === 'instagram') {
                                setInstagram(link.link)
                            }

                        })

                    }

                } else {
     
                    console.error("Failed to fetch profile data:", result);
                }

            } catch (error) {

                console.error("Error fetching profile:", error);

            }
        }
        
        fetchProfile().then(fetchTeamInfo);

    }, [id]);

    const UserComponent = ({ userData, setUserImage }) => {

        const placeholderImage = "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";
        
        const [isLoading, setIsLoading] = useState(false);
        const [selectedImage, setSelectedImage] = useState(placeholderImage);
        const [uploadedImageUrl, setUploadedImageUrl] = useState(userData.image);
        const fileInputRef = useRef();
    
        const handleImageSelect = (event) => {
            const file = event.target.files[0];
            if (file) {
                setSelectedImage(URL.createObjectURL(file));
            }
        }
    
        const handleUpload = async (event) => {
            
            event.preventDefault(); 
            const file = fileInputRef.current.files[0];
            if (!file) {
                console.log('No image selected!');
                return;
            }
    
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', file);
    
            try {
                const response = await fetch(API_ENDPOINT + '/files/uploadFile/common', {
                    method: 'POST',
                    body: formData
                });
    
                const responseData = await response.json();
    
                if (responseData.success) {
                    console.log("response data is ")
                    console.log(responseData)
                    setUploadedImageUrl(responseData.url);
                    setUserImage(responseData.url)
                    alert("Upload with success. Now, to save it in your profile, you must press button 'Edit");
                } else {
                    throw new Error(responseData.message || 'Upload failed');
                }

            } catch (error) {
                console.error('Error:', error);
                alert("Upload failed!");
            } finally {
                setIsLoading(false);
                setSelectedImage(null); 
            }

        };
    
        return (
            <div className="profile-image-wrapper">

                {isLoading ? (
                    <div className="loader text-white"><h2>Loading...</h2></div>
                ) : (
                    <>
                    
                    <div style={{ display: 'inline-block', position: 'relative' }}>

                        <label className="profile-image-wrapper-pen" htmlFor="fileUpload" style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faPen} /></label>
                        
                        <div className="profile-image-wrapper-pic" style={{ backgroundImage: `url(${uploadedImageUrl || selectedImage})` }}>
                            
                            <input
                            type="file"
                            id="fileUpload"
                            style={{ display: 'none' }}
                            onChange={handleImageSelect}
                            ref={fileInputRef}
                            /> 
                        </div>
                        <br></br>

                    </div>

                    <div style={{ 'marginBottom' : '15px' }}>

                        <div style={{ 'color' : '#fff', 'fontSize' : '1.5em', 'fontWeight' : 'bold' }} className="name">{userData.name}</div>
                        <div style={{ 'color' : '#fff' }} className="nickname">@{userData.nickname}</div>

                    </div>
                    
                    <div style={{ 'marginBottom' : '15px' }}>

                        {selectedImage != placeholderImage && (
                            <button className="mb-4 px-4 py-2 text-white" style={{ 'background' : 'linear-gradient(to right, #9F3F86, #1BA9CE)', 'borderRadius' : '5px' }}  onClick={handleUpload}>Upload Image</button>
                        )}

                    </div>
                    
                    </>
                )}

            </div>
        );
    };

    const handleBack = () => {
        if (step == 1) {
            alert("Back")
        }
        if (step > 1) setStep(step - 1);
    };

    const handleEditProfile = async (event) => {

        event.preventDefault();

        let profile = localStorage.getItem('profile');

        profile = JSON.parse(profile)

        let linksArray = [
            { socialNetwork: "youtube", link: youtube },
            { socialNetwork: "twitter", link: twitter },
            { socialNetwork: "tiktok", link: tiktok },
            { socialNetwork: "website", link: website },
            { socialNetwork: "instagram", link: instagram },
        ];
        
        const links = linksArray.reduce((acc, cur) => {
          if (cur.link) acc.push({ type: cur.socialNetwork, link: cur.link });
          return acc;
        }, []);

        let profileData = {
            title: firstName + "" + lastName,
            type: userType,
            username,
            profileImage,
            links,
            email,
            people: [{
                firstname: firstName,
                lastname: lastName,
                gender,
                phone: phoneNumber,
                phoneInternationalCode
            }],
        };
    
        let teamData = {
            teamName,
            teamLocation,
            teamGender,
        };
        
        const updatedProfileData = {};
        for(const [key, value] of Object.entries(profileData)) {
            if(profileInfo[key] !== value) {
                updatedProfileData[key] = value;
            }
        }

        const updatedTeamData = {};
        for(const [key, value] of Object.entries(teamData)) {
            if(teamInfo[key] !== value) {
                updatedTeamData[key] = value;
            }
        }
        
        const updatedData = {
            profile: {_id: profileInfo._id, ...updatedProfileData},
            team: {_id: teamInfo._id, ...updatedTeamData}
        };

        // Send request only if there are changes
        if(Object.keys(updatedProfileData).length > 1 || Object.keys(updatedTeamData).length > 1){

            const response = await fetch(API_ENDPOINT + `/profiles/id/${profile._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
            });

            if (response.ok) {
                // Handle the successful response accordingly
                console.log('Profile updated successfully.');
                alert('Profile and Team updated successfully.')
            } else {
                // Handle errors
                console.error('Failed to update.');
            }

        }

    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    return (
        <>

        <Header />

        <Container>

            <Row>

                <Col xs={3}>

                    <b></b>
                    
                </Col>

                <Col xs={4}>

                    <b></b>
                    
                </Col>

                <Col xs={5} style={{ 'textAlign' : 'right' }}>

                    <div className="text-white text-center" style={{ 'lineHeight' : '14px', padding : '10px', display : 'inline-block', background : 'linear-gradient(to right, #9F3F86, #1BA9CE)', borderRadius : '15px', cursor : 'pointer' }} >
                        <Link to="/profile/link/edit" style={{ color : '#fff', 'fontSize' : '0.8em', 'textDecoration' : 'none' }}>Edit<br></br>Scoutz Link</Link>
                    </div>
                    
                </Col>

            </Row>

            <Row>

                <Col xs={12}>

                    <UserComponent setUserImage={setUserImage} userData={{ image: profileImage, name: firstName, nickname: username }} />
    
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={firstName} 
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First name"
                        />
                    </Form.Group>

                    <Form.Group controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last name"
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                        />
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">

                    <Form.Label>Phone number</Form.Label>
                    
                    <Row>
                        <Col xs={3}>
                        <Form.Control 
                            as="select" 
                            value={phoneInternationalCode}
                            onChange={(e) => setPhoneInternationalCode(e.target.value)}
                        >
                            <option value="option">Select</option>
                            <option value="+1">+1</option>
                            <option value="+351">+351</option>
                        </Form.Control>
                        </Col>
                        <Col xs={9}>
                        <Form.Control 
                            type="text" 
                            value={phoneNumber} 
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Phone number"
                        />
                        </Col>
                    </Row>
                    
                    </Form.Group>

                    

                </Col>

            </Row>

                <div className="row">
                    <div className="col-12">
                        
                        <Form.Group controlId="formFirstName">
                            <Form.Label>Username</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>
                </div>
                
                <div className="row">
                    <div className="col-6">
                        
                        <Form.Group controlId="formGender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Control
                                as="select"
                                className="form-dropdown"
                                value={gender}
                                onChange={handleGenderChange} // use the handler function here without `this`
                            >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </Form.Control>
                        </Form.Group>

                    </div>


                    <div className="col-6">

                        <Form.Group controlId="formBirthday">
                            <Form.Label>Birthday</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={birthday} 
                                onChange={(e) => setBirthday(e.target.value)}
                                placeholder="Birthday"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                </div>
                
                <div className="row">

                    <div className="col-6">
                        <Form.Group controlId="formPosition">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                as="select"
                                className="form-dropdown">
                                <option value="">Select your Country</option>
                                <option value="USA">USA</option>
                                <option value="Portugal">Portugal</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                </div>

                <div className="row">

                    <div className="col-12">
                        
                        <Form.Group controlId="">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={teamName} 
                                onChange={(e) => setTeamName(e.target.value)}
                                placeholder="Team Name"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>
                    
                    </div>
                
                </div>

                <div className="row">

                    <div className="col-12">
                        
                        <Form.Group controlId="">
                            <Form.Label>Team Location</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={teamLocation} 
                                onChange={(e) => setTeamLocation(e.target.value)}
                                placeholder="Current team location"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>
                    
                    </div>
                
                </div>

                <div className="row">

                    <div className="col-12">
                        
                        <Form.Group controlId="">
                            <Form.Label>Team Gender</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={teamGender} 
                                onChange={(e) => setTeamGender(e.target.value)}
                                placeholder="Current team location"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>
                    
                    </div>
                
                </div>

                {/* Social Network */}

                <div className="row">

                    <div className="col-12">
                        <h2 style={{ 'color' : '#fff' }}>Social Network</h2>
                    </div>

                </div>

                <div className="row">

                    <div className="col-12">

                        <Form.Group controlId="formWeight">
                            <Form.Label>Twitter</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={twitter} 
                                onChange={(e) => setTwitter(e.target.value)}
                                placeholder="Write your username on Twitter"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                    <div className="col-12">

                        <Form.Group controlId="formWeight">
                            <Form.Label>YouTube</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={youtube} 
                                onChange={(e) => setYoutube(e.target.value)}
                                placeholder="Write your username on YouTube"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                    <div className="col-12">

                        <Form.Group controlId="formWeight">
                            <Form.Label>TikTok</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={tiktok} 
                                onChange={(e) => setTiktok(e.target.value)}
                                placeholder="Write your username on TikTok"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                    <div className="col-12">

                        <Form.Group controlId="formWeight">
                            <Form.Label>Website</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={website} 
                                onChange={(e) => setWebsite(e.target.value)}
                                placeholder="Write your username on Website"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                    <div className="col-12">

                        <Form.Group controlId="formWeight">
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={instagram} 
                                onChange={(e) => setInstagram(e.target.value)}
                                placeholder="Write your username on Instagram"
                                style={{ "width" : "100%" }}
                            />
                        </Form.Group>

                    </div>

                </div>
                

                <div className="row" style={{ 'marginTop' : '30px', 'marginBottom' : '30px' }}>
                    <div className="col-12">
                        <Button variant="primary" style={{ 'width' : '100%' }} onClick={handleEditProfile}>Edit Account</Button>
                    </div>
                </div>

        </Container>
        </>
    );

}