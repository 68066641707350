import React from "react";
import "./ProfileArea.css";

const ProfileArea = ({ image, name, country }) => {
  return (
    <div className="ProfileArea">
      <div className="ProfileAreaBox">
        <img src={image} alt={image} />
      </div>
      <div>
        <h2>{name}</h2>
        <p>{country || ""}</p>
      </div>
    </div>
  );
};

export default ProfileArea;
