/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect } from 'react';

// Components

import Header from '../../components/Header/Header'

// Packages

import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Form.css'

// Media

import CompanyLogo from '../../../assets/logo.png';

// API

import Profile from '../../../api/Profile';
import { BASE_URL } from '../../../Utils/utils';

// Global Variable

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

export default function ScoutzRatingForm() {

    const [step, setStep] = useState(1);

    const navigate = useNavigate();

    // First Step
    const [username, setUsername] = useState('');
    const [formData, setFormData] = useState({
        highestLevelPlayed: '',
        lastYearPlayed: '',
        passport: '',
        gameFilm: '',
        workoutFrequency: '',
        agent: '',
        salaryExpectation: '',
        readiness: '',
      });

      const handleOptionSelect = (field, value) => {
        setFormData({
          ...formData,
          [field]: value
        });
        setStep(step + 1);
      };

    const handleNext = () => {
        // Here, you'd typically handle form validation and other checks 
        // before proceeding to the next step.
        if (step < 8) setStep(step + 1);
    };

    const handleSubmit = async () => {
        await submitForm(formData);
    };

    const handleBack = () => {
        if (step == 1) {
            alert("Back")
        }
        if (step > 1) setStep(step - 1);
    };

    async function submitForm(userData) {

      try {

        const profile = new Profile();
        await profile.handleConfirm(userData);
        alert('Rating Saved');
        navigate('/rating/overview');
        

      } catch (error) {

          console.error(error);

      }

    }

    /*
    async function submitForm(userData) {

        try {

            console.log("User Data inside submit form")
            console.log(userData)

            let profile = localStorage.getItem('profile');

            console.log(JSON.parse(profile))

            profile = JSON.parse(profile)

            const formData = new URLSearchParams();

            for (const key in userData) {
                formData.append(key, userData[key]);
            }

            let profileId = profile._id

            formData.append('profile', profileId)

            let url = `${API_ENDPOINT}/profiles/id/${profileId}/rating`
            
            console.log(url)

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData.toString()
            });

            const data = await response.json();

            console.log(data)

            if (data.success) {
                alert('Rating Saved');
                navigate('/rating/overview');
            } else {
                // Handle failed account creation
                alert(data.error || 'Failed to save your Rating.');
            
            }

        } catch (error) {

            console.error("Error creating account:", error);
            alert('An error when saving your Rating');

        }

    }
    */

    const renderStepContent = (step) => {
        switch (step) {
          case 1:
            return (
              <>
                <h3 className="form-options-title">What's the highest level played?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('highestLevelPlayed', 'Pro')}>Pro</button>
                  <button onClick={() => handleOptionSelect('highestLevelPlayed', 'D1')}>D1</button>
                  <button onClick={() => handleOptionSelect('highestLevelPlayed', 'D2')}>D2</button>
                  <button onClick={() => handleOptionSelect('highestLevelPlayed', 'D3')}>D3</button>
                  <button onClick={() => handleOptionSelect('highestLevelPlayed', 'Semi pro')}>Semi pro</button>
                </div>
              </>
            );
          case 2:
            return (
              <>
                <h3 className="form-options-title">What year did you last play organized basketball?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('lastYearPlayed', '2023')}>2023</button>
                  <button onClick={() => handleOptionSelect('lastYearPlayed', '2022')}>2022</button>
                  <button onClick={() => handleOptionSelect('lastYearPlayed', '2021')}>2021</button>
                  <button onClick={() => handleOptionSelect('lastYearPlayed', 'Before 2020')}>Before 2020</button>
                </div>
              </>
            );
          case 3:
            return (
              <>
                <h3 className="form-options-title">Do you have a passport?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('passport', 'Yes')}>Yes</button>
                  <button onClick={() => handleOptionSelect('passport', 'No')}>No</button>
                </div>
              </>
            );
          case 4:
            return (
              <>
                <h3 className="form-options-title">Do you have full game film?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('gameFilm', 'Yes')}>Yes</button>
                  <button onClick={() => handleOptionSelect('gameFilm', 'No')}>No</button>
                </div>
              </>
            );
          // Continue cases for each additional step...
          case 5:
            return (
              <>
                <h3 className="form-options-title">How often do you workout?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('workoutFrequency', 'Daily')}>Daily</button>
                  <button onClick={() => handleOptionSelect('workoutFrequency', '4-6 times a week')}>4-6 times a week</button>
                  <button onClick={() => handleOptionSelect('workoutFrequency', '2-3 times a week')}>2-3 times a week</button>
                  <button onClick={() => handleOptionSelect('workoutFrequency', 'Once a week')}>Once a week</button>
                  <button onClick={() => handleOptionSelect('workoutFrequency', 'Rarely')}>Rarely</button>
                </div>
              </>
            );
          case 6:
            return (
              <>
                <h3 className="form-options-title">Do you have an agent?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('agent', 'Yes')}>Yes</button>
                  <button onClick={() => handleOptionSelect('agent', 'No, looking for one')}>No, looking for one</button>
                  <button onClick={() => handleOptionSelect('agent', 'No')}>No</button>
                </div>
              </>
            );
          case 7:
            return (
              <>
                <h3 className="form-options-title">What salary are you looking for?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('salaryExpectation', 'Less than $1000/month')}>Less than $1000/month</button>
                  <button onClick={() => handleOptionSelect('salaryExpectation', '$1000-$3000/month')}>$1000-$3000/month</button>
                  <button onClick={() => handleOptionSelect('salaryExpectation', '$3000-$5000/month')}>$3000-$5000/month</button>
                  <button onClick={() => handleOptionSelect('salaryExpectation', 'More than $5000/month')}>More than $5000/month</button>
                </div>
              </>
            );
          case 8:
            return (
              <>
                <h3 className="form-options-title">How ready are you to get signed?</h3>
                <div className="form-options-wrapper">
                  <button onClick={() => handleOptionSelect('readiness', 'Ready now')}>Ready now</button>
                  <button onClick={() => handleOptionSelect('readiness', 'Need a month to prepare')}>Need a month to prepare</button>
                  <button onClick={() => handleOptionSelect('readiness', 'Need a few months to prepare')}>Need a few months to prepare</button>
                  <button onClick={() => handleOptionSelect('readiness', 'Not actively looking at the moment')}>Not actively looking at the moment</button>
                </div>
              </>
            );
          // Proceed with the 'default' case or any additional steps if necessary...
          default:
            // Final step: Review answers and submit
            return (
              <div>
                <h3 className="form-options-title">Review your answers</h3>
                <ul className="results">
                    <li>Highest level played: {formData.highestLevelPlayed}</li>
                    <li>Last year played: {formData.lastYearPlayed}</li>
                    <li>Do you have a passport?: {formData.passport}</li>
                    <li>Do you have full game film?: {formData.gameFilm}</li>
                    <li>How often do you workout?: {formData.workoutFrequency}</li>
                    <li>Do you have an agent?: {formData.agent}</li>
                    <li>What salary are you looking for?: {formData.salaryExpectation}</li>
                    <li>How ready are you to get signed?: {formData.readiness}</li>
                </ul>
                
                <div style={{ "border" : "none" }}>

                    <button style={{ "border" : "none", "color" : "#fff", "height" : "50px", "borderRadius" : "15px", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={handleSubmit}>Submit Answers</button>

                </div>
                
              </div>
            );
    
        }
        
    };

    return (
        <>
        <Container className="create-account-container">

            <Row style={{ 'height' : '50px', 'marginBottom' : '40px' }}>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'left', 'lineHeight' : '50px'}}>
                    <div style={{
                        color: "white",
                        display: "inline-block",
                        cursor: "pointer",
                        height : '50px',
                        lineHeight : '50px'
                    }}
                        onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                </Col>
                
                <Col xs={6} md={6} lg={6} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>
                    <div style={{ 'color' : '#fff', 'textAlign' : 'center', 'height' : '50px', 'lineHeight' : '50px' }}>Scoutz Rating</div>
                </Col>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>                   
                    
                    <div style={{ lineHeight : '50px' }}>

                        <img src={CompanyLogo} style={{ paddingTop : '13px'}} width={30} alt="Logo" />

                    </div>
                                      
                </Col>
                
                <Col xs={12} md={12} lg={12}>
                    <ProgressBar className="mb-4 custom-progress-bar" style={{ height : '3px' }} now={(step / 8) * 100} />
                </Col>
                
            </Row>

            <Row>

                <Col xs={12} md={12} lg={12} className="mx-auto">
                    
                    <div style={{ "minHeight" : "250px" }}>
                        {renderStepContent(step)}
                    </div>
                
                </Col>

            </Row>

        </Container>
        </>
    );
}