import React, { useState } from "react";
import NewHeader from "../components/newHeader";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import BlueButton from "../../Component/UI/BlueButton";
import { BASE_URL_ADMIN } from "../../Utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../Component/UI/ImageUpload";
import { Country, City } from "country-state-city";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PostJob = () => {
  const [step, setStep] = useState(0);
  const [jobPostData, setJobPostData] = useState({});
  const [activeButton, setActiveButton] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");

  const [customValue, setCustomValue] = useState(""); // For $X per month
  const [selectedDate, setSelectedDate] = useState(null); // For DD/MM/YY
  const navigate = useNavigate();

  const countries = Country.getAllCountries();
  let profile = JSON.parse(localStorage.getItem("profile"));

  const stepArray = [
    {
      title: "Image",
      field: "imageTitle",
      buttonValue: [],
    },
    {
      title: "Gender",
      field: "gender",
      buttonValue: ["Male", "Female"],
    },
    {
      title: "Position",
      field: "designation",
      buttonValue: [
        "Point Guard",
        "Shooting Guard",
        "Small Forward",
        "Forward",
        "Center",
      ],
    },
    {
      title: "Nationality needed",
      field: "country",
      buttonValue: [
        "N/A",
        "USA",
        "European",
        "African",
        "Asian",
        "Australian",
        "Canadian",
        "South American",
      ],
    },
    {
      title: "Pay",
      field: "salary",
      buttonValue: ["Negotiable", "$X per month"],
    },
    {
      title: "Apartment included",
      field: "apartmentIncluded",
      buttonValue: ["Yes", "No"],
    },
    {
      title: "Meals included",
      field: "mealsIncluded",
      buttonValue: ["Yes", "No"],
    },
    {
      title: "Flights included",
      field: "flightsIncluded",
      buttonValue: ["Yes", "No"],
    },
    {
      title: "When do you need the player?",
      field: "playerNeededBy",
      buttonValue: ["ASAP", "DD/MM/YY"],
    },
    {
      title: "Location",
      field: "location",
      buttonValue: [], // No buttons for location, it's a dropdown
    },
    {
      title: "Team",
      field: "team",
      buttonValue: ["Name", "Anonymous"],
    },
    {
      title: "Level Preferred",
      field: "experience",
      buttonValue: ["Current Pros", "College grads", "Rookies", "Any"],
    },
  ];

  const handleButtonClick = (value) => {
    const currentField = stepArray[step]?.field;
    setActiveButton(value);
    setError("");

    if (value === "DD/MM/YY" || value === "$X per month") {
      setCustomValue(""); // Reset custom input
      setSelectedDate(null); // Reset date picker
      setJobPostData((prevData) => ({
        ...prevData,
        [currentField]: "", // Reset the field value for input
      }));
    } else {
      setJobPostData((prevData) => ({
        ...prevData,
        [currentField]: value,
      }));
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);

    const countryData = Country.getCountryByCode(selectedCountry);
    if (countryData) {
      const cityList = City.getCitiesOfCountry(selectedCountry);
      setCities(cityList);
    }

    setJobPostData((prevData) => ({
      ...prevData,
      country: selectedCountry,
      city: "", // Reset city when country changes
    }));
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setJobPostData((prevData) => ({
      ...prevData,
      city: selectedCity,
    }));
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        BASE_URL_ADMIN + "api/s3-upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const responseData = response.data;

      if (responseData.code === 200) {
        setJobPostData((prevData) => ({
          ...prevData,
          image: responseData.objectUrl,
        }));
      } else {
        throw new Error(responseData.message || "Upload failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Upload failed!");
    }
  };

  const handleNext = async () => {
    const currentField = stepArray[step]?.field;

    if (step === 0) {
      if (!jobPostData?.title) {
        setError("Please enter a title to proceed.");
        return;
      } else {
        setStep((prev) => prev + 1);
      }
    } else {
      if (currentField === "salary" && activeButton === "$X per month") {
        if (!customValue) {
          setError("Please enter the salary amount.");
          return;
        } else {
          setJobPostData((prevData) => ({
            ...prevData,
            [currentField]: customValue,
          }));
        }
      }

      if (currentField === "playerNeededBy" && activeButton === "DD/MM/YY") {
        if (!selectedDate) {
          setError("Please select a date.");
          return;
        } else {
          setJobPostData((prevData) => ({
            ...prevData,
            [currentField]: selectedDate,
          }));
        }
      }

      // if (!jobPostData[currentField]) {
      //   setError("Please complete the field to proceed.");
      //   return;
      // }

      if (step < stepArray.length - 1) {
        setStep((prev) => prev + 1);
        setActiveButton(jobPostData[stepArray[step + 1]?.field] || "");
      } else {
        // Submit jobPostData to backend
        const completeJobPostData = {
          ...jobPostData,
          website: profile?._id, // Use profile data
          contact: {
            name: "John Doe",
            number: "1234567890",
          },
          jobType: "normal",
          ...(!jobPostData?.image && {
            image:
              "https://soutzpro-admin-bucket.s3.amazonaws.com/S Logo-04 2 4.png",
          }),
        };


        console.log(completeJobPostData,"completeJobPostData")

        try {
          const response = await axios.post(
            `${BASE_URL_ADMIN}jobs`,
            completeJobPostData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log(response, "response");

          if (response.status === 200) {
            navigate("/");
          }
        } catch (error) {
          console.error("Error:", error.message || error);
          alert("Failed to post the job. Please try again.");
        }
      }
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
      setActiveButton(jobPostData[stepArray[step - 1]?.field] || "");
      setError("");
    }
  };

  return (
    <Container
      style={{ position: "relative", minHeight: "100vh" }}
      className="create-account-container"
    >
      <NewHeader name={"Posting a job"} path="/home" />
      <Row>
        <Col xs={12}>
          <ProgressBar
            className="mb-4 custom-progress-bar"
            style={{ height: "3px" }}
            now={((step + 1) / stepArray.length) * 100}
          />
        </Col>

        <Col xs={12}>
          <h3 style={{ color: "#fff" }}>{stepArray[step]?.title}</h3>

          {step === 0 && (
            <>
              <ImageUpload
                onImageUpload={handleImageUpload}
                image={jobPostData?.image}
              />

              <h2 style={{color:"#fff",marginTop:"10px"}} >Title</h2>
              <input
                type="text"
                placeholder="Enter Job Title"
                value={jobPostData.title || ""}
                onChange={(e) =>
                  setJobPostData((prevData) => ({
                    ...prevData,
                    title: e.target.value,
                  }))
                }
                className="form-control mt-3"
              />
            </>
          )}

          {step > 0 && stepArray[step]?.field === "location" && (
            <>
              <h2 style={{color:"#fff",marginTop:"10px"}} >Country</h2>

              <select
                className="form-control"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>

              <h2 style={{color:"#fff",marginTop:"10px"}} >City</h2>

              <select
                className="form-control mt-3"
                value={jobPostData.city || ""}
                onChange={handleCityChange}
                disabled={!cities.length}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </>
          )}

          {step > 0 && stepArray[step]?.field !== "location" && (
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {stepArray[step]?.buttonValue?.map((item, index) => (
                <BlueButton
                  key={index}
                  active={activeButton === item}
                  onClick={() => handleButtonClick(item)}
                >
                  {item}
                </BlueButton>
              ))}
            </div>
          )}

          {activeButton === "$X per month" && (
            <input
              type="text"
              placeholder="Enter Salary Amount"
              value={customValue}
              onChange={(e) => setCustomValue(e.target.value)}
              className="form-control mt-3"
            />
          )}

          {activeButton === "DD/MM/YY" && (
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control mt-3"
            />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </Col>

        <Col xs={12} className="d-flex justify-content-between mt-4">
          <button
            style={{
              fontWeight: "bold",
              color: "#FFF",
              height: "45px",
              border: "none",
              borderRadius: "15px",
              width: "48%",
              background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
            }}
            onClick={handleBack}
          >
            Back
          </button>
          <button
            style={{
              fontWeight: "bold",
              color: "#FFF",
              height: "45px",
              border: "none",
              borderRadius: "15px",
              width: "48%",
              background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
            }}
            onClick={handleNext}
          >
            {step < stepArray.length - 1 ? "Next" : "Post Job"}
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default PostJob;
