import React from "react";

const BorderButton = ({ children, onClick, type, disabled, active }) => {
  return (
    <div
    style={{
      padding: "2px", // Space for border
      background: "linear-gradient(to right, #9F3F86, #1BA9CE)", // Gradient border
      borderRadius: "10px", // Border radius
    }}
  >
    <button
      disabled={disabled}
      type={type}
      style={{
        fontWeight: "bold",
        color: "#FFF",
        height: "45px",
        width: "100%",
        background: "#131313", // Transparent background
        borderRadius: "10px", // Border radius
        border: "none", // No border
        padding:"10px 30px"
      }}
      onClick={onClick}
    >
      {children}
    </button>
  </div>
  
  );
};

export default BorderButton;
