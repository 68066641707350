import React, { useState, useEffect } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";

// CSS

import "./Overview.css";
import "./HamburguerMenu.css";
import "./EntityList.css";

// Media

import { BASE_URL, BASE_URL_ADMIN } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL;

/*

  Follow Mechanism

*/

let token = localStorage.getItem("token");

/*

  Team List

*/

const TeamList = ({ data }) => {

  const navigate = useNavigate();
  const handleNavigate = (id) => {
    navigate(`/league/${id}`);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="entity-title">Leagues</div>
        </Col>
      </Row>
      {data?.map((item) => (
        <Row onClick={()=>handleNavigate(item?.id)} key={item._id} style={{ marginBottom: 15 }}>
          <Col xs={2}>
            <img className="circle-img" src={item.image} style={{margin:"0px"}} />
          </Col>
          <Col
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="title-athlete">{item.name}</div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

function TeamsOverview() {
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  useEffect(() => {
    const fetchTeamsData = async () => {
      try {
        const response = await fetch(`${BASE_URL_ADMIN}/league/leagues`);
        const data = await response.json();
        setTeams(data?.data);

        setTeamsLoaded(true);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchTeamsData();
  }, []);

  return (
    <>
      <Header />

      <Container>{teamsLoaded && <TeamList data={teams} />}</Container>

      <Footer />
    </>
  );
}

export default TeamsOverview;
