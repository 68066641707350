import React, { useState, useEffect } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
} from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faBookBookmark,
  faArrowRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// CSS

import "./Overview.css";
import "./HamburguerMenu.css";
import "./EntityList.css";

// Media

import CompanyLogo from "../../../assets/logo.png";
import Logo from "../../../assets/logo.png";
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL;

/*

  Follow Mechanism

*/

let token = localStorage.getItem("token");

function FollowUnfollowButton({ profileId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      let profile = localStorage.getItem("profile");
      profile = JSON.parse(profile);

      fetch(`${API_ENDPOINT}/profiles/id/${profileId}/following`, {
        method: "PUT",
        body: JSON.stringify({ profileId: profile._id }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setIsFollowing(data.data);
          setIsLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }, [profileId]);

  const toggleFollowing = () => {
    let profile = localStorage.getItem("profile");

    profile = JSON.parse(profile);

    fetch(`${API_ENDPOINT}/profiles/id/${profileId}/following`, {
      method: "POST",
      body: JSON.stringify({ profileId: profile._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setIsFollowing(!isFollowing);
        navigate(0);
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) {
    return <button disabled>...</button>;
  }

  return (
    <>
      <Row>
        <Col xs={12} style={{ textAlign: "center" }}>
          <button
            style={{
              borderRadius: "100px",
              padding: 3,
              color: "#fff",
              backgroundColor: "#1BA9CE",
              display: "inline-block",
              fontSize: "0.7em",
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 4,
            }}
            onClick={toggleFollowing}
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </button>
        </Col>
      </Row>
    </>
  );
}

/*

  Team List

*/

const TeamList = ({ data }) => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="entity-title">Teams</div>
        </Col>
      </Row>
      {data.map((item) => (
        <Row key={item._id} style={{ marginBottom: 15 }}>
          <Col xs={2}>
            <div
              className="circle-img"
              style={{
                backgroundImage: `url(${
                  item.profileImage || "alternativeimage"
                })`,
              }}
            />
          </Col>
          <Col xs={6} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <div className="title-athlete">{item.title}</div>
            <div className="description">Player</div>
          </Col>
          <Col xs={4} style={{display:"flex",flexDirection:"column",justifyContent:"center",textAlign: "right"}}>
            <FollowUnfollowButton profileId={item._id} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

function LeaguesOverview() {
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  useEffect(() => {
    const fetchTeamsData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/profiles/type/teams`);
        const data = await response.json();

        setTeams(data.data.docs);

        setTeamsLoaded(true);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchTeamsData();
  }, []);

  return (
    <>
      <Header />

      <Container>{teamsLoaded && <TeamList data={teams} />}</Container>

      <Footer />
    </>
  );
}

export default LeaguesOverview;
