/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useEffect } from 'react';

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

// Media

import Logo from '../../assets/logo.png';
import Home from '../Home/Home/Home';

/*



  START PAGE



*/

export default function Index() {

  const navigate = useNavigate();

  const Pulsate = keyframes`
    0% {
      transform: scale(0.95);
    }
    70% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(0.95);
    }
  `;

  const StyledImage = styled(Image)`
    animation: ${Pulsate} 1s ease-in-out infinite;
  `;

 
  return (
      <Home />
  );
}
