/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useEffect, useState } from "react";
import { ApiContext } from "./context/ApiContext";
import { TailSpin } from "react-loader-spinner";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Components

import "./i18n/i18n";

// Packages

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";

// Utils

import withAuth from "./withAuth";

// CSS

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Pages

import Index from "./pages/Index/Index";

// Authentication

import ForgotPassword from "./pages/Accounts/ForgotPassword/Forgot";
import Login from "./pages/Accounts/Login/Login";
import Create from "./pages/Accounts/Create/Create";

// Home

import Home from "./pages/Home/Home/Home";

// Profile

import ProfileAthlete from "./pages/Profiles/MyProfile/Athlete/Athlete";
import ProfileAgent from "./pages/Profiles/MyProfile/Agent/Agent";
import ProfileTeam from "./pages/Profiles/MyProfile/Team/Team";
import ProfileCoach from "./pages/Profiles/MyProfile/Coach/Coach";
import ProfileWhoViewed from "./pages/Profiles/MyProfile/WhoViewed/WhoViewed";

// Chat

import ChatById from "./pages/Chat/ChatById/ChatById";
import ChatOverview from "./pages/Chat/Overview/Overview";
import ChatRequests from "./pages/Chat/Requests/Requests";

// Jobs

import JobsById from "./pages/Jobs/JobsById/JobsById";
import JobsOverview from "./pages/Jobs/Overview/Overview";
import JobsMyJobs from "./pages/Jobs/MyJobs/MyJobs";

// News

import NewsExplore from "./pages/News/Explore/Explore";
import NewsById from "./pages/News/ById/ById";

// Notifications

import NotificationsOverview from "./pages/Notifications/Overview/Overview";

// Subscriptions

import SubscriptionsOverview from "./pages/Subscriptions/Overview/Overview";
import SubscriptionsMore from "./pages/Subscriptions/More/More";
import SubscriptionsUnlock from "./pages/Subscriptions/Unlock/Unlock";
import SubscriptionsUnlockJobs from "./pages/Subscriptions/UnlockJobs/UnlockJobs";

// Settings

import Settings from "./pages/Settings/Settings/Settings";
import FAQ from "./pages/Settings/FAQ/FAQ";
import Languages from "./pages/Settings/Languages/Languages";
import Terms from "./pages/Settings/Terms/Terms";
import PrivacyPolicy from "./pages/Settings/PrivacyPolicy/PrivacyPolicy";
import PaymentMethods from "./pages/Settings/PaymentMethods/PaymentMethods";

// ProDay

import ProDay from "./pages/ProDay/Overview/Overview";
import ProDayById from "./pages/ProDay/ById/ById";
import ProDayQRCode from "./pages/ProDay/QRCode/QRCode";

// Profile Management

import ProfileAthleteEdit from "./pages/Profiles/Edit/Athlete/Athlete";
import ProfileTeamEdit from "./pages/Profiles/Edit/Team/Team";
import ProfileAgentEdit from "./pages/Profiles/Edit/Agent/Agent";
import ProfileCoachEdit from "./pages/Profiles/Edit/Coach/Coach";

// Teams

import TeamsOverview from "./pages/Teams/Overview/Overview";
import TeamsById from "./pages/Teams/TeamsById/TeamsById";

// Leagues

import LeaguesOverview from "./pages/Leagues/Overview/Overview";
// import TeamsById from "./pages/Teams/TeamsById/TeamsById";

// Athlete

import AthleteOverview from "./pages/Athlete/Overview/Overview";
import AthleteById from "./pages/Athlete/PlayerById/PlayerById";

// Agent

import AgentByIdPage from "./pages/Agent/AgentById/AgentById";

// Coach

import CoachByIdPage from "./pages/Coach/CoachById/CoachById";

// Verify

import ProfileVerify from "./pages/Verify/Overview/Overview";

// HighlightTape

import HighlightTapePage from "./pages/Highlight/Tape/Tape";

// ScoutzLink

// Scoutz Link Edit

import ScoutzLinkEdit from "./pages/Profiles/LinkEdit/Edit";

// Videos - Create

import VideosCreate from "./pages/Videos/Create/Create";

// Discover

import Discover from "./pages/Discover/Discover/Discover";

// ScoutzRating

import ScoutzRatingOverviewPage from "./pages/ScoutzRating/Overview/Overview";
import ScoutzRatingFormPage from "./pages/ScoutzRating/Form/Form";

// Boost Me

import ContactUs from "./pages/ContactUs";
import CoacHJobs from "./pages/Coach";
import PostJob from "./pages/Coach/postJob";
import CoachRoutes from "./Routes/coach";
import axios from "axios";
import { BASE_URL } from "./Utils/utils";
import LeagueById from "./pages/Leagues/LeagueById/LeagueById";
import NewTeamById from "./pages/NewTeams/NewTeamById/NewTeamById";
import RostersOverview from "./pages/Rosters/Overview/Overview";
// import { useState } from 'react';

// Auth Pages

const AuthedHome = Home;

/*
  Subscriptions
*/

const AuthedSubscriptionsOverview = withAuth(SubscriptionsOverview);
const AuthedSubscriptionsUnlock = withAuth(SubscriptionsUnlock);
const AuthedSubscriptionsUnlockJobs = withAuth(SubscriptionsUnlockJobs);
const AuthedSubscriptionsMore = withAuth(SubscriptionsMore);

/*
  Profile
*/

const AuthedProfileAthlete = withAuth(ProfileAthlete);
const AuthedProfileAgent = withAuth(ProfileAgent);
const AuthedProfileTeam = withAuth(ProfileTeam);
const AuthedProfileCoach = withAuth(ProfileCoach);
const AuthedProfileCoachJobs = withAuth(CoacHJobs);
const AuthedProfileCoachPostJob = withAuth(PostJob);
const AuthedProfileWhoViewed = withAuth(ProfileWhoViewed);

/*
  Profile Verification
*/

const AuthedProfileVerify = withAuth(ProfileVerify);
const AuthedContactUs = withAuth(ContactUs);

const ContactUss = ContactUs;

/*
  Profile by ID
*/

const AuthedProfileAthleteById = withAuth(AthleteById);
const AuthedProfileTeamsById = withAuth(TeamsById);
const AuthedProfileAgentByIdPage = withAuth(AgentByIdPage);
const AuthedProfileCoachByIdPage = withAuth(CoachByIdPage);

/*
  Profile Edit
*/

const AuthedProfileAthleteEdit = withAuth(ProfileAthleteEdit);
const AuthedProfileTeamEdit = withAuth(ProfileTeamEdit);
const AuthedProfileCoachEdit = withAuth(ProfileCoachEdit);
const AuthedProfileAgentEdit = withAuth(ProfileAgentEdit);

/*
  Settings
*/

const AuthedSettings = withAuth(Settings);
const AuthedFAQ = withAuth(FAQ);
const AuthedLanguages = withAuth(Languages);
const AuthedTerms = withAuth(Terms);
const AuthedPrivacyPolicy = withAuth(PrivacyPolicy);
const AuthedPaymentMethods = withAuth(PaymentMethods);

/*
  Pro Day Events
*/

const AuthedProDay = withAuth(ProDay);
const AuthedProDayQRCode = withAuth(ProDayQRCode);
const AuthedProDayById = withAuth(ProDayById);

/*
  Chat
*/

const AuthedChatById = withAuth(ChatById);
const AuthedChatOverview = withAuth(ChatOverview);
const AuthedChatRequests = withAuth(ChatRequests);

/*
  News
*/

const AuthedNewsById = NewsById;
const AuthedNewsExplore = NewsExplore;

/*
  Jobs
*/

const AuthedJobsById = JobsById;
const AuthedJobsOverview = JobsOverview;
const AuthedJobsMyJobs = JobsMyJobs;

/*
  Purchase
*/

const AuthedHighlightTapePage = withAuth(HighlightTapePage);

/*
  Video
*/

const AuthedVideoCreatePage = withAuth(VideosCreate);

/*
  Discover
*/

const AuthedDiscoverPage = withAuth(Discover);

/*
  Scoutz Rating
*/

const AuthedScoutzRatingPage = withAuth(ScoutzRatingOverviewPage);
const AuthedScoutzRatingFormPage = withAuth(ScoutzRatingFormPage);

/*
  Scoutz Link
*/

const AuthedScoutzLinkEditPage = withAuth(ScoutzLinkEdit);

/*


*/

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const profile = JSON.parse(localStorage.getItem("profile"));

  const getCoachById = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/profiles/id/${profile?._id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success === true) {
        localStorage.setItem("profile", JSON.stringify(response?.data?.data));
        // alert("Upload successful. Please press 'Edit' to save it in your profile.");
      } else {
        // throw new Error(message || "Upload failed");
      }
    } catch (error) {
      console.error("Error:", error.message || error);
      // alert("Upload failed! Please try again.");
    } finally {
      // Optional: Reset or clear actions here, e.g., clear a selected image
      // setSelectedImage(null);
    }
  };

  console.log(profile, "profile");

  useEffect(() => {
    if (profile?._id) {
      getCoachById();
    }
  }, [profile]);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (
    <>
      {profile?.type === "coach" ? (
        <CoachRoutes />
      ) : (
        <Routes>
          {token && <Route path={"*"} element={<Navigate to="/" replace />} />}

          <Route path="/" element={<Index />} />

          {/* Authentication */}
          {!token && (
            <>
              <Route path="/accounts/create" element={<Create />} />
              <Route path="/accounts/login" element={<Login />} />
              <Route
                path="/accounts/forgot/password"
                element={<ForgotPassword />}
              />
            </>
          )}

          {/* Home Page */}

          <Route path="/home" element={<AuthedHome />} />

          {/* Profile */}

          <Route path="/profile/verify" element={<AuthedProfileVerify />} />
          <Route path="/contactus" element={<AuthedContactUs />} />

          <Route path="/profile/athlete" element={<AuthedProfileAthlete />} />
          <Route path="/profile/agent" element={<AuthedProfileAgent />} />
          <Route path="/profile/team" element={<AuthedProfileTeam />} />

          <Route
            path="/profile/whoviewed"
            element={<AuthedProfileWhoViewed />}
          />

          {/* Profile Edit */}

          <Route
            path="/profile/athlete/edit"
            element={<AuthedProfileAthleteEdit />}
          />

          <Route
            path="/profile/team/edit"
            element={<AuthedProfileTeamEdit />}
          />
          <Route
            path="/profile/agent/edit"
            element={<AuthedProfileAgentEdit />}
          />

          {/* Profile by ID */}

          <Route
            path="/athletes/id/:id"
            element={<AuthedProfileAthleteById />}
          />
          <Route path="/teams/id/:id" element={<AuthedProfileTeamsById />} />
          <Route
            path="/agents/id/:id"
            element={<AuthedProfileAgentByIdPage />}
          />
          <Route
            path="/coaches/id/:id"
            element={<AuthedProfileCoachByIdPage />}
          />

          {/* Overview */}

          <Route path="/teams/overview" element={<TeamsOverview />} />
          <Route path="/athletes/overview" element={<AthleteOverview />} />

          {/* Leagus Overview */}

          <Route path="/leagues/overview" element={<LeaguesOverview />} />
          <Route path="/league/:id" element={<LeagueById />} />

          {/* New Teams Overview */}

          <Route path="/teams/overview" element={<LeaguesOverview />} />
          <Route path="/team/:id" element={<NewTeamById />} />


           {/* Rosters Overview */}

           <Route path="/rosters/overview" element={<RostersOverview />} />

          {/* Subscriptions Page */}

          <Route
            path="/subscriptions/unlock"
            element={<AuthedSubscriptionsUnlock />}
          />
          <Route
            path="/subscriptions/unlock/jobs"
            element={<AuthedSubscriptionsUnlockJobs />}
          />
          <Route
            path="/subscriptions/more"
            element={<AuthedSubscriptionsMore />}
          />
          <Route
            path="/subscriptions/overview"
            element={<SubscriptionsOverview />}
          />

          {/* Settings */}

          <Route path="/settings" element={<AuthedSettings />} />
          <Route path="/settings/faq" element={<AuthedFAQ />} />
          <Route
            path="/settings/privacypolicy"
            element={<AuthedPrivacyPolicy />}
          />
          <Route
            path="/settings/paymentmethods"
            element={<AuthedPaymentMethods />}
          />
          <Route path="/settings/terms" element={<AuthedTerms />} />
          <Route path="/settings/languages" element={<AuthedLanguages />} />

          {/* Scoutz Link */}

          <Route
            path="/profile/link/edit"
            element={<AuthedScoutzLinkEditPage />}
          />

          {/* Scoutz Rating */}

          <Route path="/rating/overview" element={<AuthedScoutzRatingPage />} />
          <Route path="/rating/form" element={<AuthedScoutzRatingFormPage />} />

          {/* Highlight Tape */}

          <Route path="/highlight/tape" element={<AuthedHighlightTapePage />} />

          {/* Pro Day Events */}

          <Route path="/proday" element={<AuthedProDay />} />
          <Route path="/proday/qrcode" element={<AuthedProDayQRCode />} />
          <Route path="/proday/id/:id" element={<AuthedProDayById />} />

          {/* Chat */}

          <Route path="/chat/id/:id" element={<AuthedChatById />} />
          {/* <Route path="/chat/overview" element={<AuthedChatOverview />} /> */}
          <Route path="/chat/overview" element={<AuthedContactUs />} />
          <Route path="/chat/requests" element={<AuthedChatRequests />} />

          {/* News */}

          <Route path="/news/explore" element={<AuthedNewsExplore />} />
          <Route path="/news/id/:id" element={<AuthedNewsById />} />

          {/* Videos */}

          <Route path="/videos/create" element={<AuthedVideoCreatePage />} />

          {/* Notifications */}

          <Route path="/notifications/overview" element={<ContactUss />} />
          <Route path="/coming-soon" element={<ContactUss />} />

          {/* Jobs */}

          <Route path="/jobs/overview" element={<AuthedJobsOverview />} />

          <Route path="/jobs/my" element={<AuthedJobsMyJobs />} />
          {/* <Route path="/jobs/my" element={<AuthedContactUs />} /> */}

          <Route path="/jobs/id/:id" element={<AuthedJobsById />} />

          {/* Discover */}

          <Route path="/discover" element={<Discover />} />

          {/* Pro Day Events */}

          <Route path="/proday" element={<AuthedProDay />} />
          <Route path="/proday/qrcode" element={<AuthedProDayQRCode />} />
          <Route path="/proday/id/:id" element={<AuthedProDayById />} />
        </Routes>
      )}
    </>
  );
}

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <Router>
      <ApiContext.Provider value={{ loading, setLoading }}>
        <TailSpin
          visible={loading}
          height="80"
          width="80"
          color={("#9F3F86", "#1BA9CE")}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperClass="laoderPage"
        />
        <AppContent />
      </ApiContext.Provider>
    </Router>
  );
}

export default App;
