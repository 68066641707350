import { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';

import './ChatById.css'
import { ApiContext } from '../../../context/ApiContext';
import { BASE_URL } from '../../../Utils/utils';

const API_ENDPOINT = BASE_URL

const ChatByIdPage = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [profile, setProfile] = useState({});
  const endOfMessagesRef = useRef(null);
  const { setLoading } = useContext(ApiContext);


  useEffect(() => {
    const fetchMessages = async () => {
      try {

        setLoading(true);
        const messagesResponse = await fetch(`${API_ENDPOINT}/conversations/id/${id}/messages`);
        const messagesData = await messagesResponse.json();
  
        if (messagesData.success) {
          setMessages(messagesData.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
        }
      } catch(e) {
        console.error(e);
      } finally {
        setLoading(false);

      }
    };

    fetchMessages();
    const interval = setInterval(fetchMessages, 5000);
    
    return () => clearInterval(interval);
  }, [id]);

  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    if (storedProfile) {
      setProfile(JSON.parse(storedProfile));
    }
  }, []);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  

  const sendMessage = async () => {
    try {
      setLoading(true);
      if (!message.trim()) return;
      const response = await fetch(`${API_ENDPOINT}/conversations/id/${id}/messages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ content: message, sender: profile._id })
      });
      const newMessage = await response.json();
      if (newMessage.success) {
        setMessage('');
        setMessages((prevMessages) => [...prevMessages, newMessage.data]);
      }
    } catch(e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const isMessageFromCurrentUser = (senderId) => senderId === profile._id;

  return (
    <Container className="chat-page">
      <Row className="chat-header">
        <Col xs={12} className="d-flex align-items-center">
          <Button className="back-button" variant="link" onClick={() => navigate(-1)}>Back</Button>
          <div className="profile-info">
            <h1 className="profile-title">Chat</h1>
          </div>
        </Col>
      </Row>
      <Row className="chat-window">
        <Col xs={12} className="chat-messages">
          {messages.map((msg) => (
            <div key={msg._id} className={`message ${isMessageFromCurrentUser(msg.sender._id) ? 'sent' : 'received'}`}>
              {!isMessageFromCurrentUser(msg.sender._id) && <p className="sender-name">{msg.sender.title}</p>}
              <p className="message-content">{msg.content}</p>
              <p className="message-timestamp">{new Date(msg.createdAt).toLocaleTimeString()}</p>
            </div>
          ))}
          <div ref={endOfMessagesRef} className="end-of-messages"></div>
        </Col>
      </Row>
      <Row className="chat-input-row">
        <Col xs={9}>
          <div className="input-group">
            <textarea
              className="message-input"
              value={message}
              style={{ 'width' : '100%', 'resize' : 'none', 'border' : 0, 'border-radius' : '10px' }}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
            />
          </div>
        </Col>
        <Col xs={3}>
          <button className="send-button" style={{ 'width' : '100%', 'height' : '100%', 'borderRadius' : '5px' }} onClick={sendMessage}>Send</button>
        </Col>
      </Row>
    </Container>
  );

};

export default ChatByIdPage;