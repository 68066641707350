import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './PrivacyPolicy.css'

function PrivacyPolicy() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    return (
        <>
            <Header />
            <Container className="create-account-container text-white">

                <h1 class="mt-4 mb-3">Privacy Policy for Scoutz Sports Inc</h1>
                <small class="text-muted">Effective Date: January 26, 2024</small>
                <h2 class="mt-4">1. Overview</h2>
                <p>1.1 Scoutz Sports Inc, located at in Orlando, FL, is committed to protecting the privacy of its users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the steps taken to safeguard your data.</p>
                <h2 class="mt-4">2. Information We Collect</h2>
                <h3>2.1 Personal Information:</h3>
                <p>- When you register on Scoutz, we may collect personal details, including your name, contact information, and relevant sports background.</p>
                <h3>2.2 Usage Data:</h3>
                <p>- We gather information about how you interact with the platform, such as pages visited, features utilized, and the duration of your visits.</p>
                <h2 class="mt-4">3. How We Use Your Information</h2>
                <h3>3.1 Recruitment Process:</h3>
                <p>- Personal information is utilized to facilitate connections between athletes and coaches, streamlining the sports recruitment process.</p>
                <h3>3.2 Platform Improvement:</h3>
                <p>- Usage data helps us enhance our platform, ensuring a seamless and personalized experience for our users.</p>
                <h2 class="mt-4">4. Information Sharing</h2>
                <h3>4.1 Third-Party Collaboration:</h3>
                <p>- Scoutz may share data with trusted third parties to optimize our services, provided they adhere to our privacy standards.</p>
                <h3>4.2 Legal Compliance:</h3>
                <p>- We may disclose information if required by law or to protect our rights, users, or the public.</p>
                <h2 class="mt-4">5. Data Security</h2>
                <h3>5.1 Security Measures:</h3>
                <p>- Scoutz employs industry-standard security measures to protect your information from unauthorized access or disclosure.</p>
                <h2 class="mt-4">6. User Control and Choices</h2>
                <h3>6.1 Account Settings:</h3>
                <p>- Users can manage and update their account information through Scoutz settings.</p>
                <h3>6.2 Communication Preferences:</h3>
                <p>- Users can choose to receive or opt-out of communications from Scoutz.</p>
                <h2 class="mt-4">7. Cookies and Tracking Technologies</h2>
                <h3>7.1 Cookies:</h3>
                <p>- Scoutz uses cookies and similar technologies to enhance user experience and gather data on site usage.</p>
                <h2 class="mt-4">8. Changes to this Privacy Policy</h2>
                <h3>8.1 Notification:</h3>
                <p>- Users will be notified of any changes to this Privacy Policy, and the updated version will be posted on the Scoutz website.</p>
                <h2 class="mt-4">9. Contact Information</h2>
                <h3>9.1 Questions:</h3>
                <p>- If you have any questions or concerns regarding this Privacy Policy, contact us at Info@scoutzapp.com. By using Scoutz, you agree to the terms outlined in this Privacy Policy</p>

            </Container>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;