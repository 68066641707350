/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect } from 'react'

// Components

import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'

// Packages

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAirFreshener, faBookBookmark, faGlobe, faEye, faSearch, faArrowRight, faArrowLeft, faPen  } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

// Media

import Twitter from '../../../../assets/icons/socialmedia/twitter.png';
import Instagram from '../../../../assets/icons/socialmedia/instagram.png';
import TikTok from '../../../../assets/icons/socialmedia/tiktok.png';
import Web from '../../../../assets/icons/socialmedia/web.png';
import YouTube from '../../../../assets/icons/socialmedia/youtube.png';
import { BASE_URL } from '../../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

/*

  Display followers and who is following component

*/

const FollowersFollowing = ({ followers, following }) => {

  return (
    <Row style={{ 'textAlign' : 'center', 'color' : '#fff' }}>
      <Col xs={6} className="border-right">
        <h2 className="font-weight-bold">{followers}</h2>
        <p className="text-white-50">Followers</p>
      </Col>
      <Col xs={6}>
        <h2 className="font-weight-bold">{following}</h2>
        <p className="text-white-50">Following</p>
      </Col>
    </Row>
  );
}

/*

  Social Media links component

*/

const SocialMedia = ({profileData}) => {

  return (
    <Col xs={12} className="text-center mb-1">
      {profileData.links.map(({ type, link, _id }) => {
        let imgSrc;
        switch (type) {
          case 'twitter':
            imgSrc = Twitter;
            break;
          case 'instagram':
            imgSrc = Instagram;
            break;
          case 'tiktok':
            imgSrc = TikTok;
            break;
          case 'website':
            imgSrc = Web;
            break;
          case 'youtube':
            imgSrc = YouTube;
            break;
          default:
            imgSrc = Web; // default image in case the type doesn't match
        }
        return (
          <div key={_id} className="mr-3" style={{ display: 'inline-block', paddingRight: '15px' }}>
            <Link to={`https://${link}`} target="_blank" rel="noreferrer">
              <img src={imgSrc} width="30" />
            </Link>
          </div>
        )
      })}
    </Col>
  );

}

/*

  Who Viewed My Profile component

*/

const ProfileViews = () => {

  const [viewsCount, setViewsCount] = useState(0);
  const [searchCount, setSearchCount] = useState(0);

  const navigate = useNavigate();

  const handleProfileRedirect = () => {
    // navigate to another page
    navigate('/profile/whoviewed')
  }

  return (
    <div className="mb-4 px-2">

      <div style={{ 'backgroundColor' : '#3A3A3A', 'borderRadius' : '15px', 'marginBottom' : '15px' }}>

        <Row className="px-4" style={{ 'paddingLeft' : '15px', 'paddingRight' : '15px', 'color' : '#fff',}}>
          <Col xs={12} onClick={handleProfileRedirect} style={{ 'position' : 'relative', 'height' : '50px', 'lineHeight' : '50px', 'borderBottom' : '1px solid #fff'  }}>   
            <FontAwesomeIcon className="mr-2" icon={faEye} /> {viewsCount} Profile Views
            <FontAwesomeIcon icon={faArrowRight} style={{ 'position' : 'absolute', 'right' : 15, 'top' : 15 }} />
          </Col>
        </Row>

        <Row className="px-4" style={{ 'paddingLeft' : '15px', 'paddingRight' : '15px', 'color' : '#fff' }}>
          <Col xs={12} onClick={handleProfileRedirect} style={{ 'position' : 'relative', 'height' : '50px', 'lineHeight' : '50px' }}>   
            <FontAwesomeIcon className="mr-2" icon={faSearch} /> {searchCount} Search Views
            <FontAwesomeIcon icon={faArrowRight} style={{ 'position' : 'absolute', 'right' : 15, 'top' : 15 }} />
          </Col>
        </Row>

      </div>
      
      
      
    </div>
  );
}

/*

  Show Profile Header component

*/

const UserComponent = ({ userData }) => {

  const placeholderImage = "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(userData.image || placeholderImage);

  console.log("User image is ")
  console.log(userData.image)

  return (
    <div className="profile-image-wrapper">
      {isLoading ? (
        <div className="loader" style={{'color' : '#fff'}}>Loading...</div>
      ) : (
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <Link to="/profile/agent/edit">
            <label className="profile-image-wrapper-pen" style={{ cursor: 'pointer', bottom : 0 }}>
              <FontAwesomeIcon icon={faPen} />
            </label>
          </Link>
          <div
            className="profile-image-wrapper-pic"
            style={{ backgroundImage: `url(${selectedImage})` }} 
          />
        </div>
      )}
      <div style={{ "fontSize" : "1.2em", "fontWeight" : 'bold' }} className="name text-white mt-3">{userData.name}</div>
      <div className="nickname text-white mt-1">@{userData.nickname}</div>
    </div>
  );
};

//


/*



  START PAGE



*/

export default function MyProfileAgent() {
  
    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const [followers, setFollowers] = useState(0);
    const [following, setFollowing] = useState(0);
    const [profileImage, setProfileImage] = useState('');

    const [username, setUsername] = useState('');
    const [title, setTitle] = useState('');
    const [profileData, setProfileData] = useState('');
    const [agentData, setAthleteData] = useState('');

    const handleOnOpen = () => {
        setMenuDisplay(true)
    }


    const [sport, setSport] = useState('');
    const [feets, setFeets] = useState('');
    const [inches, setInches] = useState('');
    const [weight, setWeight] = useState('');
    const [currentTeam, setCurrentTeam] = useState('');
    const [position, setPosition] = useState('');

    //

    let profile = localStorage.getItem('profile');
    profile = JSON.parse(profile)

    //

    useEffect(() => {
      const fetchProfileData = async () => {
        try {
          
          const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}`);
          const data = await response.json();

          setFollowers(data.data.followers.length);
          setFollowing(data.data.following.length);
          setProfileImage(data.data.profileImage);
          setProfileData(data.data)
          setUsername(data.data.username);
          setTitle(data.data.title);

        } catch (error) {
          console.log(error.message);
        }
      }
      fetchProfileData();
    }, [API_ENDPOINT, profile._id]);

    useEffect(() => {
        
      // Fetch agent information
      const fetchAgentInfo = async () => {

          let profile = localStorage.getItem('profile');

          profile = JSON.parse(profile)


          try {
              const response = await fetch(`${API_ENDPOINT}/profiles/id/${profile._id}/agent`);

              const data = await response.json();
              console.log("Fetch agent Info")
              console.log(data)
              if (data.success && data.data) {

                  setAthleteData(data.data)
                  setCurrentTeam(data.data.currentTeam);

               }

              } catch (error) {
              console.error('Error fetching agent info:', error);
          }
      };

      fetchAgentInfo()

  }, [profile._id]);

  //

  const handleChatWithProfile = () => {
    
    navigate(`${API_ENDPOINT}/chat/id/${profileData._id}`)

  }


  //

  return (
    <>
   
    <Header />

    <Container className="create-account-container" style={{ 'paddingBottom' : '150px' }}>

        {profileData && (
          <div>
            <UserComponent userData={{
              image: profileImage,
              name: title,
              nickname: username
            }} />
          </div>
        )}

        <Row>

          <Col xs={12} className="text-align">

            <FollowersFollowing followers={followers} following={following}/>
          
          </Col>

        </Row>

        {profileData && (
          <SocialMedia profileData={profileData} />
        )}

        {profileData && (
          <ProfileViews />
        )}

        <div style={{ 'height' : '100px' }}>

        </div>

    </Container>

    <Footer />
    </>
  );

}