/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useContext, useEffect, useState } from "react";

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Container, Row, Col, Button, Tab, Tabs  } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

const EntityList = ({ data }) => {

  const [follow, setFollow] = useState(false);

  const placeholderImage = 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'

  const handleFollow = () => {
      setFollow(!follow);
  };

  // Add link

  return (
    <div>
      {data.map(item => (
        <Row key={item.id} className="mb-4">
          <Col xs={3}>
            <div 
              className="circle-img"
              style={{
                backgroundImage: `url(${item.profileImage || placeholderImage})`,
              }}
            />
          </Col>
          <Col xs={5}>
            <div className="text-white">{item.title}</div>
          </Col>
        </Row>
      ))}
    </div>
  );

}; 

// Date formatting function
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

/*

  Register on Pro Day button component

*/

function RegisterEventButton({ eventId }) {

      const [isLoading, setIsLoading] = useState(true);
      const [isFollowing, setIsFollowing] = useState(false);
      const { setLoading } = useContext(ApiContext);

      let profile = JSON.parse(localStorage.getItem('profile'));
    
      useEffect(() => {

        setLoading(true);
  
        // Esta lógica pode mudar
        // Poderia obter essa informação dentro do call já feito para obter info sobre o evento
  
        // replace with your api url
        fetch(`${API_ENDPOINT}/events/id/${eventId}/attend`, { 
          method: "PUT",
          body: JSON.stringify({ "profileId" : profile._id }),
          headers: {
              'Content-Type': 'application/json'
          }
        })
        .then((response) => response.json())
        .then((data) => {
  
          console.log(data.data)
  
          setIsFollowing(data.data); // the data from our api call
          setIsLoading(false);
          setLoading(false);
  
          // Refresh page
         
        })
        .catch((error) => console.error(error));
  
      }, [eventId]);
    
      const toggleFollowing = () => {
        setLoading(true);
        // replace with your api url
        fetch(`${API_ENDPOINT}/events/id/${eventId}/attend`, {
          method: "POST",
          body: JSON.stringify({ "profileId" : profile._id }),
          headers: {
              'Content-Type': 'application/json'
          }
        })
        .then(() => {
            setIsFollowing(!isFollowing);
            window.location.reload(); 
            setLoading(false);
        }) 
        .catch((error) => console.error(error));
      };
    
      {/* 
      if (isLoading) {
        return <button disabled>...</button>; // replace with your chimmery loading
      }
      */}
    
      return (
        <>
            <Row>
    
                <Col xs={12} style={{ 'textAlign' : 'center', 'marginBottom' : '40px' }}>
    
                    <button style={{ "fontWeight" : "bold", "color" : "#FFF", "height" : "45px", "border" : "none", "borderRadius" : "15px", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={toggleFollowing}>
                    {isFollowing ? "Remove application" : "Register"}
                    </button>
    
                </Col>
                
            </Row>        
        </> 
      );
}

/*



  START PAGE



*/

const ProDayById = () => {
  
  const [registeredInEvent, setRegisteredInEvent] = useState({});
  const [proDayData, setProDayData] = useState({});
  const [jobId, setJobId] = useState('');

  const params = useParams();

  const navigate = useNavigate();
  const { setLoading } = useContext(ApiContext);

  let profile = JSON.parse(localStorage.getItem('profile'));

  const [key, setKey] = useState('applied');

  /*

    Load information about the Event

  */

  useEffect(() => {

    const fetchData = async () => {


      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/events/id/${params.id}`);
        const data = await response.json();

        console.log(data)

        setProDayData(data.data);

        let profile = JSON.parse(localStorage.getItem('profile'));

        // Check if user is registered
        let isUserRegistered = data.data.attending.some((attendee) => attendee._id === profile._id);
        
        // If registered, then setRegisteredInEvent(true)
        if (isUserRegistered) {
          setRegisteredInEvent(true);
        } else {
          setRegisteredInEvent(false);
        }

      } catch(e) {
        console.error(e)
      } finally {
        setLoading(false);
      }


    };
  
    fetchData();
  
  },[]);

  /*

    Verify if Profile has active subscription

  */

  useEffect(() => {

    let profile = JSON.parse(localStorage.getItem('profile'));

    const fetchData = async () => {

      try {
        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/profiles/id/${params.id}/subscriptions/active`);
        const data = await response.json();
  
        console.log("Profile Subscription Active")
        console.log(data)
  
        if (!data.data) {
  
          // Redirect
          //navigate('/subscriptions/unlock');
  
        } else {
  
        }
  
        //setJobData(data.data);
        
      } catch(e) {
        console.error(e);
      } finally {
        setLoading(false);
      }

    };
  
    fetchData();
  
  },[]);

  //



  return (

    <>
      
      <Header />

      <Container>

        <Row className="justify-content-md-center mt-4">
          <Col xs={12}>
            <img src={proDayData.imageUrl} alt="Logo" style={{ width: '150px', height: '150px', borderRadius: '50%'}}/>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col xs={12}>
            <p style={{background: "blue", color: "white"}}>{proDayData?.status}</p>
            <h2 style={{textAlign: "center", fontWeight: "bold", color: "white"}}>{proDayData?.title}</h2>
            <div style={{textAlign: "center"}}>

              <div style={{color: "white"}}>{`${proDayData?.address}`}</div>
              <p style={{color: "white"}}>{`${proDayData?.city} - ${proDayData?.state} - ${proDayData?.country}`}</p>

            </div>
            
          </Col>
        </Row>

        
        <Row className="mb-4" style={{color: "white", textAlign: "center"}}>

          <Col xs={6} md={6}>
            <p style={{fontWeight: "lighter"}}>Time</p>
            <p style={{color: "white", fontWeight: "bold"}}>{formatDate(proDayData.time)}</p>
          </Col>

          <Col xs={6} md={6}>
            <p style={{fontWeight: "lighter"}}>Attend</p>

            { registeredInEvent && 
              <button style={{ "fontWeight" : "bold", "color" : "#FFF", "height" : "45px", "border" : "none", "borderRadius" : "15px", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}  onClick={() => navigate('/proday/qrcode')}>
                View QR Code
              </button>
            }

            {!registeredInEvent && (
              <div className="mb-10 mt-4">
                  <RegisterEventButton eventId={proDayData._id} />
              </div>
            )}
          </Col>

        </Row>

        <Row>

            <Col xs={12}>

                <Tabs 
                    id="controlled-tab-example" 
                    activeKey={key} 
                    onSelect={(k) => setKey(k)} 
                    className="mb-3"
                >

                  <Tab eventKey="applied" title="Attending" className="tabStyle">
                    {
                      proDayData.attending && (
                        <EntityList
                          // Filter out only athletes
                          data={proDayData.attending.filter(entity => entity.type === 'athlete')}
                        />
                      )
                    }
                  </Tab>
                  
                  <Tab eventKey="saved" title="Teams Commited" className="tabStyle">
                    {
                      proDayData.attending && (
                        <EntityList
                          // Filter out only teams
                          data={proDayData.attending.filter(entity => entity.type === 'team')}
                        />
                      )
                    }
                  </Tab>
                  

                </Tabs>
                
            </Col>

        </Row>

        <Row className="justify-content-center">
          <Col xs={12}>

            <div style={{ height: "50px" }}></div>

          </Col>
        </Row>

      </Container>

      <Footer />

      </>

  );

}

export default ProDayById;