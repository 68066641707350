import React from "react";
import BorderButton from "../BorderButton";
import { CoachIcon } from "../../../assets";
import "./playerCard.css"

const PlayerCard = () => {
  return (
    <div className="playerCard" >
      <div className="playerCardImage" >
        <img src={CoachIcon} alt="$$$" />
      </div>
      <h2 >Tom Casey</h2>
      <BorderButton>connect</BorderButton>
    </div>
  );
};

export default PlayerCard;
