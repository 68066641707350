/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Image, Row, Col, Form, Button, Container, ProgressBar, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// CSS

import './Overview.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

export default function ScoutzRatingOverview() {

    const [step, setStep] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const [jobs, setJobs] = useState([]);
    const [jobsLoaded, setJobsLoaded] = useState(false);

    const navigate = useNavigate();

    // Modal handler functions
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleStartForm = () => {
        navigate('/rating/form');
    }

    /*

      Load Recommended Jobs

    */

    // After recommended jobs algo is defined, implement API call

    function JobsTitle ({src, title, city, country, date, id, saved}) {
    
        const [isSaved, setIsSaved] = useState(false);
        const [iconColor, setIconColor] = useState('initial');
    
        const navigate = useNavigate();
    
        function handleNavigate (id) {
          navigate(`/jobs/id/${id}`);
        };
      
        const onClickHandler = (id) => () => {
          navigate(`/jobs/id/${id}`);
        };
    
   
        //
    
        // Date formatting function
        const formatDate = (dateString) => {
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
          return new Date(dateString).toLocaleDateString(undefined, options);
        };
    
        //
    
        const saveJob = async () => {
          // Check localStorage for the profile
          const profile = JSON.parse(localStorage.getItem('profile'));
          if (!profile || !profile._id) {
            alert('Please log in to save the job.');
            return;
          }
        
          // Prepare the API endpoints
          const endpointToSave = `${API_ENDPOINT}/jobs/id/${id}/save`;
          const endpointToUnsave = `${API_ENDPOINT}/jobs/id/${id}/unsave`;
        
          // Define a helper to update the icon color and show an alert.
          const successHandler = (message, isSaved) => {
            alert(message);
            setIconColor(isSaved ? 'aquamarine' : 'initial');
          };
        
          try {
            // We need "isSaved" state here to track if a job is saved or not.
            const response = await fetch(isSaved ? endpointToUnsave : endpointToSave, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ profileId: profile._id }),
            });
        
            const data = await response.json();
            if (response.ok) {
              // Toggle the isSaved state on success.
              setIsSaved(!isSaved);
              successHandler(data.message, !isSaved);
            } else {
              alert(data.message || 'Failed to perform the operation.');
            }
          } catch (error) {
            alert('An error occurred. Please try again later.');
            console.error('Save job error:', error);
          }
        };
        
        useEffect(() => {
          // Effect to check if the job is saved when the component mounts or when _id changes.
          const checkIfJobIsSaved = () => {
            const profile = JSON.parse(localStorage.getItem('profile'));
            if (profile && profile._id) {
              const isJobSaved = saved.some(entry => entry.profile === profile._id);
              setIsSaved(isJobSaved);
              setIconColor(isJobSaved ? 'aquamarine' : 'initial');
            }
          };
          checkIfJobIsSaved();
        }, [id]);
        
        return (
        <>
          <Row onClick={onClickHandler(id)}>
            <Col xs={3}>
                <Image src={src} style={{ objectFit: 'cover', width: '100%', aspectRatio: '1/1', borderRadius : 10 }} />
            </Col>
            <Col xs={8} style={{ color: '#ffffff' }}>
                <div style={{ borderRadius : 6, padding : 3, color: '#fff', backgroundColor : '#1BA9CE', display : 'inline-block', fontSize : '0.7em', paddingLeft : 10, paddingRight : 10, marginBottom : 4 }}>Active</div>
                <h1 style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{title}</h1>
                <p style={{ fontSize: '0.8em', opacity: '0.6' }}>
                    {city}, {country} - {formatDate(date)}
                </p>
            </Col>
          </Row>
        </>
        )
    
    };
    
    
    function ScrollableJobsRow({images}) {
    
      const navigate = useNavigate();
    
      const { t, i18n } = useTranslation();
    
        return (
            <>
    
            <div className="flex justify-between items-center mb-3">
              <Row>
                <Col xs={9}>
                  <h1 style={{fontWeight: 'bold', color:'white'}} className="text-2xl font-bold">{t('recommended.jobs')}</h1>
                </Col>
                <Col xs={3} className="text-end">
                  <Link to="/jobs/overview" style={{ fontWeight: '300', color:'#1BA9CE', textDecoration : 'none' }}>{t('recommended.seeAll')}</Link>
                </Col>
              </Row>
            </div>
    
            <Row>
                {
                    images.slice(0, 3).map((img, index) => <JobsTitle key={index} navigate={navigate} key={index} {...img} />)
                }
            </Row>

            
            </>
        );
    }

    return (
        <>
        <Header />
            <Container>

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center", "marginBottom" : "45px" }}>
                        
                        <img style={{ "height" : "50px" }} src="https://cdn3d.iconscout.com/3d/premium/thumb/earth-3715209-3105192.png" height="50px" />
                        <h2 style={{ "color" : "#fff" }}>How Pro ready are you?</h2>
                        <p style={{ "color" : "#fff" }}>You must complete your profile</p>

                        <div>

                        <Button onClick={handleShow} variant="primary" style={{ "height" : "50px", "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Start now</Button>
                        
                        </div>
                    
                    </Col>

                </Row>

                {jobsLoaded && jobs.length > 0 && (

                    <ScrollableJobsRow 
                    images={jobs.map((job) => ({
                    src: job.profile.profileImage,
                    id: job._id,
                    title: job.title,
                    city : job.city,
                    country : job.country,
                    date : job.createdAt,
                    saved : job.saved,
                    }))}

                    />

                )}

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                        
                        <h1 style={{ "color" : "#fff" }}>Elite</h1>
                        <h2 style={{ "color" : "#fff" }}>What does this mean?</h2>
                        <p style={{ "color" : "#fff" }}>We believe you have the talent and resume to play at a high level. We would love to help you sign asap. </p>
                    
                    </Col>

                </Row>

                <div>

                    <Row>

                        <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                            
                            <h2 style={{ "color" : "#fff" }}>How to get signed?</h2>
                            <p style={{ "color" : "#fff" }}>Since your an elite player many teams would love to get you on there team. Begin looking through your recommended teams and express your interest. Salaries can range from 5000-10000 a month for players like you!</p>
                            <p style={{ "color" : "#fff" }}>Pro day can put you right in front of these coaches.</p>
                            <p style={{ "color" : "#fff" }}>Register for pro day link to subscription</p>
                        
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                            
                            <h1 style={{ "color" : "#fff" }}>Mid Level</h1>
                            <h2 style={{ "color" : "#fff" }}>What does this mean?</h2>
                            <p style={{ "color" : "#fff" }}>We believe you have what it takes to play in some of the top leagues worldwide. Many coaches want a player like you. Begin expressing your interest to your recommended teams. Salaries can range from $800-5000 per month in leagues worldwide.</p>
                        
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                            
                            <h2 style={{ "color" : "#fff" }}>How to get signed?</h2>
                            <p style={{ "color" : "#fff" }}>A lot of coaches love our mid level guys! You could sign with teams in leagues like Portugal LPB, Spain Leb silver and many others. Stay up to date and attend a pro day to get in front of these coaches. You have a high chance of being signed. </p>
                        
                        </Col>

                    </Row>

                    <Row>

                        <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                            
                            <h1 style={{ "color" : "#fff" }}>Emerging talent</h1>
                            <h2 style={{ "color" : "#fff" }}>What does this mean?</h2>
                            <p style={{ "color" : "#fff" }}>You are an emerging talent which could be because of a gap or a low level team you played on previously. Salaries could range from $500-2000 per month in leagues. </p>
                        
                        </Col>

                        <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                            
                            <h2 style={{ "color" : "#fff" }}>How to get signed?</h2>
                            <p style={{ "color" : "#fff" }}>We recommend starting off at rookie level openings and getting your foot in the door. We believe that attending our Pro Day will boost your exposure and get you professional film and in front of coaches.</p>
                            <p style={{ "color" : "#fff" }}>Register for pro day link to subscription</p>
                        
                        </Col>

                    </Row>

                </div>

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                        <div  style={{ "height" : "150px" }}></div>
                    </Col>

                </Row>

                {/* Modal */}
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Body style={{backgroundColor: '#000'}}>
                        <Row className="justify-content-center">
                            <Col xs={12} className="text-center">
                                <img height="90px" src={'https://cdn3d.iconscout.com/3d/premium/thumb/question-mark-5202742-4347244.png?f=webp'} alt="Company Logo" className="centralized-image"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="text-center">
                                <h3 style={{color: 'white'}}>What is Scoutz review?</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{color: 'white', opacity: '0.8'}}>
                                <p>Scoutz Review is your gauge for pro readiness and potential playing opportunities. Answer 10 questions, get a score, and uncover insights into your readiness for the pros and potential pathways in the basketball world.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{color: 'white', opacity: '1'}}>
                            <Button onClick={handleStartForm} variant="primary" style={{ "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>Begin</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

            </Container>
        <Footer />
        </>
    );
}