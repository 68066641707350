import React, { useState, useEffect } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";

// Media

import { BASE_URL, BASE_URL_ADMIN } from "../../../Utils/utils";
import BackgroundCard from "../../../Component/UI/BackgroundCard";
import AthleatHeader from "../../components/Header/AthleatHeader";
import ProfileArea from "../../../Component/UI/ProfileArea";
import moment from "moment";
import { Menplayer } from "../../../assets";
import "./LeagueById.css";

// Global Variables
const API_ENDPOINT = BASE_URL;

export default function LeagueById() {
  const navigate = useNavigate();

  const league_id = useParams();
  const [league, setLeague] = useState(null);
  const [teams, setTeams] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeagueData = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          `${BASE_URL_ADMIN}league/league/${league_id?.id}`
        );
        const data = await response.json();
        setLeague(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };

    if (league_id?.id) {
      fetchLeagueData();
    }
  }, [API_ENDPOINT, league_id]);

  useEffect(() => {
    const fetchTeamsData = async () => {
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL_ADMIN}team/team-league-id/${league_id?.id}`);
        const data = await response.json();
        setTeams(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    fetchTeamsData();
  }, [API_ENDPOINT,league_id]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <BackgroundCard>
            <AthleatHeader name={"League"} />
            <ProfileArea
              image={league?.image}
              name={league?.name}
              country={league?.country}
            />
          </BackgroundCard>

          <Row className="LeagueById">
            <h2>Teams</h2>
            {teams?.length > 0 &&
              teams?.map((item, index) => {
                return (
                  <Col
                    onClick={() => navigate(`/team/${item?._id}`)}
                    style={{ marginTop: "20px" }}
                    key={index}
                    xs={12}
                  >
                    <div className="experiences">
                      <img src={item?.image || Menplayer} alt="$$$" />
                      <div>
                        <h2>{item?.name || ""}</h2>

                        <p>
                          {item?.country || ""} • {item?.city || ""}  
                        </p>
                      </div>
                    </div>
                    <hr />
                  </Col>
                );
              })}
          </Row>
        </>
      )}
    </>
  );
}
