/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useEffect, useState } from "react";

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card, Tab, Tabs } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Home.css'
import './HamburguerMenu.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import Logo from '../../../assets/logo.png';

// API

import Jobs from '../../../api/Jobs';
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

function ImageTitle({src, title}){
    return (
        <Card style={{width: '8rem', marginRight: '16px', backgroundColor : 'unset', border : 'unset'}}>
            <Card.Img variant="top" src={src} style={{ borderRadius: '50%', margin: '5px auto', width : '80px' }} />
            <Card.Body style={{ textAlign : 'center'}}>
                <Card.Title style={{color: '#fff'}}>{title}</Card.Title>
            </Card.Body>
        </Card>
    );
}

const JobsTitle = ({ _id, title, city, country, createdAt, profile ,image}) => {

  const navigate = useNavigate();
  
  const handleOnOpen = () => {
    navigate('/jobs/id/' + _id)
  }

  return (
      <Row onClick={handleOnOpen} style={{ cursor : 'pointer' }}>
          <Col xs={3}>
              {/* Placeholder for Image since 'src' is not provided in the JSON response */}
              <Image src={image ? image : profile.profileImage} style={{ objectFit: 'cover', width: '100%', aspectRatio: '1/1', borderRadius: 10 }} />
          </Col>
          <Col xs={8} style={{ color: '#ffffff' }}>
              <h1 style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{title}</h1>
              <p style={{ fontSize: '0.8em', opacity: '0.6' }}>
                  {city}, {country} 
              </p>
          </Col>
      </Row>
  );
};

const ScrollableJobsSavedRow = ({ data }) => {
  return (
      <>
          <Row>
              {data}
          </Row>
      </>
  );
};

export function ScrollableJobsAppliedRow({images}) {
    return (
        <>
        <Row>
          {
            images.map((img, index) => <JobsTitle key={index} {...img} />)
          }
        </Row>
        </>
    );
}

/*



  START PAGE



*/

function Home() {
  
  const navigate = useNavigate();
  const [key, setKey] = useState('applied');

  const [jobsApplied, setJobsApplied] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const [jobsSaved, setJobsSaved] = useState([]);
  const [jobsSavedLoaded, setJobsSavedLoaded] = useState(false);
  
  let profile = localStorage.getItem('profile');
  console.log(JSON.parse(profile))
  profile = JSON.parse(profile)

  const jobsObj = new Jobs();

  // Load Recommended Jobs
  useEffect(() => {
    
    const fetchJobsData = async () => {
       try {
        
           const [jobsAppliedData, jobsSavedData] = await jobsObj.getJobsData();
           setJobsApplied(jobsAppliedData);
           setJobsLoaded(true);

           setJobsSaved(jobsSavedData);
           setJobsSavedLoaded(true);
        
        } catch (error) {
           console.log(error.message);
        }
    };

    fetchJobsData();

  }, []);


  console.log(jobsApplied,"jobsApplied")

  return (
    <>

    <Header />
   
    <Container className="create-account-container">

        <Row>
            <Col xs={12}>
                <Tabs 
                    id="controlled-tab-example" 
                    activeKey={key} 
                    onSelect={(k) => setKey(k)} 
                    className="mb-3"
                >
                    <Tab eventKey="applied" title="Applied" 
                        className="tabStyle">
                        
                        {setJobsLoaded && jobsApplied?.length > 0 && (
                          <ScrollableJobsSavedRow data={
                              jobsApplied.map((job, index) => <JobsTitle key={index} {...job} />)
                          } />
                        )}

                    </Tab>
                    <Tab eventKey="saved" title="Saved" 
                        className="tabStyle">
                        
                        {/* {setJobsLoaded && jobsSaved.length > 0 && (
                          <ScrollableJobsSavedRow data={
                              jobsSaved.map((job, index) => <JobsTitle key={index} {...job} />)
                          } />
                        )} */}

                    </Tab>
                </Tabs>
            </Col>
        </Row>

    </Container>

    <Footer />
    </>
  );

}

export default Home;