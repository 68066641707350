import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faNewspaper,
  faBriefcase,
  faSearch,
  faMessage,
  faGlobe,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./CoachFooter.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../../../Utils/utils";
import { useEffect } from "react";

function CoachFooter() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const opacity = (path) => (location.pathname === path ? 1 : 0.35);



  return (
    <div className="footer">
      <NavLink
        to="/home"
        className="footer-option"
        style={{ opacity: opacity("/home"), textDecoration: "none" }}
      >
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faHome} />
        <div className="title">{t("footer.home")}</div>
      </NavLink>

      <NavLink
        // to="/news/explore"
        className="footer-option"
        style={{ opacity: opacity("/news/explore"), textDecoration: "none" }}
      >
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faMessage} />{" "}
        <div className="title">{t("footer.messages")}</div>
      </NavLink>
      <button
        style={{
          fontWeight: "bold",
          color: "#FFF",
          height: "45px",
          border: "none",
          borderRadius: "100px",
          width: "45px",
          background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
        }}
      >
        +
      </button>

      <NavLink
        // to="/jobs/overview"
        className="footer-option"
        style={{ opacity: opacity("/jobs/overview"), textDecoration: "none" }}
      >
        <FontAwesomeIcon icon={faGlobe} style={{ color: "#fff" }} />
        <div className="title">{t("footer.players")}</div>
      </NavLink>

      <NavLink
        to="/profile/coach"
        className="footer-option"
        style={{ opacity: opacity("/discover"), textDecoration: "none" }}
      >
        <FontAwesomeIcon icon={faUser} style={{ color: "#fff" }} />
        <div className="title">{t("footer.profile")}</div>
      </NavLink>
    </div>
  );
}

export default CoachFooter;
