/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from 'react';

// Components

import UserComponent from './UserComponent';

// Packages
import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// API

import Profile from '../../../../api/Profile';

// JSON

import countries from './countries.json';

const TeamForm = ({ firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, phoneInternationalCode, setPhoneInternationalCode, phoneNumber, setPhoneNumber }) => {

    const navigate = useNavigate();
    const profile = new Profile();

    const [teamName, setTeamName] = useState('');
    const [teamLocation, setTeamLocation] = useState('');
    const [teamGender, setTeamGender] = useState('');
    const [recruiting, setRecruiting] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [league, setLeague] = useState('');
    const [sport, setSport] = useState("");
    const [username, setUsername] = useState("");
    const [profileImage, setUserImage] = useState('');
    const type = "team"
    const phone = phoneInternationalCode + phoneNumber;

    async function handleSubmit(userData) {
        try {
           const user = await profile.create(userData, navigate);
           console.log('User created:', user);
        } catch (error) {
           console.error('Failed to create user:', error);
        }
    }

    const handleCreateAccount = () => {
        const userData = {type, profileImage, firstName, lastName, teamName, username, type, email, password, teamGender, country, state, city, sport, recruiting };
        handleSubmit(userData);
    }

    return (
    <>
        <Form>
            <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formTeamName">
                <Form.Label>Team Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Team Name"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formTeamLocation">
                <Form.Label>Country</Form.Label>
                <Form.Control 
                            as="select" 
                            className="form-dropdown"
                            value={country} 
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            {countries.map(country => <option key={country.code} value={country.name}>{country.name}</option>)}
                </Form.Control>
                <Form.Label>City</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formLeague">
                <Form.Label>League</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="League"
                    value={league}
                    onChange={(e) => setLeague(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formTeamGender">
                <Form.Label>Team Gender</Form.Label>

                <Form.Control 
                            as="select" 
                            className="form-dropdown" 
                            value={teamGender} 
                            onChange={(e) => setTeamGender(e.target.value)}
                        >
                            <option value="">Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formRecruiting">
                <Form.Label>Recruiting</Form.Label>
                <Form.Control as="select" value={recruiting} onChange={(e) => setRecruiting(e.target.value)}>
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </Form.Control>
            </Form.Group>
        </Form>

        <div className="row mt-4">
                <div className="col-12">
                    <Button variant="primary" style={{ "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={handleCreateAccount}>Create Account</Button>
                </div>
        </div>
    </>
    );
}

export default TeamForm;