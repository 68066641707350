/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useEffect, useState } from "react";

// Components

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

// Packages

import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
  Image,
  Card,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faBookBookmark,
  faArrowRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// CSS

import "./Home.css";
import "./HamburguerMenu.css";

// Media

import Logo from "../../../assets/logo.png";
import CompanyLogo from "../../../assets/logo.png";
import PortugalImage from "../../../assets/cities/lisbon.jpg";
import SpainImage from "../../../assets/cities/madrid.jpg";
import ItalyImage from "../../../assets/cities/rome.jpg";
import { BASE_URL } from "../../../Utils/utils";
import { ProfileLock, saveJobIcon } from "../../../assets";
import moment from "moment";

// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

const cityImages = {
  Madrid: SpainImage,
  Lisbon: PortugalImage,
  Rome: ItalyImage,
};

function JobsTitle({
  src,
  title,
  city,
  country,
  date,
  id,
  saved,
  gender,
  jobType,
}) {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [isSaved, setIsSaved] = useState(false);
  const [iconColor, setIconColor] = useState("initial");
  const [loading, setLoading] = useState(false);
  const [jobModalIsOpen, setJobModalIsOpen] = useState(false);

  const navigate = useNavigate();

  function handleNavigate(id) {
    navigate(`/jobs/id/${id}`);
  }

  const subscriptionData =
    profile?.paymentMethods?.[profile?.paymentMethods?.length - 1]
      ?.subscription;

  const payment =
    (subscriptionData?.status === "incomplete_expired" ||
      subscriptionData?.status === "canceled") &&
    new Date().toISOString() >
      new Date(subscriptionData?.current_period_end * 1000).toISOString()
      ? false
      : subscriptionData?.id && true;
  const onClickHandler = (id, jobType) => () => {
    if (payment && jobType === "exclusive") {
      navigate(`/jobs/id/${id}`);
    } else if (!payment && jobType === "normal") {
      navigate(`/jobs/id/${id}`);
    } else {
      setJobModalIsOpen(true);
    }
  };

  const handleClose = () => {
    setJobModalIsOpen(false);
  };

  if (city in cityImages) {
    //src = cityImages[city];
  }

  //

  const saveJob = async () => {
    // Check localStorage for the profile
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (!profile || !profile._id) {
      alert("Please log in to save the job.");
      return;
    }

    // Prepare the API endpoints
    const endpointToSave = `${API_ENDPOINT}/jobs/id/${id}/save`;
    const endpointToUnsave = `${API_ENDPOINT}/jobs/id/${id}/unsave`;

    // Define a helper to update the icon color and show an alert.
    const successHandler = (message, isSaved) => {
      alert(message);
      setIconColor(isSaved ? "aquamarine" : "initial");
    };

    try {
      // We need "isSaved" state here to track if a job is saved or not.
      const response = await fetch(
        isSaved ? endpointToUnsave : endpointToSave,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ profileId: profile._id }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Toggle the isSaved state on success.
        setIsSaved(!isSaved);
        successHandler(data.message, !isSaved);
      } else {
        alert(data.message || "Failed to perform the operation.");
      }
    } catch (error) {
      alert("An error occurred. Please try again later.");
      console.error("Save job error:", error);
    }
  };

  useEffect(() => {
    // Effect to check if the job is saved when the component mounts or when _id changes.
    const checkIfJobIsSaved = () => {
      if (profile && profile._id) {
        const isJobSaved = saved.some((entry) => entry.profile === profile._id);
        setIsSaved(isJobSaved);
        setIconColor(isJobSaved ? "aquamarine" : "initial");
      }
    };

    checkIfJobIsSaved();
  }, [id]);

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  console.log(
    profile?.paymentMethods?.[profile?.paymentMethods?.length - 1]?.subscription
      ?.id,
    jobType
  );

  return (
    <>
      <Row
        style={{ position: "relative" }}
        onClick={onClickHandler(id, jobType)}
      >
        <Col
          xs={3}
          style={
            jobType === "exclusive"
              ? {
                  filter: payment ? "blur(0px)" : "blur(10px)",
                  cursor: "pointer",
                  color: "#ffffff",
                }
              : { cursor: "pointer", color: "#ffffff" }
          }
        >
          <Image
            src={src}
            style={{
              objectFit: "cover",
              width: "100%",
              aspectRatio: "1/1",
              borderRadius: 10,
            }}
          />
        </Col>
        <Col
          xs={8}
          style={
            jobType === "exclusive"
              ? {
                  filter: payment ? "blur(0px)" : "blur(10px)",
                  cursor: "pointer",
                  color: "#ffffff",
                }
              : { cursor: "pointer", color: "#ffffff" }
          }
        >
          <div
            style={{
              borderRadius: "100px",
              padding: 3,
              color: "#fff",
              backgroundColor: "#1BA9CE",
              display: "inline-block",
              fontSize: "0.7em",
              paddingLeft: 10,
              paddingRight: 10,
              marginBottom: 4,
            }}
          >
            Active
          </div>
          {gender && (
            <p
              style={{
                borderRadius: "100px",
                padding: 3,
                color: "#fff",
                backgroundColor: "rgba(159, 63, 134, 1)",
                display: "inline-block",
                fontSize: "0.7em",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 4,
                marginLeft: "10px",
                cursor: "pointer",
              }}
            >
              {gender === "male" ? "Men" : "Women"}
            </p>
          )}
          {jobType === "exclusive" && (
            <p
              style={{
                borderRadius: "100px",
                padding: 3,
                color: "#fff",
                backgroundColor: "rgba(159, 63, 134, 1)",
                display: "inline-block",
                fontSize: "0.7em",
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 4,
                marginLeft: "10px",
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              {jobType}
            </p>
          )}
          <h1 style={{ fontSize: "1.3em", fontWeight: "bold" }}>{title}</h1>
          <p style={{ fontSize: "0.8em", opacity: "0.6" }}>
            {city}, {country} - {moment(date).format("MM-DD-YYYY")}
          </p>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <img src={saveJobIcon} alt="$$$" />
        </Col>

        {jobType === "exclusive" ? (
          payment ? null : (
            <div>
              <div className="exclusiveBlurLeft">
                <img src={ProfileLock} alt="$$$" />
              </div>
              <div className="exclusiveBlurRight">
                <h2 style={{ color: "#ffff" }}>Exclusive opening</h2>
              </div>
            </div>
          )
        ) : null}
      </Row>
      <Modal
        show={jobModalIsOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            Attention!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="attention">
            <p>
              sorry you must be on a subscription to view this exclusive opening
            </p>
            <div className="footerBtn">
              <button
                onClick={() => setJobModalIsOpen(false)}
                type="button"
                class="linkBtn"
              >
                Cancel
              </button>
              <button
                style={{
                  fontWeight: "bold",
                  color: "#FFF",
                  height: "45px",
                  border: "none",
                  borderRadius: "15px",
                  width: "100%",
                  background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                  padding: "10px 40px",
                }}
                onClick={() => navigate("/settings/paymentmethods")}
              >
                Subscribe
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </>
  );
}

export function ScrollableJobsRow({ images }) {
  const navigate = useNavigate();

  return (
    <>
      {images.map((img, index) => (
        <JobsTitle navigate={navigate} key={index} {...img} />
      ))}
    </>
  );
}

/*



  START PAGE



*/

export default function JobsOverview() {
  const navigate = useNavigate();
  const [key, setKey] = useState("applied");

  const [jobs, setJobs] = useState([]);
  const [alljobs, setAllJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const [isSaved, setIsSaved] = useState(false);
  const [iconColor, setIconColor] = useState("initial");

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasPrevPage, setHasPrevPage] = useState(true);

  let token = localStorage.getItem("token");

  const completeUser = JSON.parse(localStorage.getItem("profile"));

  // Load Recommended Jobs
  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        setJobsLoaded(true);
        const response = await fetch(
          `${API_ENDPOINT}/profiles/jobs?page=${page}`
        );
        const data = await response.json();
        console.log(
          completeUser?.people?.[0]?.gender,
          "completeUser?.people?.[0]?.gender"
        );
        if (completeUser?.people?.[0]?.gender) {
          const filterData = data.data.docs?.filter((item) =>
            item?.gender
              ? item?.gender.toLowerCase() ==
                completeUser?.people?.[0]?.gender.toLowerCase()
              : null
          );
          const images = filterData
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((job) => {
              return {
                src: job?.image ? job?.image : job?.profile?.profileImage,
                id: job?._id,
                title: job?.title,
                city: job?.city,
                country: job?.country,
                date: job?.createdAt,
                saved: job?.saved,
                gender: job?.gender,
                jobType: job?.jobType || "normal",
              };
            });

          setJobs(images);
        } else {
          const images = data.data.docs
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((job) => {
              return {
                src: job?.image ? job?.image : job?.profile?.profileImage,
                id: job?._id,
                title: job?.title,
                city: job?.city,
                country: job?.country,
                date: job?.createdAt,
                saved: job?.saved,
                gender: job?.gender,
                jobType: job?.jobType || "normal",
              };
            });

          setJobs(images);
        }

        setJobsLoaded(false);
        //setHasNextPage(data.data.hasNextPage);
        //setHasPrevPage(data.data.hasPrevPage);
      } catch (error) {
        console.log(error.message);
        setJobsLoaded(false);
      }
    };

    fetchJobsData();
  }, [page]); // Changed to observe page changes

  // Paginator handler functions
  const handlePrevClick = () => {
    if (page > 1) setPage(page - 1);
  };
  const handleNextClick = () => {
    if (hasNextPage) setPage(page + 1);
  };

  console.log(jobs, "jbs");

  return (
    <>
      <Header />

      <Container className="create-account-container">
        {/* Recommended Jobs */}
        {/* {jobsLoaded && jobs.length > 0 && (
          <ScrollableJobsRow
            images={jobs
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((job) => ({
                src: job.profile.profileImage,
                id: job._id,
                title: job.title,
                city: job.city,
                country: job.country,
                date: job.createdAt,
                saved: job.saved,
              }))}
          />
        )} */}

        {/* Paginator buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: token ? 0 : 70,
          }}
        >
          <Button
            style={{ backgroundColor: "white", color: "black" }}
            variant="contained"
            onClick={handlePrevClick}
            disabled={!hasPrevPage}
          >
            Previous Page
          </Button>
          <Button
            style={{ backgroundColor: "white", color: "black" }}
            variant="contained"
            onClick={handleNextClick}
            disabled={!hasNextPage}
          >
            Next Page
          </Button>
        </div>

        <div style={{ minHeight: "150px", marginTop: "20px" }}>
          {jobs.map((img, index) => (
            <JobsTitle navigate={navigate} key={index} {...img} />
          ))}
        </div>
      </Container>

      <Footer />
    </>
  );
}
