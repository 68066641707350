import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './FAQ.css'

function FAQ() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    return (
        <>
            <Header />
            <Container className="text-white">
                
                <h3>Frequently Asked Questions (FAQs)</h3>

                <div class="my-4">
                <h4>Cancelling Subscription:</h4>
                <p><strong>Q1: How can I cancel my subscription on Scoutz?</strong></p>
                <p>A: To cancel your subscription, navigate to your account settings, locate the subscription section, and follow the cancellation instructions. For any assistance, contact our support team at support@scoutzapp.com.</p>
                </div>

                <div class="my-4">
                <h4>Contacting Coaches on Subscription:</h4>
                <p><strong>Q2: Can I still contact coaches if I cancel my subscription?</strong></p>
                <p>A: No, active subscription is required to access our full range of features, including contacting coaches. To maintain uninterrupted communication with coaches, ensure your subscription is active.</p>
                </div>

                <div class="my-4">
                <h4>Updating Profile:</h4>
                <p><strong>Q3: How do I update my profile information?</strong></p>
                <p>A: Easily update your profile by logging into your Scoutz account. Navigate to the profile section, where you can modify and save changes to your personal and sports information.</p>
                </div>

                <div class="my-4">
                <h4>Information on Pro Basketball:</h4>
                <p><strong>Q4: Where can I find information on pro basketball opportunities?</strong></p>
                <p>A: Stay informed about pro basketball opportunities by regularly checking our platform. We provide updates, news, and relevant information to keep you in the loop on the latest opportunities in the world of professional basketball.</p>

                <p><strong>Q5: Can Scoutz help me get recruited for pro basketball teams?</strong></p>
                <p>A: Absolutely! Scoutz is designed to connect aspiring athletes with professional opportunities. Utilize our platform to showcase your skills, connect with coaches, and enhance your chances of getting recruited for pro basketball teams.</p>

                <p>For any additional inquiries or assistance, feel free to contact our support team at support@scoutzapp.com. We're here to help you make the most out of your Scoutz experience!</p>
                </div>

            </Container>
            <Footer />
        </>
    );
}

export default FAQ;