/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useContext, useEffect, useState } from "react";

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Container, Image, Card } from 'react-bootstrap';

// CSS

import './ById.css'
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START PAGE



*/

export default function NewsById() {
  const [news, setNews] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoading } = useContext(ApiContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_ENDPOINT}/news/id/${id}`);
        const data = await response.json();

        console.log(data)

        if (data.success) {
            console.log("Inside data success")
          setNews(data.data);
        } else {
          //navigate("/404");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  return (
    <>
      <Header />
      {news ?  
        <Container className="news-container">

          <Row>
              <Col xs={12}>
                  <div style={{ 
                      height: '350px', 
                      backgroundImage: `url(${news.highlightImage})`, 
                      backgroundPosition: 'center', 
                      backgroundSize: 'contain', 
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '5px' ,
                      marginBottom : '15px'
                  }}>
                  </div>
              </Col>
          </Row>
          
          <Row>
            <Col xs={12}>
              <h1 style={{color: 'white', fontWeight: 'bold'}}>{news.title}</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
                <h5 style={{color: 'white', fontWeight: 'light'}}>{news.newsCategory.title} - {new Date(news.createdAt).toLocaleString()}</h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <p style={{color: 'white', fontWeight: 'light'}}>{news.text}</p>
            </Col>
          </Row>
        </Container>
      :        
        <p>Loading... </p> 
      }
      <Footer />
    </>
  );
}