/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useContext, useEffect, useState } from "react";

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Explore.css'
import './HamburguerMenu.css'

//  Media

import Logo from '../../../assets/logo.png';
import CompanyLogo from '../../../assets/logo.png';

import BottomBar from './BottomBar';
import { ApiContext } from "../../../context/ApiContext";
import { BASE_URL } from "../../../Utils/utils";

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

/*



  START COMPONENTS



*/

function NewsHighlight({src, title}){
    return (
        <Card style={{width: '12rem', marginRight: '16px', backgroundColor : 'unset', border : 'unset'}}>
            <Card.Img src={src} style={{ borderRadius: '15px', margin: '5px auto', height: '110px' }} />
            <Card.Body style={{ textAlign : 'left', padding : 0 }}>
                <Card.Title style={{color: '#fff', fontSize : '0.8em' }}>{title}</Card.Title>
            </Card.Body>
        </Card>
    );
}

function JobsTitle ({src, title, city, country, date, id}) {

  const navigate = useNavigate();

  const onClickHandler = (id) => () => {
    navigate(`/news/id/${id}`);
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  
  return (
    <>
    <Row onClick={onClickHandler(id)}>
        <Col xs={3} style={{ textAlign : 'center' }}>
            <Image src={src} style={{ objectFit: 'cover', width: '100%', aspectRatio: '1/1', borderRadius : '15px' }} />
        </Col>
        <Col xs={8} style={{ color: '#ffffff' }}>
            <h1 style={{ fontSize: '1em', fontWeight: 'bold' }}>{title}</h1>
            <p style={{ fontSize: '0.8em', opacity: '0.6' }}>
              {formatDate(date)}
            </p>
        </Col>
        <Col xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color : "#fff" }}>
            {/* <FontAwesomeIcon icon={faBookBookmark} /> */}
        </Col>
    </Row>
    </>
  );

};


export function ScrollableNewsRow() {

  const [newsCategory, setNewsCategory] = useState([]);
  const [newsHighlights, setNewsHighlights] = useState([]);
  const { setLoading } = useContext(ApiContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_ENDPOINT}/news/category`);
        const data = await response.json();
        setNewsCategory(data.data.docs);
      } catch (error) {
        console.error('Failed to fetch the data', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const fetchNewsbyCategory = async (category) => {
    try {

      setLoading(true);

      const response = await fetch(`${API_ENDPOINT}/news/category/id/${category._id}/news`);
      const data = await response.json();

      console.log("Category data is ")
      console.log(data.data)

      setNewsHighlights(data.data);

    } catch (error) {
      console.error('Failed to fetch the news by category', error);
    } finally {
      setLoading(false);
    }
  };

    return (
        <div>
          <div className="d-flex flex-row overflow-auto mt-1 mb-4">
            {
              newsCategory.map((category, index) => 
                <div key={index} className="news-highlights-category py-2 px-4" onClick={() => fetchNewsbyCategory(category)}>{category.title}</div>
              )
            }
          </div>
          <div className="d-flex flex-row overflow-auto pb-4">
              {
                newsHighlights.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .slice(0, 4)
                  .map((highlight, index) => <NewsHighlight key={index} src={highlight.highlightImage} title={highlight.title} />)
              }
          </div>
        </div>
    );
 }

export function ScrollablePlayersRow({images}) {
    return (
        <>
        <div className="d-flex flex-row overflow-auto mt-1 mb-1">
          <div className="news-highlights-category">Recent</div>
          <div className="news-highlights-category">Latest Signings & Transfer</div>
        </div>
        <div className="d-flex flex-row overflow-auto pb-4">
          {
            images.map((img, index) => <NewsHighlight key={index} {...img} />)
          }
        </div>
        </>
    );
}

export function ScrollableJobsRow({images}) {
    return (
        <>
        <div className="d-flex justify-content-between mt-4 mb-2">
          <h3 style= {{fontWeight: 'bold', color:'white'}}>Trending</h3>
        </div>
        <Row>
          {
            images.map((img, index) => <JobsTitle key={index} {...img} />)
          }
        </Row>
        </>
    );
}

/*



  START PAGE



*/

function Home() {
  
  const navigate = useNavigate();
  const { setLoading } = useContext(ApiContext);

  const [searchDisplay, setSearchDisplay] = useState(false);

  // Load News Categories
  const [news, setNews] = useState([]);
  const [newsLoaded, setNewsLoaded] = useState(false);

  useEffect(() => {
    
    const fetchTeamData = async () => {
      try {

        setLoading(true);

        const response = await fetch(`${API_ENDPOINT}/news`);
        const data = await response.json();
        

        console.log("Fetch News Data")
        console.log(data.data.docs)

        setNews(data.data.docs);

        setNewsLoaded(true)

        
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }

    };

    fetchTeamData();

  }, []);

  // // //

  return (
    <>

    <Header />
   
    <Container className="create-account-container">

        <Row>
          <Col xs={12} style={{ 'textAlign' : 'right' }}>
            <Button onClick={() => { setSearchDisplay(true)}}>Filter</Button>
          </Col>
        </Row>

        {/* Recommended News */}
        {newsLoaded && news.length > 0 && (

          <ScrollableNewsRow images={[
              {title : 'Report: LeBron James Son Bryce Leaving Sierra...', src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJfBGptHD1kzifydeGGuPlaY6aCASSEtMbAaPYNgCCpwA0BJoYxaDcHWQ2LwI1ZEQaEN8&usqp=CAU' },
              {src: 'https://i0.wp.com/hbcusports.com/wp-content/uploads/2022/04/946F3F3D-DA9F-4FCD-A70A-D3D31C3D2AB3.jpeg', title: 'NFL Draft: Florida high schools finish with the most'},
              // More images...
          ]} />

        )}

        {newsLoaded && news.length > 0 && (

          <ScrollableJobsRow 
            images={news.map((article) => ({
              src: article.highlightImage,
              id: article._id,
              title: article.title,
              date : article.createdAt
            }))}

          />

        )}

        {searchDisplay && (  
          <BottomBar searchDisplay={searchDisplay} setFilterDisplay={setSearchDisplay}></BottomBar> 
        )}  

        <div className="navigation-buttons mt-4">
        </div>

    </Container>

    <Footer />
    </>
  );

}

export default Home;