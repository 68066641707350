/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from "react";

// Components

import Header from "../../../components/Header/Header";

// Package

import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  ProgressBar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import Chart from "react-apexcharts";
import cloneDeep from "lodash/cloneDeep";
import { isEqual } from "lodash";

// CSS

import "./ProfileEdit.css";

// Media

import CompanyLogo from "../../../../assets/logo.png";
import { BASE_URL, BASE_URL_ADMIN } from "../../../../Utils/utils";
import NewHeader from "../../../components/newHeader";
import { CoachIcon } from "../../../../assets";
import axios from "axios";

// Global Variables

const API_ENDPOINT = BASE_URL;
//const API_ENDPOINT = "BASE_URL"

export default function ProfileCoachEdit() {
  const { id } = useParams();
  const [step, setStep] = useState(1);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [username, setUsername] = useState("");

  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("coach");

  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [profileImage, setUserImage] = useState("");

  const [phoneInternationalCode, setPhoneInternationalCode] = useState("");

  const [profileInfo, setProfileInfo] = useState({});
  const [coachInfo, setCoachInfo] = useState({});

  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");

  const [teamName, setTeamName] = useState("");
  const [teamLocation, setTeamLocation] = useState("");
  const [teamGender, setTeamGender] = useState("");
  const [coaching, setCoaching] = useState("");

  // Social Media
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [previousExp, setPreviousExp] = useState([
    { team: "", position: "", startDate: "", endDate: "" },
  ]);

  /*

        Fetch Profile and Coach info

    */

  useEffect(() => {
    // Fetch coach information
    const fetchCoachInfo = async () => {
      let profile = localStorage.getItem("profile");
      profile = JSON.parse(profile);

      try {
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}/coach`
        );

        const data = await response.json();

        if (data.success && data.data) {
          setCoaching(data.data.coaching);
          setCoachInfo(data.data);
        }
      } catch (error) {
        console.error("Error fetching coach info:", error);
      }
    };

    async function fetchProfile() {
      let profile = localStorage.getItem("profile");
      console.log(JSON.parse(profile));
      profile = JSON.parse(profile);

      try {
        const response = await fetch(
          `${API_ENDPOINT}/profiles/id/${profile._id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.success) {
          const profile = result.data;
          console.log(profile, "profile");
          const personItem = profile.people[0];

          console.log("Profile is:");
          console.log(profile);
          setProfileInfo(profile);
          setPreviousExp(profile?.experience)
          setFirstName(personItem.firstname);
          setLastName(personItem.lastname);
          setEmail(profile.email);
          setUsername(profile.username);
          setPhoneNumber(personItem.phone);

          setBirthday(profile.birthday || "");

          setUserImage(profile.profileImage);

          setPhoneInternationalCode(personItem.phoneInternationalCode || "");

          setUserType(profile.type);

          if (profile.links.length > 0) {
            profile.links.forEach((link) => {
              if (link.type === "youtube") {
                setYoutube(link.link);
              }

              if (link.type === "tiktok") {
                setTiktok(link.link);
              }

              if (link.type === "twitter") {
                setTwitter(link.link);
              }

              if (link.type === "website") {
                setWebsite(link.link);
              }

              if (link.type === "instagram") {
                setInstagram(link.link);
              }
            });
          }

          //setTwitter(data.data.position)
        } else {
          // Handle the case when the API request isn't successful
          console.error("Failed to fetch profile data:", result);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    }

    fetchProfile().then(fetchCoachInfo);
  }, [id]);

  /*

        User Header Component

    */

  const UserComponent = ({ userData, setUserImage }) => {
    const placeholderImage = CoachIcon;

    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState(userData.image);
    const fileInputRef = useRef();

    const handleImageSelect = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
      }
    };

    const handleUpload = async (event) => {
      event.preventDefault(); // Prevent the default form submit action.
      const file = fileInputRef.current.files[0];
      if (!file) {
        console.log("No image selected!");
        return;
      }

      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axios.post(
          BASE_URL_ADMIN + "api/s3-upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const responseData = response.data;

        if (responseData.code === 200) {
          console.log("response data is ");
          console.log(responseData);
          setUploadedImageUrl(responseData.objectUrl);
          setUserImage(responseData.objectUrl);
          setSelectedImage(null);
          alert(
            "Upload with success. Now, to save it in your profile, you must press button 'Edit"
          );
        } else {
          throw new Error(responseData.message || "Upload failed");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Upload failed!");
      } finally {
        setIsLoading(false);
        // setSelectedImage(null); // Clear the preview
      }
    };

    return (
      <div className="profile-image-wrapper coachContainer">
        <NewHeader name={"Edit profile"} path="/profile/coach" />
        {isLoading ? (
          <div className="loader text-white">
            <h2>Loading...</h2>
          </div>
        ) : (
          <>
            <div style={{ display: "inline-block", position: "relative" }}>
              <label
                className="profile-image-wrapper-pen"
                htmlFor="fileUpload"
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon icon={faPen} />
              </label>

              <div>
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : uploadedImageUrl || placeholderImage
                  }
                  alt="$$$"
                  className="profile-image-wrapper-pic"
                />
                <input
                  type="file"
                  id="fileUpload"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                  ref={fileInputRef}
                />
              </div>
              <br></br>
            </div>

            {/* <div style={{ marginBottom: "15px" }}>
              <div
                style={{ color: "#fff", fontSize: "1.5em", fontWeight: "bold" }}
                className="name"
              >
                {userData.name}
              </div>
              <div style={{ color: "#fff" }} className="nickname">
                @{userData.nickname}
              </div>
            </div> */}

            <div style={{ marginBottom: "15px" }}>
              {selectedImage != placeholderImage && (
                <button
                  className="mb-4 px-4 py-2 text-white"
                  style={{
                    background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                    borderRadius: "5px",
                  }}
                  onClick={handleUpload}
                >
                  Upload Image
                </button>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleBack = () => {
    if (step == 1) {
      alert("Back");
    }
    if (step > 1) setStep(step - 1);
  };

  const token = localStorage.getItem("token");

  const handleEditProfile = async (event) => {
    event.preventDefault();

    let profile = localStorage.getItem("profile");
    profile = JSON.parse(profile);

    let linksArray = [
      { socialNetwork: "youtube", link: youtube },
      { socialNetwork: "twitter", link: twitter },
      { socialNetwork: "tiktok", link: tiktok },
      { socialNetwork: "website", link: website },
      { socialNetwork: "instagram", link: instagram },
    ];

    const links = linksArray.reduce((acc, cur) => {
      if (cur.link) acc.push({ type: cur.socialNetwork, link: cur.link });
      return acc;
    }, []);

    let profileData = {
      title: `${firstName} ${lastName}`,
      type: userType,
      username,
      profileImage,
      links,
      email,
      people: [
        {
          firstname: firstName,
          lastname: lastName,
          gender,
          phone: phoneNumber,
          phoneInternationalCode,
        },
      ],
    };

    let coachData = {
      coaching,
      teamName,
      teamLocation,
      teamGender,
    };

    const updatedProfileData = {};
    for (const [key, value] of Object.entries(profileData)) {
      if (profileInfo[key] !== value) {
        updatedProfileData[key] = value;
      }
    }

    const updatedCoachData = {};
    for (const [key, value] of Object.entries(coachData)) {
      if (coachInfo[key] !== value) {
        updatedCoachData[key] = value;
      }
    }

    const updatedData = {
      profile: { _id: profileInfo._id, ...updatedProfileData,experience : previousExp },
      coach: { _id: coachInfo._id, ...updatedCoachData },
    };

    console.log(updatedData, "updatedData");

    // Send request only if there are changes
    if (
      Object.keys(updatedProfileData).length > 0 ||
      Object.keys(updatedCoachData).length > 0
    ) {
      try {
        const response = await axios.put(
          `${API_ENDPOINT}/profiles/id/${profile._id}`,
          updatedData,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": token,
            },
          }
        );

        console.log(response, "response");

        if (response.status === 200) {
          console.log(response, "response");
          // Handle the successful response accordingly
          if(response?.data?.updatedModels?.profile){
            localStorage.setItem(
              "profile",
              JSON.stringify(response?.data?.updatedModels?.profile)
            );
            alert("Profile and Athlete updated successfully.");
          }else{
            alert("Please Fill all fields")
          }
        } else {
          console.error("Failed to update.");
        }
      } catch (error) {
        console.error("Error:", error.message || error);
        alert("Failed to update the profile. Please try again.");
      }
    }
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleInputChange = (index, field, value) => {
    const updatedExp = previousExp.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setPreviousExp(updatedExp);
  };

  // Add this function to handle deleting an experience
const handleDeleteExperience = (index) => {
  const updatedExp = previousExp.filter((_, i) => i !== index);
  setPreviousExp(updatedExp);
};


  return (
    <>
      {/* <Header /> */}

      <Container
        className="profileCoach"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Row>
          <Col xs={3}>
            <b></b>
          </Col>

          <Col xs={4}>
            <b></b>
          </Col>

          {/* <Col xs={5} style={{ textAlign: "right" }}>
            <div
              className="text-white text-center"
              style={{
                lineHeight: "14px",
                padding: "10px",
                display: "inline-block",
                background: "linear-gradient(to right, #9F3F86, #1BA9CE)",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              <Link
                to="/profile/link/edit"
                style={{
                  color: "#fff",
                  fontSize: "0.8em",
                  textDecoration: "none",
                }}
              >
                Edit<br></br>Scoutz Link
              </Link>
            </div>
          </Col> */}

          <Col xs={12}>
            <UserComponent
              setUserImage={setUserImage}
              userData={{
                image: profileImage,
                name: firstName,
                nickname: username,
              }}
            />
          </Col>
        </Row>

        <Row style={{ padding: "10px" }}>
          <Col xs={6}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
              />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>

              <Row>
                <Col xs={3}>
                  <Form.Control
                    as="select"
                    value={phoneInternationalCode}
                    onChange={(e) => setPhoneInternationalCode(e.target.value)}
                  >
                    <option value="option">Select</option>
                    <option value="+54">Argentina (+54)</option>
                    <option value="+61">Australia (+61)</option>
                    <option value="+55">Brazil (+55)</option>
                    <option value="+86">China (+86)</option>
                    <option value="Canada+1">Canada (+1)</option>
                    <option value="+45">Denmark (+45)</option>
                    <option value="+358">Finland (+358)</option>
                    <option value="+33">France (+33)</option>
                    <option value="+49">Germany (+49)</option>
                    <option value="+91">India (+91)</option>
                    <option value="+39">Italy (+39)</option>
                    <option value="+81">Japan (+81)</option>
                    <option value="+64">New Zealand (+64)</option>
                    <option value="+31">Netherlands (+31)</option>
                    <option value="+234">Nigeria (+234)</option>
                    <option value="+47">Norway (+47)</option>
                    <option value="+48">Poland (+48)</option>
                    <option value="+351">Portugal (+351)</option>
                    <option value="+7">Russia (+7)</option>
                    <option value="+966">Saudi Arabia (+966)</option>
                    <option value="+27">South Africa (+27)</option>
                    <option value="+82">South Korea (+82)</option>
                    <option value="+34">Spain (+34)</option>
                    <option value="+46">Sweden (+46)</option>
                    <option value="+41">Switzerland (+41)</option>
                    <option value="+90">Turkey (+90)</option>
                    <option value="+44">United Kingdom (+44)</option>
                    <option value="+1">USA (+1)</option>
                  </Form.Control>
                </Col>
                <Col xs={9}>
                  <Form.Control
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone number"
                  />
                </Col>
              </Row>
            </Form.Group>
            {/* <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Form.Group> */}
          </Col>
        </Row>

        <div className="row" style={{ padding: "10px" }}>
          <div className="col-6">
            <Form.Group controlId="formFirstName">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formGender">
              <Form.Label>Gender</Form.Label>
              <Form.Control
                as="select"
                className="form-dropdown"
                value={gender}
                onChange={handleGenderChange}
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Form.Control>
            </Form.Group>
          </div>

          {/* <div className="col-6">
            <Form.Group controlId="formBirthday">
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                type="text"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                placeholder="Birthday"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-6">
            <Form.Group controlId="formPosition">
              <Form.Label>Country</Form.Label>
              <Form.Control
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                as="select"
                className="form-dropdown"
              >
                <option value="">Select your Country</option>
                <option value="USA">USA</option>
                <option value="Portugal">Portugal</option>
              </Form.Control>
            </Form.Group>
          </div> */}
        </div>

        <div className="row" style={{ padding: "10px" }}>
          <div className="col-12">
            <Form.Group controlId="formCoaching">
              <Form.Label>Coaching</Form.Label>
              <Form.Control
                as="select"
                value={coaching}
                onChange={(e) => setCoaching(e.target.value)}
                className="form-dropdown"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        {/* <div className="row" style={{padding:"10px"}}>
          <div className="col-12">
            <Form.Group controlId="">
              <Form.Label>Team Name</Form.Label>
              <Form.Control
                type="text"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                placeholder="Team Name"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
       
          <div className="col-12">
            <Form.Group controlId="">
              <Form.Label>Team Location</Form.Label>
              <Form.Control
                type="text"
                value={teamLocation}
                onChange={(e) => setTeamLocation(e.target.value)}
                placeholder="Current team location"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        
          <div className="col-12">
            <Form.Group controlId="">
              <Form.Label>Team Gender</Form.Label>
              <Form.Control
                type="text"
                value={teamGender}
                onChange={(e) => setTeamGender(e.target.value)}
                placeholder="Current team location"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div> */}

        {/* Social Network */}

        <div className="row" style={{ padding: "10px" }}>
          <div className="col-12">
            <h2 style={{ color: "#fff" }}>Social Network</h2>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Twitter</Form.Label>
              <Form.Control
                type="text"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                placeholder="Write your username on Twitter"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>YouTube</Form.Label>
              <Form.Control
                type="text"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                placeholder="Write your username on YouTube"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>TikTok</Form.Label>
              <Form.Control
                type="text"
                value={tiktok}
                onChange={(e) => setTiktok(e.target.value)}
                placeholder="Write your username on TikTok"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                placeholder="Write your username on Website"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>

          <div className="col-12">
            <Form.Group controlId="formWeight">
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder="Write your username on Instagram"
                style={{ width: "100%" }}
              />
            </Form.Group>
          </div>
        </div>

        <div className="row" style={{ padding: "10px" }}>
      <div className="col-11">
        <h2 style={{ color: "#fff" }}>Previous Experiences</h2>
      </div>
      <div className="col-1">
        <h2
          onClick={() =>
            setPreviousExp((prev) => [
              ...prev,
              { team: "", position: "", startDate: "", endDate: "" },
            ])
          }
          style={{ color: "#fff", cursor: "pointer" }}
        >
          +
        </h2>
      </div>
      {previousExp?.map((item, index) => (
    <React.Fragment key={index}>
      <div style={{ marginTop: "20px" }} className="col-12">
        <Form.Group controlId={`formTeam${index}`}>
          <Form.Label>Team</Form.Label>
          <Form.Label
            style={{ cursor: "pointer", color: "red", marginLeft: "10px",float:"right" }}
            onClick={() => handleDeleteExperience(index)}
          >
            close
          </Form.Label>
          <Form.Control
            as="select"
            value={item.team}
            onChange={(e) =>
              handleInputChange(index, "team", e.target.value)
            }
            className="form-dropdown"
          >
            <option value="">Select a Team</option>
            <option value="Team 1">Team 1</option>
            <option value="Team 2">Team 2</option>
          </Form.Control>
        </Form.Group>
      </div>

      <div className="col-12">
        <Form.Group controlId={`formPosition${index}`}>
          <Form.Label>Position</Form.Label>
          <Form.Control
            as="select"
            value={item.position}
            onChange={(e) =>
              handleInputChange(index, "position", e.target.value)
            }
            className="form-dropdown"
          >
            <option value="">Select a Position</option>
            <option value="Position 1">Position 1</option>
            <option value="Position 2">Position 2</option>
          </Form.Control>
        </Form.Group>
      </div>

      <div className="col-6">
        <Form.Group controlId={`formStartDate${index}`}>
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            value={item.startDate}
            onChange={(e) =>
              handleInputChange(index, "startDate", e.target.value)
            }
            style={{ width: "100%" }}
          />
        </Form.Group>
      </div>

      <div className="col-6">
        <Form.Group controlId={`formEndDate${index}`}>
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            value={item.endDate}
            onChange={(e) =>
              handleInputChange(index, "endDate", e.target.value)
            }
            style={{ width: "100%" }}
          />
        </Form.Group>
      </div>

      <div className="col-12">
        <Form.Group controlId={`formCurrentlyWorkHere${index}`}>
          <Form.Check
            style={{ color: "#fff" }}
            type="checkbox"
            label="I am currently working here"
            onChange={(e) =>
              handleInputChange(index, "currentlyWorking", e.target.checked)
            }
            checked={item.currentlyWorking || false}
          />
        </Form.Group>
      </div>
    </React.Fragment>
  ))}
    </div>

        <div
          className="row"
          style={{ marginTop: "30px", marginBottom: "30px", padding: "10px" }}
        >
          <div className="col-12">
            <Button
              variant="primary"
              style={{ width: "100%" }}
              onClick={handleEditProfile}
            >
              Save Profile
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
}
