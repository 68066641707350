import React, { useState, useEffect } from 'react';
import './Unlock.css'
import { Row, Col, Form, Button, Container, ProgressBar, Modal, Table } from 'react-bootstrap';

import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';


const API_ENDPOINT = BASE_URL

//const API_ENDPOINT = "BASE_URL"

export default function CreateAccount() {

    const [step, setStep] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    // Modal handler functions
    const handleClose = () => setShowModal(false);
    const handleShow = () => {

        //setShowModal(true)
        // Redirect
        navigate('/subscriptions/start');

    }

    // First Step
    const [username, setUsername] = useState('');

    const handleStartForm = () => {
        navigate('/subscriptions/start');
    }

    return (
        <>
        <Header />
            <Container className="create-account-container">

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "center", "marginBottom" : "45px" }}>
                
                <img className="mt-4 mb-4" src="https://cdn3d.iconscout.com/3d/premium/thumb/key-6338353-5220261.png" height="255px" />
                <h1 className="mt-4 mb-4" style={{ "color" : "#fff" }}>Jobs Marketplace</h1>
                <h3 className="mt-6" style={{ "color" : "#fff", "fontWeight" : '300' }}>Players must be on unsigned subscription to apply to openings</h3>

                <div style={{ "marginTop" : "55px" }}>

                    <Button onClick={handleShow} variant="primary" style={{ "height" : "50px", "fontSize" : "1.5em", "border" : "none", "width" : "100%", "fontWeight" : "bold", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }}>SUBSCRIBE HERE</Button>
                
                </div>

            </Col>

            </Row>

            {/* 
            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                
                <h1 style={{ "color" : "#fff" }}>Your 'Highlight Tape' Journey</h1>
                <h2 style={{ "color" : "#fff" }}>What we do?</h2>
                <p style={{ "color" : "#fff" }}>You play, we do the rest. We’ll edit and upload your best moments in this section, and automatically add them to your digital profile- Scoutz Link, the hotspot for your games!</p>

            </Col>

            </Row>

            <Row>

            <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                
                <h2 style={{ "color" : "#fff" }}>Boost Your Chances</h2>
                <p style={{ "color" : "#fff" }}>By having your best moments catalogued in Scoutz Link, your digital profile and resume takes a gigantic leap. Tightly edited, highly accessible select highlights increase visibility and placement in your Instagram bio, giving you the edge you deserve.</p>
                <p style={{ "color" : "#fff" }}>This way, you make a vital impression on coaches, agents, and teams scouting for talent. With the structure they crave, you increase your chances of being recruited. Go ahead, spotlight your brilliance.</p>

            </Col>

            </Row>
            */}

                <Row>

                    <Col xs={12} md={12} lg={12} className="mx-auto" style={{ "textAlign" : "left" }}>
                        <div  style={{ "height" : "150px" }}></div>
                    </Col>

                </Row>

            </Container>
        <Footer />
        </>
    );
}