/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState, useEffect, useRef } from 'react';
import { Country,State,City } from 'country-state-city';

// Components

import UserComponent from './UserComponent';

// Packages
import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// API

import Profile from '../../../../api/Profile';

// JSON

const CoachForm = ({ firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, phoneInternationalCode, setPhoneInternationalCode, phoneNumber, setPhoneNumber }) => {
        
    const profile = new Profile();
    const navigate = useNavigate();

    const [username, setUsername] = useState(email.split('@')[0]);
    const [birthday, setBirthday] = useState("");
    const [sport, setSport] = useState("");
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [coaching, setCoaching] = useState('No');
    const [teamName, setTeamName] = useState('');
    const [teamLocation, setTeamLocation] = useState('');
    const [teamGender, setTeamGender] = useState('');
    const [recruiting, setRecruiting] = useState('false');
    const [profileImage, setUserImage] = useState('');
    const [type, setType] = useState('coach');

    async function handleSubmit(userData) {
        try {
           const user = await profile.create(userData,navigate);
           console.log('User created:', user);
        } catch (error) {
           console.error('Failed to create user:', error);
        }
    }

    const handleCreateAccount = () => {
        const userData = {email, password, type, profileImage, firstName, lastName, username, gender, birthday, country, state, city, sport, coaching, teamName, teamLocation, teamGender, recruiting};
        
        if (userData.coaching == 'Yes') {
            userData.coaching = true
        }

        if (userData.coaching == 'No') {
            userData.coaching = false
        }

        if (userData.recruiting == 'Yes') {
            userData.coaching = true
        }

        if (userData.recruiting == 'No') {
            userData.coaching = false
        }
        
        handleSubmit(userData);

    }

    return (
        <>  

            <div className="row">

                <div className="col-12">
                    
                    <UserComponent userData={{ image: "", name: "", nickname: "" }} setUserImage={setUserImage} />

                </div>

            </div>

            <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Form.Group>


            <Form.Group controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <Form.Control as="select" value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCountry">
                <Form.Label>Country</Form.Label>
                    <Form.Control 
                        as="select" 
                        className="form-dropdown"
                        value={country} 
                        onChange={(e) => {setCountry(e.target.value)}}
                    >
                        {Country.getAllCountries().map(country => <option key={country.isoCode} value={country.isoCode}>{country.name}</option>)}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formState">
                <Form.Label>State</Form.Label>
                <Form.Control
                    as="select"
                    className="form-dropdown"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                >
                    {State.getStatesOfCountry(country).map(state => <option key={state.isoCode} value={state.isoCode}>{state.name}</option>)}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                    as="select"
                    className="form-dropdown"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                >
                    {City.getCitiesOfState(country,state).map(city => <option key={city.isoCode} value={city.isoCode}>{city.name}</option>)}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCoaching">
                <Form.Label>Currently Coaching</Form.Label>
                <Form.Control as="select" value={coaching} onChange={(e) => setCoaching(e.target.value)}>
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </Form.Control>
            </Form.Group>

            {coaching === 'Yes' && (
                <>
                    <Form.Group controlId="formTeamName">
                        <Form.Label>Team Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={teamName} 
                            onChange={(e) => setTeamName(e.target.value)}
                            placeholder="Team Name"
                        />
                    </Form.Group>

                    <Form.Group controlId="formTeamLocation">
                        <Form.Label>Team Location</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={teamLocation} 
                            onChange={(e) => setTeamLocation(e.target.value)}
                            placeholder="Team Location"
                        />
                    </Form.Group>

                    <Form.Group controlId="formTeamGender">
                        <Form.Label>Team Gender</Form.Label>
                        <Form.Control as="select" value={teamGender} onChange={(e) => setTeamGender(e.target.value)}>
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formRecruiting">
                        <Form.Label>Recruiting</Form.Label>
                        <Form.Control as="select" value={recruiting} onChange={(e) => setRecruiting(e.target.value)}>
                            <option value="">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </Form.Control>
                    </Form.Group>

                </>
            )}

            <div className="row mt-4">
            <div className="col-6">
                    <Button variant="primary" style={{ "border" : "1px solid", "width" : "100%", "background": "transparent" }} onClick={handleCreateAccount}>Skip Now</Button>
                </div>
                <div className="col-6">
                    <Button variant="primary" style={{ "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={handleCreateAccount}>Create Account</Button>
                </div>
            </div>  

        </>
    );
}

export default CoachForm;