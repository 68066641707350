/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/

import React, { useState } from 'react';

// Packages

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// CSS

import './Login.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import { BASE_URL } from '../../../Utils/utils';

// Global Variables

const API_ENDPOINT = BASE_URL
//const API_ENDPOINT = "BASE_URL"

const LoginPage = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();


  /*

    Implement via Profile.authenticate

  */

  const handleLogin = async (e) => {

    e.preventDefault();

    const loginEndpoint = API_ENDPOINT + '/profiles/authenticate';

    try {

      const response = await fetch(
        loginEndpoint,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email:email.toLowerCase(), password }),
        },
      );

      const data = await response.json();
   
      console.log(data)

      if (data.success) {

        localStorage.setItem('token', data.data.token);
        localStorage.setItem('profileType', data.data.data.type);
        localStorage.setItem('profile', JSON.stringify(data.data.data));
        window.location.pathname = "/"

      } else {

        alert(data.message)
        console.log("Error when log in the account")

      }

    } catch (error) {

      console.error(`Error during login: ${error}`);

    }
  };

  const handleOAuthLogin = (platform) => {
    console.log(`Login with ${platform}`);
  };

  const goToHome = () => {
    navigate('/home');
  };

  return (
    <div className="align-items-center justify-content-center vh-100 d-flex">
      
      <Container className="container-central mt-4">
            
            <Row>
            
                <Col xs={12} md={6} lg={4} className="mx-auto">
                    
                    <img src={CompanyLogo} width={90} alt="Logo" className="mt-4 my-4 d-block mx-auto" />
                    
                    <Form onSubmit={handleLogin}>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label style={{ 'color' : '#fff' }} className="mb-2">Email address</Form.Label><br></br>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicPassword" className="mt-2">
                            <Form.Label style={{ 'color' : '#fff' }} className="mb-2">Password</Form.Label><br></br>
                            <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>

                        <div style={{'textAlign' : 'right'}}>
                          <Form.Text className="text-right text-primary font-weight-bold"><Link to="/accounts/forgot/password">Forgot your Password</Link></Form.Text>
                        </div>
                        
                        <br></br>

                        <Button variant="primary" onClick={handleLogin} type="submit" className="btn-signin mt-3 w-100">Login</Button>

                    </Form>

                    

                </Col>

            </Row>

            {/*
            <Row className="mt-4">

                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Apple')}>
                                <img src='https://www.svgrepo.com/download/303125/apple-logo.svg' />
                            </Button>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Google')}>
                                <img src='https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png' />
                            </Button>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Facebook')}>
                                <img src='https://www.gov.br/mre/pt-br/delbrasupa/facebook-logo-blue-circle-large-transparent-png.png/@@images/image.png' />
                            </Button>
                        </Col>

            </Row>
            */}

            <div className="login-position-fixed" style={{ bottom: 0 }}>
              <p className="text-center">Don't have an account. <Link to="/accounts/create" className="text-primary font-weight-bold">Sign up</Link></p>
            </div>


      </Container>

    </div>
  );

};

export default LoginPage;
